import React from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { datePlusDays, lastDayOfMonth, datePlusMonths, datesInAMonth } from '@habline/common/utils'
import { dayDataStore } from '../data/DayDataStore'
import { DateMode, describeDate, mainStore } from '@habline/common/MainStore'

export const CalendarView = observer(({currentDate, infoForDate, onDateChanged, onDateClicked = () => {}}: {currentDate: Date, infoForDate: (date: Date) => {text: string, backgroundColor: string}, onDateChanged: (newDate:Date) => void, onDateClicked?: (date: Date) => void}) => {
    React.useEffect(() => {
      for (let date of datesInAMonth(currentDate)) {
        dayDataStore.requestDayData(date);
      }
    }, [currentDate]);

    var date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    date = datePlusDays(date, -[6, 0, 1, 2, 3, 4, 5][date.getDay()])

    let startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    let endDate = lastDayOfMonth(startDate);

    let daysData = [];
    
    while (date.getTime() <= endDate.getTime()) {
      if (date.getMonth() !== currentDate.getMonth()) {
        daysData.push({dayData: null, date: date});
      } else {
        daysData.push({dayData: dayDataStore.getDayData(date), date: date});
      }
      date = datePlusDays(date, 1);
    }

    let renderedMonth = [];

    for (let {dayData, date} of daysData) {
      if (dayData == null) {
        renderedMonth.push(<DayInCalendarFiller key={date.getTime()}></DayInCalendarFiller>);
      } else {
        let {text, backgroundColor} = infoForDate(date);

        renderedMonth.push(
          <DayInCalendar key={date.getTime()} backgroundColor={backgroundColor} today={date.getTime() === mainStore.today.getTime()} onClick={() => onDateClicked(date)} firstDayOfWeek={date.getDay() === 1}>
            {text}
          </DayInCalendar>
        );
      }
    }
    renderedMonth.push(<Clear key="end"/>)

    return <div style={{textAlign:"center"}}>
      <Title>{describeDate(currentDate, {mode: DateMode.MONTH})}</Title>
      <CalendarContainer>
        <MonthChangeButton onClick={() => onDateChanged(datePlusMonths(currentDate, -1))}>❮</MonthChangeButton>
        <MonthCointainer>{renderedMonth}</MonthCointainer>
        <MonthChangeButton onClick={() => onDateChanged(datePlusMonths(currentDate, +1))}>❯</MonthChangeButton>
      </CalendarContainer>
    </div>
  }
);

const Title = styled.h3`
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 0px;
`;

const CalendarContainer = styled.div`
  display: flex;
  align-items: center;
  height: calc((34px + 2px + 2px) * 6);
`;

const MonthChangeButton = styled.div`
  text-align: center;
  width: 10%;
`;

const MonthCointainer = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: ${(32 + 4)*7}px;
  display: block;
`;

const DayInCalendar = styled.div<{backgroundColor:string, firstDayOfWeek: boolean, today: boolean}>`
  text-align: center;
  margin: 2px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-family: monospace;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  color: ${({theme}) => theme.backgroundLevel0.toString()};;
  font-weight: bold;
  background-color: ${({backgroundColor}) => backgroundColor};
  ${props => props.firstDayOfWeek ? "clear:both;" : ""}
  box-shadow: ${props => props.today ? `inset 0px 0px 1px 2px ${({theme}) => theme.highlightColor}` : ""};
`;

const DayInCalendarFiller = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 32px;
  height: 32px;
  margin: 2px;
  display: block;
  float: left;
`;

const Clear = styled.div`
  clear:both;
  margin-bottom: 1em;
`;
