import React from 'react'
import { observer } from 'mobx-react-lite'
import { DayLevelScreen } from "../styles/components"
import styled from 'styled-components'
import { uiStore } from '../../data/UIStore'
import { durationToMs, msToDuration, pointWeight } from '@habline/common/time';
import { TextEditor } from './TextEditor'
import { Item } from '../../data/Item'

export const DurationEditor = observer<{value: number, valueSetter: (arg0: number) => void, item?: Item}>(({value, valueSetter, item}) => {

    let onSave = (duration) => {
      uiStore.pop();
      setTimeout(() => valueSetter(duration), 10);
    }

    let options: [string, number][] = [
      ["-", durationToMs("0m")],
      ["5 mins", durationToMs("5m")],
      ["15 mins", durationToMs("15m")],
      ["30 mins", durationToMs("30m")],
      ["1 hour", durationToMs("1h")],
      ["1,5 hours", durationToMs("1h 30m")],
      ["2 hours", durationToMs("2h")],
      ["3 hours", durationToMs("3h")],
      ["4 hours", durationToMs("4h")],
    ]

    return <DayLevelScreen title="Pick a duration" onBack={() => uiStore.pop()}>
      <CategoryButtonsContainer>
        
        {options.map(option => <CategoryButton key={option[0]} onClick={() => {onSave(option[1])}}>{option[0]}<PointValue>~{pointWeight(option[1])} task{pointWeight(option[1]) > 1 ? "s" : ""}</PointValue></CategoryButton>)}
        <CategoryButton style={{flexGrow: 4}} onClick={() => uiStore.push(<TextEditor label="Specify a duration"
          help="Aproximate time needed to allocate on a habit. Format: 1h 5m"
          placeholder="15m"
          value={msToDuration(value)}
          valueSetter={(value) => onSave(durationToMs(value))}/>)
        }>Custom</CategoryButton>
      </CategoryButtonsContainer>
    </DayLevelScreen>
  }
);

//TODO: Those styles are copied ...
export const CategoryButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CategoryButton = styled.button`
  margin-right: 5px;
  flex: 1 0 calc(33% - 5px);
  padding: 0;
  font-size: 1em;
  text-transform: none;
  height: 100px;
`;

export const PointValue = styled.div`
  padding: 0;
  font-size: 0.7em;
  line-height: 0.7em;
  color: ${({theme}) => theme.textLevel2};
`;

export const CategoryFiller = styled.div`
  margin-right: 5px;
  flex: 1 0 calc(33% - 5px)
`;