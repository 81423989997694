import React from 'react';
import styled from 'styled-components';
import { HistoricDailyItemView } from './DailyItemView';
import { observer } from 'mobx-react-lite';
import { Item } from '../data/Item';

export const HistoricDailyItemList = observer<{items:Item[], isMultiDay: boolean}>(({items, isMultiDay}) => {
  return (
    <Container>
      {items.map((item, index) => <HistoricDailyItemView key={item.id} item={item} isMultiDay={isMultiDay} />)}
    </Container>
  );
});

const Container = styled.div`

`;