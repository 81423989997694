import { DefaultTheme } from "styled-components/native"
import { Color } from "./Color"

declare module "styled-components" {
  export interface DefaultTheme {
    highlightColor: Color,

    backgroundLevel0: Color,
    backgroundLevel1: Color,
    backgroundLevel2: Color,
    backgroundLevel3: Color,
    backgroundLevel4: Color,
    backgroundLevel5: Color,

    textLevel3: string,
    textLevel2: string,
    textLevel1: string,
    textLevel0: string,

    green: Color,
    lightGreen: Color,
    red: Color,
    lightRed: Color,
    yellow: Color,
    blue: Color,
  }
}

export const lightTheme: DefaultTheme = {
    highlightColor: new Color("#fdffb6"),

    backgroundLevel0: new Color("#fafafa"),
    backgroundLevel1: new Color("#e5e3f5"),
    backgroundLevel2: new Color("#b6acea"),
    backgroundLevel3: new Color("#705BD6"),
    backgroundLevel4: new Color("#5842A0"),
    backgroundLevel5: new Color("#392E6D"),

    textLevel3: "#5a585c13",
    textLevel2: "#5a585c47",
    textLevel1: "#5a585cab",
    textLevel0: "#5a585cff",

    green: new Color("#1F9E40"),
    lightGreen: new Color("#edfcf1"),
    red: new Color("#FF6619"),
    lightRed: new Color("#ffe3d5"),
    yellow: new Color("#FFC717"),
    blue: new Color("#5463FF"),
}

export const darkTheme: DefaultTheme = {
    highlightColor: new Color("#fdffb6"),

    backgroundLevel0: new Color("#363537"),
    backgroundLevel1: new Color("#392E6D"),
    backgroundLevel2: new Color("#5842A0"),
    backgroundLevel3: new Color("#705BD6"),
    backgroundLevel4: new Color("#b6acea"),
    backgroundLevel5: new Color("#f6f5ff"),

    textLevel3: "#e0e0e013",
    textLevel2: "#e0e0e047",
    textLevel1: "#e0e0e0ab",
    textLevel0: "#e0e0e0",

    green: new Color("#1F9E40"),
    lightGreen: new Color("#edfcf1"),
    red: new Color("#FF6619"),
    lightRed: new Color("#ffe3d5"),
    yellow: new Color("#FFC717"),
    blue: new Color("#5463FF"),
}
