import React from 'react';
import { gapiAuth } from "../../data/gapi";
import { fb } from "@habline/common/firebase";
import { observer } from 'mobx-react-lite';
import { DayLevelScreen, Divider } from "../../components/styles/components";
import { uiStore } from '../../data/UIStore'
import { err, toExcelDate, logEvent } from "@habline/common/utils";
import { mainStore } from '@habline/common/MainStore';
import { Button } from "../../components/preferences/SettingsView";

const gapi = window.gapi;

export const CalendarsEditor = observer((props) => {
    const [calendars, setCalendars] = React.useState([]);
  
    if (!gapiAuth.workspace.calendars) {
      gapiAuth.workspace.calendars = [];
    }
  
    React.useEffect(() => {
      gapi.client.calendar.calendarList.list().then((response) => {
        setCalendars(response.result.items.map(({id, summary}) => {
          let existing = gapiAuth.workspace.calendars.find(calendar => calendar.id === id);
  
          if (!existing) {
            existing = {id: id}
            existing.startDate = null;
            existing.isTimeSensitive = true;
          }
  
          existing.summary = summary;
  
          return existing;
        }));
      });
    }, []); //run once
  
    return <DayLevelScreen title="Calendars" onBack={() => uiStore.pop()}>
      <Divider/>
      {calendars.map(calendar => {return <Button key={calendar.id} onClick={() => {
        calendar.startDate = calendar.startDate ? null : toExcelDate(mainStore.today);
        logEvent('calendar_settings_saved');
        fb.firestore().collection(`/workspaces/`).doc(gapiAuth.user.uid).update({
          calendars: calendars.filter(calendar => calendar.startDate)
        }).catch(err);
        setCalendars(calendars.slice())}
      }>{calendar.startDate ? "✔️" : ""} {calendar.summary}</Button>})}
    </DayLevelScreen>
  });