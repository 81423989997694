import React, { useState } from 'react'
import { observer } from 'mobx-react-lite';
import { uiStore } from '../../data/UIStore';
import { ContextMenu, ContextMenuRow, ContextMenuActionButton } from './ContextMenu';
import styled from 'styled-components'
import { makeAutoObservable } from 'mobx';


const RANGE_EDITOR_SOURCE_UPDATE_DELAY = 1000;

export class RangeStore {
  timeOutId = null;

  constructor() {
    makeAutoObservable(this);
  }
}

export const rangeStore = new RangeStore();

export const RangeEditor = observer<{value: number, label: string, valueSetter: (arg0: number) => void, canBeReset: boolean}>(({value, label, valueSetter, canBeReset}) => {
  
  const [localValue, setLocalValue] = useState(value);

  let finish = (event: any, reset?: boolean) => {
    clearTimeout(rangeStore.timeOutId);
    rangeStore.timeOutId = setTimeout(() => {
      valueSetter(reset ? null : localValue);
      uiStore.hideContextMenu();
    }, RANGE_EDITOR_SOURCE_UPDATE_DELAY);
  }

  return <ContextMenu visible={uiStore.isContextMenuVisible} onClose={() => uiStore.hideContextMenu()}>
    <p>{label} ({localValue}):</p>
    <ContextMenuRow>
        <RangeInput type="range" min="0" max="100" step="5" value={localValue} onMouseUp={finish} onKeyUp={finish} onTouchEnd={finish} onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
          onChange={(event) => {
            let v = parseInt((event.target as HTMLInputElement).value);
            setLocalValue(v)
            clearTimeout(rangeStore.timeOutId);
            rangeStore.timeOutId = setTimeout(() => valueSetter(value), RANGE_EDITOR_SOURCE_UPDATE_DELAY);
          }}
        />
    </ContextMenuRow>
    <ContextMenuRow>
      {canBeReset && <ContextMenuActionButton onClick={(e) => {finish(e, true)}}>Reset</ContextMenuActionButton>}
    </ContextMenuRow>
  </ContextMenu>
});


const RangeInput = styled.input<{value: number}>`
  -webkit-appearance: none;
  width: 100%;
  height: 20px;
  background: ${({theme}) => theme.backgroundLevel2.toString()};
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: 4px;
  margin: 0;
  margin-top: 2rem;
  margin-bottom: 2rem;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 40px;
    background: ${({theme}) => theme.textLevel0};
    cursor: pointer;
    border-radius: 4px;
  }

  &::-moz-range-thumb {
    width: 25px;
    height: 40px;
    background: ${({theme}) => theme.textLevel0};
    cursor: pointer;
    border-radius: 4px;
  }
`;