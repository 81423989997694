import { Item } from "../data/Item";
import { Status } from "@habline/common/Status";
import { uiStore } from "../data/UIStore";
import { openContextMenu } from "./context/ItemActionsContextMenu";

export function notifyAboutItemStatus(item: Item) {
  let line = "";
  if (item.status === Status.UNKNOWN) {
    line = `Reset status of ${item.category} ${item.name}`;
  } else if (item.status === Status.PARTIAL) {
    line = `Partially completed ${item.category} ${item.name}`;
  } else if (item.status === Status.SUCCESS) {
    line = `Successfully completed ${item.category} ${item.name}`;
  } else if (item.status === Status.MISSED && item.isInIcebox) {
    line = `Postponed ${item.category} ${item.name}`;
  } else if (item.status === Status.MISSED && !item.isInIcebox) {
    line = `Skipped ${item.category} ${item.name}`;
  }
  
  uiStore.toast(line, () => openContextMenu([item], true))
}