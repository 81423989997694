import React from 'react'
import { observer } from 'mobx-react-lite'
import { DayLevelScreen } from "../styles/components"
import { uiStore } from '../../data/UIStore'
import { dayTagStore } from '../../data/DayTagStore'
import { dayDataStore } from '../../data/DayDataStore'
import { Button } from './SettingsView'
import { DayTag } from '../../data/DayTag'

export const DayTagsEditor = observer((props) => {
  let dayData = dayDataStore.dayData;
  let date = dayData.date;

  return <DayLevelScreen title="Tags" onBack={() => uiStore.pop()}>
    {(dayTagStore.dayTags.filter(tag => tag instanceof DayTag) as DayTag[]).map(tag => {return <Button key={tag.id} onClick={() => {
      tag.manuallyToggleDate(date);
      dayData.save();
    }}>{tag.isInThisSet(date) ? "✔️" : ""} {tag.name}</Button>})}
  </DayLevelScreen>
});