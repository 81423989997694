import React from 'react';
import { observer } from 'mobx-react-lite';
import { newLinesToBR } from '@habline/common/utils';
import styled from 'styled-components'
import { uiStore } from '../data/UIStore';
import { TextEditor } from "./preferences/TextEditor"
import { DayData } from '../data/DayData';
import { Item } from '../data/Item';
import { selectedCoachingHints } from './Coaching';
import { msToTime } from '@habline/common/time';
import { openContextMenu } from './context/ItemActionsContextMenu';

export const Content = styled.div`
  padding: 1.5rem;
  font-size: 80%;
  color: ${({theme}) => theme.textLevel1 }
`;

export const NoteDom = styled.div`
  min-height: 3em;
  padding: 0 1.5rem;
  color: ${({theme}) => theme.textLevel1 }
`;

export const Title = styled.div`
  font-weight: bolder;
  height: 42px;
  line-height: 42px;
`;

export function editInsightNote(item: Item) {
  uiStore.push(
    <TextEditor
      label={item.name + " insight"}
      area={true}
      placeholder="I felt ..."
      value={item.insightNote}
      valueSetter={(value) => { item.setInsightNote(value.trim()); item.dayData.save()}}
    />
  )
}

export function editNote(dayData) {
  uiStore.push(
    <TextEditor
      label="Note"
      area={true}
      placeholder="I felt ..."
      value={dayData.note}
      valueSetter={(value) => { dayData.note = value.trim(); dayData.save()}}
    />
  )
}

export const InsightNoteContainer = styled.div`
  border-bottom: 1px ${({theme}) => theme.textLevel3} solid;
  padding: 0.75em 0em;
  display:flex;
  flex-direction: column;
  
  &:last-of-type {
    border: 0px;
  }
`;

export const InsightNoteName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.5em;
`;

export const InsightNoteActualNote = styled.div`
`;

export const NotesContent = styled(Content)`
  padding: 0rem 0rem;
`;

export function fullNote(dayData: DayData) {
  return <NoteDom>
    {dayData.items.filter(item => item.insightNote).sort((a, b) => a.completionTime - b.completionTime).map(item =>
      <InsightNoteContainer key={item.id}>
        <InsightNoteName onClick={() => openContextMenu([item], true)}>
          {msToTime(item.completionTime)} {item.category} {item.name}
        </InsightNoteName>
        <InsightNoteActualNote onClick={() => editInsightNote(item)}>
          {newLinesToBR(item.insightNote)}
        </InsightNoteActualNote>
      </InsightNoteContainer>
    )}

    {dayData.note &&
      <InsightNoteContainer onClick={() => editNote(dayData)}>
        <InsightNoteActualNote>{newLinesToBR(dayData.note)}</InsightNoteActualNote>
      </InsightNoteContainer>
    }

    {selectedCoachingHints(dayData).map(({hint, onClick}, idx) =>
      <InsightNoteContainer key={idx}>
        <InsightNoteActualNote onClick={onClick}>{hint}</InsightNoteActualNote>
      </InsightNoteContainer>
    )}      
  </NoteDom>
}

export const Notes = observer<{daysData: DayData[], dateToHeader: (date:Date)=>string}>(({daysData, dateToHeader}) => {
  let hasAnyNotes = daysData.find(dayData => dayData.note);

  return hasAnyNotes ? <NotesContent>
    {daysData.map(dayData => 
      <div key={dayData.date.getTime()} onClick={() => uiStore.push(<TextEditor label={dateToHeader(dayData.date)} area={true} placeholder="I felt ..." value={dayData.note} valueSetter={(value) => { dayData.note = value.trim(); dayData.save()}}/>)}>
        <Title style={{paddingLeft: "1.5rem"}}>{dateToHeader(dayData.date)}</Title>
        {fullNote(dayData)}
      </div>
    )}
  </NotesContent> : <></>
});

