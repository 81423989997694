import React from 'react';
import { observer } from 'mobx-react-lite';
import { uiStore } from '../../data/UIStore';
import { ContextMenu, ContextMenuOption, ContextMenuIcon, } from './ContextMenu';
import { CopyPasteOp, Item, RelocateOption } from '../../data/Item';
import { HabitItem } from '../../habits/data/HabitItem';
import { ItemStatsView } from '../ItemStatsView';
import { Status } from '@habline/common/Status';
import { HabitsDefView } from '../../habits/components/HabitsDefEditor';
import { TextEditor } from '../preferences/TextEditor';
import { capitalizeFirstLetter } from '@habline/common/utils.common';
import { ItemContextMenu } from './ItemContextMenu';
import { itemStore } from '../../data/ItemStore';
import { mainStore } from '@habline/common/MainStore';
import { scrollToItem } from '../scrollToItem';
import { action } from 'mobx';
import { RangeEditor } from './RangeEditor';

export const openInsightNoteEditor = action((item: Item) => {
  uiStore.push(<TextEditor label="Insight note" area={true} placeholder="eg. It was puzzling for me." value={item.insightNote} valueSetter={(value) => item.setInsightNote(value)}/>);
})

export const openDescriptionEditor = action((item: Item) => {
  uiStore.push(<TextEditor label="Description" area={true} placeholder="eg. Complete only if done before 10am, partial otherwise." value={item.description} valueSetter={(value) => item.setDescription(value)}/>);
})

export const duplicateItems = action((items: Item[], addingToIcebox: boolean) => {
  items.forEach(item => item.performCopy(mainStore.currentDate, addingToIcebox ? CopyPasteOp.COPY_TO_INBOX : CopyPasteOp.COPY));
  if (!addingToIcebox) {
    returnToMainScreen();
  }
  itemStore.clearSelection();
});

export const openHabit = action((item: Item) => {  
  if (item instanceof HabitItem) {
    uiStore.push(<HabitsDefView habitDef={item.habitDef}/>);
  } else {
    uiStore.toast('This is read only.') //openHabit function is often called in response to not being able to write something, we try to open the habit at that part. If it's not a habit item then show this message. HACK.
  }
})

export const openContextMenu = action((items: Item[], outsideOfListing: boolean) => {
  uiStore.showContextMenu(<ItemContextMenu items={items} outsideOfListing={outsideOfListing} />)
})

export const removeFromPlan = action((items: Item[]) => {
  for (let item of items) {
    item.setIsPartOfPlan(false);
  }
})

export const editPriority = action((item: Item) => {
  if (item.canOverridePriority) {
    uiStore.showContextMenu(<RangeEditor
      label="Priority"
      value={item.priority}
      valueSetter={(value) => item.setPriority(value)}
      canBeReset={item.isPriorityOverriden}
    />)
  } else {
    uiStore.toast("Can not override priority")
  }
})

export const moveTo = action((item:Item, option:RelocateOption) => {
  let diff = item.moveTo(option);
  
  if (diff == null) {
    uiStore.toast("Not moved", () => openContextMenu([item], true));
  } else {
    item.doNewItemEntry();
    uiStore.toast(`${item.category} ${capitalizeFirstLetter(item.name)} moved.`, () => openContextMenu([item], true));
  }
})

export const resetItems = action((items: Item[]) => {
  let firstItem = true;
  items.forEach(item => {
    if (item.status !== Status.UNKNOWN) {
      item.setUnknown()
      if (firstItem) {
        mainStore.setCurrentDateImmediatelly(item.date);
        firstItem = false;
      }
    }

    scrollToItem(item);
    itemStore.removeFromSelection(item);
  })

  returnToMainScreen();

  itemStore.clearSelection();
})

export const returnToMainScreen = action(() => {
  mainStore.showingCompletedItems = false;
  uiStore.hideContextMenu();
  uiStore.returnToCoreView();
})

export const setPartialForItems = action((items: Item[]) => {
  items.forEach(item => item.setPartial())
  itemStore.clearSelection();
})

export const setSuccessForItems = action((items: Item[]) => {
  items.forEach(item => item.setSuccess())
  itemStore.clearSelection();
})

export const skipLaterItems = action((items: Item[]) => {
  items.forEach(item => item.canBeDoneLater ? item.moveToIcebox() : item.ignore())
  itemStore.clearSelection();
})

export const ItemActions = observer<{items:Item[], addingToIcebox: boolean}>(({items, addingToIcebox}) => {
  let item = items.length === 1 ? items[0] : null;

  return <>
    { items.some(item => (item.status === Status.UNKNOWN || item.isInIcebox)) &&
      <ContextMenuOption style={{cursor: "pointer"}} onClick={() => items.forEach(item => item.status === Status.UNKNOWN || item.isInIcebox ? item.ignore() : null)}><ContextMenuIcon><i className="gg-close"></i></ContextMenuIcon> Abandon </ContextMenuOption> 
    }

    { item != null && !itemStore.isInSelection(item) &&
      <ContextMenuOption style={{cursor: "pointer"}} onClick={() => itemStore.addToSelection(item)}><ContextMenuIcon><i className="gg-play-list-add"></i></ContextMenuIcon> Select</ContextMenuOption> 
    }

    { item != null && items.some(item => itemStore.isInSelection(item)) &&
      <ContextMenuOption style={{cursor: "pointer"}} onClick={() => items.forEach(item => itemStore.removeFromSelection(item))}><ContextMenuIcon><i className="gg-play-list-remove"></i></ContextMenuIcon> Deselect</ContextMenuOption> 
    }

    { item != null && item instanceof HabitItem &&
      <ContextMenuOption style={{cursor: "pointer"}} onClick={() => openHabit(item)}><ContextMenuIcon><i className="gg-repeat"></i></ContextMenuIcon> Edit habit ...</ContextMenuOption>
    }

    { item != null &&
      <ContextMenuOption style={{cursor: "pointer"}} onClick={() => uiStore.push(<ItemStatsView item={item}/>)}><ContextMenuIcon><i className="gg-insights"></i></ContextMenuIcon> Show stats ...</ContextMenuOption> 
    }

    { true &&
      <ContextMenuOption style={{cursor: "pointer"}} onClick={() => removeFromPlan(items)}><ContextMenuIcon><i className="gg-play-list-remove"></i></ContextMenuIcon> Remove from plan</ContextMenuOption> 
    }

    { item != null && item.canOverrideDescription && item.description.length === 0 &&
      <ContextMenuOption style={{cursor: "pointer"}} onClick={() => openDescriptionEditor(item)}><ContextMenuIcon><i className="gg-notes"></i></ContextMenuIcon> Add description ...</ContextMenuOption> 
    }

    { item != null && item.insightNote.length === 0 &&
      <ContextMenuOption style={{cursor: "pointer"}} onClick={() => openInsightNoteEditor(item)}><ContextMenuIcon><i className="gg-bulb"></i></ContextMenuIcon> Add insight ...</ContextMenuOption> 
    }

    { true &&
      <ContextMenuOption style={{cursor: "pointer"}} onClick={() => duplicateItems(items, addingToIcebox)}><ContextMenuIcon><i className="gg-duplicate"></i></ContextMenuIcon> Duplicate</ContextMenuOption> 
    }
  </>
});

export const ItemActionsContextMenu = observer<{items:Item[], addingToIcebox: boolean}>(({items, addingToIcebox}) => {
  
  return <ContextMenu visible={uiStore.isContextMenuVisible} onClose={() => uiStore.hideContextMenu()}>
    <ItemActions items={items} addingToIcebox={addingToIcebox} />
  </ContextMenu>
});
