import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { observer } from 'mobx-react-lite';

export const HeaderProgressBar = observer<{successful: number, unknown: number, overlapping: number, free: number, errors: number, isLoading: boolean}>(({successful, unknown, overlapping, free, errors, isLoading}) => {
  let theme = useTheme();

  let fullDay = successful + unknown + overlapping + free + errors;

  let successfulRatio = fullDay > 0 ? successful / fullDay : 0;
  let overlappingRatio = fullDay > 0 ? overlapping / fullDay : 0;
  let freeRatio = fullDay > 0 ? free / fullDay : 0;
  let unknownRatio = fullDay > 0 ? unknown / fullDay : 0;
  let errorsRatio = fullDay > 0 ? errors / fullDay : 0;

  if (isLoading) {
    successfulRatio = 0;
    overlappingRatio = 0;
    freeRatio = 0;
    errorsRatio = 0;
    unknownRatio = 1;
  }
  
  return <ProgresBarContainer showRemaining={false} style={{height: "3px"}}>
    <SolidBar value={successfulRatio} color={theme.backgroundLevel4.toString()} />
    <SolidBar value={unknownRatio} color={theme.backgroundLevel1.toString()} />
    <SolidBar value={overlappingRatio} color={theme.textLevel2} />
    <SolidBar value={freeRatio} color={theme.green.toString()} />
    <SolidBar value={errorsRatio} color={theme.red.toString()} />
    <LoadingBar style={{opacity: isLoading ? 1 : 0}}/>
  </ProgresBarContainer>
});

export const ProgressChangeBar = observer<{value: number, showRemaining?: boolean, onClick?: ()=>void, refValue: number, desc: string, total: number}>(({value, showRemaining, onClick, refValue, desc, total}) => {
  let theme = useTheme();

  const solidRatio = total > 0 ? value / total : 0;
  const refRatio = total > 0 ? refValue / total : 0;
  const placeInside = solidRatio + refRatio > 0.1;

  return <ProgresBarContainer onClick={onClick} showRemaining={showRemaining}>
    <SolidBar value={solidRatio} color={theme.backgroundLevel3.toString()} />
    <SolidBar value={refRatio} color={theme.backgroundLevel2.toString()} />
    <CategoryDesc inverted={!placeInside}>{desc}</CategoryDesc>
  </ProgresBarContainer>
});

const CategoryDesc = styled.div<{inverted?: boolean}>`
  position: ${({inverted}) => inverted ? "relative" : "absolute"};;
  color: ${({inverted, theme}) => inverted ? theme.backgroundLevel4.toString() : theme.backgroundLevel0.toString()};
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  left: 0.5rem;
`;

//; ${style.};
const ProgresBarContainer = styled.div<{showRemaining?: boolean}>`
  ${({showRemaining}) => showRemaining && css`background-color: #EEE`};
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

const SolidBar = styled.div<{value: number, color: string}>`
  background-color: ${({color}) => color}; 
  height: 100%;
  width: 0;
  transition: width 0.25s ease-in-out;
  width: ${({value}) => (value * 100).toFixed(3)}%;
`;

const LoadingBar = styled.div`
  position: absolute;
  height: 100%;
  width: 0;
  background: repeating-linear-gradient(to right, ${({theme}) => theme.backgroundLevel3.toString()} 0%,${({theme}) => theme.backgroundLevel1.toString()} 50%, ${({theme}) => theme.backgroundLevel3.toString()} 100%);
  width: 100%;
  background-size: 200% auto;
  background-position: 0 100%;
  animation: gradient 2s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  transition: opacity 1s;
`;