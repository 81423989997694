import { err, toExcelDate } from "@habline/common/utils";
import { action, computed, makeObservable, observable } from "mobx"
import { isInThisDaySet, dayTagStore } from "./DayTagStore";
import { mainStore } from "@habline/common/MainStore";
import { finalParser, tagNameParser } from "../data/every";

export abstract class DayTagCommon {
  id:string;

  abstract isInThisSet(date:Date): boolean

  constructor() {
    makeObservable(this, {
      name: computed
    });
  }

  get name() {
    return this.id || "";
  }
}

export class DayTag extends DayTagCommon {
  description: string;
  autoExpression: string;
  exists: boolean;

  manuallyAddedTo: Set<string>;
  manuallyRemovedFrom: Set<string>;

  constructor({id = null, description = "", autoExpression = "", exists = false, manuallyAddedTo = new Set<string>(), manuallyRemovedFrom  = new Set<string>()} = {}) {
    super();
    this.id = id;
    this.description = description;
    this.autoExpression = autoExpression;
    this.exists = exists;
    this.manuallyAddedTo = manuallyAddedTo;
    this.manuallyRemovedFrom = manuallyRemovedFrom;

    makeObservable(this, {
      id: observable,
      description: observable,
      autoExpression: observable,
      exists: observable,
      manuallyAddedTo: observable,
      manuallyRemovedFrom: observable,
      
      compiledAutoExpression: computed,
      //nameError: action,
      //autoExpressionError: action,
      //TODO: This causes the view not to be updated, why? : isInThisSet: action, This does not modify state though
      save: action,
      delete: action,
      manuallyAddTo: action,
      manuallyRemoveFrom: action,
      manuallyToggleDate: action,
    });
  }

  get compiledAutoExpression() { return finalParser.parse(this.autoExpression.toLowerCase()); }

  nameError(value: string) {
    if (dayTagStore.dayTags.filter(set => set.name.toLowerCase() === value.toLowerCase()).length > ((this.exists && this.name === value ) ? 1 : 0)) {
      return `Duplicate name found: ${value}`
    }
  
    if (value.length === 0) {
      return `Please enter a name of the set`
    }

    if (!tagNameParser.parse(value).status) {
      return `Invalid name`
    }

    return null;
  }

  autoExpressionError(value: string) {
    if (value === "") {
      return null;
    }

    try {
      finalParser.tryParse(value.toLowerCase()).isInThisSet(mainStore.currentDate)
    } catch (e) {
      console.log(e);
      return "" + e.message;
    }

    return null;
  }

  get error() {
    return this.nameError(this.name) || this.autoExpressionError(this.autoExpression);
  }

  static toFirestore(dayTag: DayTag) {
    return {
      description: dayTag.description,
      autoExpression: dayTag.autoExpression,
      manuallyAddedTo: [...dayTag.manuallyAddedTo],
      manuallyRemovedFrom: [...dayTag.manuallyRemovedFrom],
    };
  }

  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    let expr = "";

    if (data.autoExpression) {
      expr = data.autoExpression;
    } else {
      const excluded = [...(data.excludedDays ? data.excludedDays : []), ...(data.excludedSets ? data.excludedSets : []), ...(data.excludedDates ? data.excludedDates : [])];
      const included = [...(data.includedDays ? data.includedDays : []), ...(data.includedSets ? data.includedSets : []), ...(data.includedDates ? data.includedDates : [])];

      expr = included.join("|");

      if (excluded.length > 0 && included.length > 0) {
        expr = `(${expr})`
      }

      if (excluded.length > 0) {
        expr = `${expr} & ${excluded.map(e => "!" + e).join("&")}`
      }
    }

    return new DayTag({
      id: snapshot.ref.id,
      description: data.description ? data.description : "",
      autoExpression: expr,
      manuallyAddedTo: new Set<string>(data.manuallyAddedTo ? data.manuallyAddedTo : []),
      manuallyRemovedFrom: new Set<string>(data.manuallyRemovedFrom ? data.manuallyRemovedFrom : []),
      exists: true,
    });
  }

  isInThisSet(date: Date) {
    let strDate = toExcelDate(date);

    if (this.manuallyRemovedFrom.has(strDate)) {
      return false;
    }

    if (this.manuallyAddedTo.has(strDate)) {
      return true;
    }

    return isInThisDaySet(date, this);
  }

  async manuallyAddTo(date: Date) {
    let strDate = toExcelDate(date);
    this.manuallyRemovedFrom.delete(strDate);
    this.manuallyAddedTo.add(strDate);
    return this.save();
  }

  async manuallyRemoveFrom(date: Date) {
    let strDate = toExcelDate(date);
    this.manuallyAddedTo.delete(strDate);
    this.manuallyRemovedFrom.add(strDate);
    return this.save();
  }

  async manuallyToggleDate(date: Date) {
    if (this.isInThisSet(date)) {
      return this.manuallyRemoveFrom(date);
    } else {
      return this.manuallyAddTo(date);
    }
  }

  async save() {
    this.exists = true;
    return dayTagStore.collection.doc(this.id).set(this).then(() => { console.log(`Day set ${this.name} (${this.id}) written`) }).catch(err);
  }

  async delete() {
    return dayTagStore.collection.doc(this.id).delete().then(() => {
      console.log(`Day set ${this.name} (${this.id}) deleted`);
    }).catch(err);
  }
}
