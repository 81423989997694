import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

export const Help = styled.div<{highlight?: boolean}>`
  text-align: left;
  font-size: 80%;
  color: ${({theme}) => theme.textLevel1};
  padding-bottom: 0.5em;
  display:block;
  font-weight: ${({highlight}) => highlight ? "bold" : "normal"};
`;

export const Divider = styled.hr`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;

export const DayLevelScreen: FunctionComponent<{title:string, onTitleClick?:()=>void, onClose?:()=>void, onBack?:()=>void, onPlus?:()=>void, onSave?:()=>void, onSaveText?:string, children:any}> =
  ({title = "Title", onTitleClick, onClose, onBack, onPlus, onSave, onSaveText = "Done", children}) => {
    return <Container>
      <FixedHeader>
      {onBack ? <BackButton onClick={onBack}><i className="gg-arrow-left"></i></BackButton> : ""}
      {onClose ? <BackButton onClick={onClose}><i className="gg-close"></i></BackButton>: ""}
      <Title onClick={onTitleClick}>{title}</Title>
      {onPlus ? <BackButton onClick={onPlus}><i className="gg-math-plus"></i></BackButton> : ""}
      {onSave ? <SaveButton onClick={onSave}>{onSaveText}</SaveButton> : ""}
      </FixedHeader>
      <HeaderPadding />
      {children}
    </Container>
  }

const BackButton = styled.div`
  line-height: 35px;
  width: 32px;
  height: 42px;
  top: 1px;
  font-size: 30px;
  font-weight: 100;
  text-align: center;
  display: flex;
  color: ${({theme}) => theme.textLevel1};
  justify-content: center;
  align-items: center;
  color: var(--aic);
  flex-direction: row;
  box-sizing: border-box;
  margin-left: 10px;
  margin-right: 10px;
`;

const FixedHeader = styled.div`
  height: 42px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background: ${({theme}) => theme.backgroundLevel1.toString()};
  box-shadow:0px 2px 2px rgba(0, 0, 0, 0.2);
  display:flex;
  z-index: 2000;
`;

export const FixedFooter = styled.div`
  height: 42px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  color: ${({theme}) => theme.backgroundLevel1.toString()};
  background-color: ${({theme}) => theme.backgroundLevel4.toString()};
  box-shadow:0px -2px 2px 0px rgba(0,0,0,0.2);
`;

const HeaderPadding = styled.div`
  height: 48px;
  width: 100%;
  box-sizing: border-box;
`;

const Container = styled.div`
  padding: 0.2em 1em 0.2em 1em;
  position: relative;
  width: 100%;
  box-sizing: border-box;
`;

const SaveButton = styled.button`
  margin-top: 6px;
  height: 30px;
  width: 100px;
  line-height: 30px;
  margin-left: 10px;
  margin-right: 10px;
`;

const Title = styled.div`
  text-align: left;
  height: 42px;
  line-height: 42px;
  justify-self: center;
  align-self: center;
  flex-grow: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;