import React from 'react'
import { logEvent } from '@habline/common/utils'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { DayLevelScreen } from "./styles/components"
import { TaskItem } from '../data/TaskItem'
import { itemStore } from '../data/ItemStore'
import { Help } from './styles/components'
import { Item } from '../data/Item'
import { dayDataStore } from '../data/DayDataStore'
import { action, makeAutoObservable } from "mobx"
import { Status } from '@habline/common/Status'
import { uiStore } from '../data/UIStore';
import { CategoryEditor } from './preferences/CategoryEditor'
import { IceboxDailyItemView } from './DailyItemView'
import { newId } from '@habline/common/firebase'
import { describeDate, mainStore } from '@habline/common/MainStore'

const PostponedTaskButton = styled.button`
  flex-grow: 0;
  padding: 0px 10px;
  margin: 0px 5px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: row;
`;

const FormCategory = styled.div`
  flex-grow: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-right: 10px;
`;

const FormInput = styled.input`
  flex-grow: 1;
  width: "100%";
  margin: 0;
`;

function sortCompareForAdd(item1: Item, item2: Item): number {
  let adventage = 0;

  if (item1.isInIcebox) adventage += 1000;
  if (item2.isInIcebox) adventage -= 1000;
  if (item1.status === Status.SUCCESS) adventage += 100;
  if (item2.status === Status.SUCCESS) adventage -= 100;
  if (item1.status === Status.PARTIAL) adventage += 50;
  if (item2.status === Status.PARTIAL) adventage -= 50;
  if (item1.isUnknown) adventage += 200;
  if (item2.isUnknown) adventage -= 200;

  adventage += item1.pointWeight;
  adventage -= item2.pointWeight;

  if (item1.name < item2.name) adventage -= 0.1;
  if (item1.name > item2.name) adventage += 0.1;

  function time(item: Item) {
    return item.date.getTime() + item.time;
  }

  if (item1.isUnknown) {
    if (time(item1) < time(item2)) adventage += 10;
  } else {
    if (time(item1) > time(item2)) adventage -= 10;
  }

  if (item1.priority < item2.priority) adventage -= 15;
  if (item1.priority > item2.priority) adventage += 15;

  return adventage;
}

class AddDataStore {
  constructor() {
    makeAutoObservable(this);
  }

  get possibleItemsToAdd() {
    console.log("Reaclculating possible items to add");

    let names: {[id:string]: Item} = {};

    for (const dayData of dayDataStore.allLoadedDaysData) {
      for (const item of dayData.items) {
        const key = item.name.trim().toLowerCase();

        if (item.reuses.length > 0 && (item.isMissed)) {
          continue;
        }

        if (!names[key]) {
          names[key] = item;
        } else {
          
          if (sortCompareForAdd(item, names[key]) > 0) {
            names[key] = item;
          }
        }
      }
    }

    console.log(names)
    return Object.entries(names);
  }
}

const addDataStore = new AddDataStore();

const DEFAULT_CATEGORY = "⭐";

export const AddTaskDialog = observer<{onClose: () => void, date:Date}>(props => {
  const [text, setText] = React.useState("");
  const [category, setCategory] = React.useState(null);
  const [init, setInit] = React.useState(true);
  const [addingToIcebox, setAddingToIcebox] = React.useState(false);
  
  // Similar to componentDidMount and componentDidUpdate:
  React.useEffect(() => {
    if (init) {
      setInit(false);
    }
  }, [init]);

  function clearSelection() {
    setText("");
    setCategory(null)
  }

  let addTask = action(() => {
    if (text.trim().length > 0) {
      logEvent('added_task', {date: props.date});
      dayDataStore.requestDayData(props.date).addItem(new TaskItem({id: newId(), manualName: text, manualCategory: category, date: props.date, manuallyPutInIcebox: addingToIcebox, status: addingToIcebox ? Status.MISSED : Status.UNKNOWN}))
      if (addingToIcebox) {
        clearSelection();
      } else {
        props.onClose();
      }
    }
  })

  let itemSuggestions = new Array<Item>();
  let searchText = text.trim().toLowerCase();
  if (searchText.length > 0 || category) {
    console.log("KEY FACTOR", searchText, category)
    const onlyFirst = searchText.length < 3

    for (let [key, item] of addDataStore.possibleItemsToAdd) {
      if (!category || item.category === category) {
        if (onlyFirst) {
          if (key.startsWith(searchText)) {
            itemSuggestions.push(item);
          }
        } else {
          if (key.indexOf(searchText) !== -1) {
            itemSuggestions.push(item);
          }
        }
      }
    }
  }
  itemSuggestions.sort(sortCompareForAdd).reverse();
  
  let itemElements = new Array<any>();
  const MAX_POSTPONED = 10;
  let postponedItems = [...itemStore.postponedItems.filter(item => item.manuallyPutInIcebox || item.date.getTime() !== mainStore.currentDate.getTime() )];

  if (text.length === 0 && !category) {
    itemElements.push(<Help key="help">Recently postponed:</Help>);

    for (let item of postponedItems) {
      if (!category || item.category === category) {
        itemElements.push(
          <IceboxDailyItemView key={item.id} item={item} addingToIcebox={addingToIcebox} />
        );
      }

      if (itemElements.length === MAX_POSTPONED) {
        break;
      }
    }
  }

  if (itemSuggestions.length > 0) {
    itemElements.push(<Help key="help">Suggestions:</Help>);
    for (let item of itemSuggestions) {
      if (!category || item.category === category) {
        itemElements.push(<IceboxDailyItemView key={item.id} item={item} addingToIcebox={addingToIcebox}/>);
      }
    }
  }
  
  return <DayLevelScreen
    title={`Add task to ${addingToIcebox ? "postponed": describeDate(mainStore.currentDate)}`}
    onTitleClick={() => setAddingToIcebox((addingToIcebox) => !addingToIcebox)}
    onClose={props.onClose}
    onSave={text.length > 0 ? () => addTask() : undefined}
    onSaveText="Add">
    <Form action="my_redirect_url" method="post" onSubmit={e => {addTask(); e.preventDefault()}}>
      <FormCategory 
        onClick={() => uiStore.push(<CategoryEditor value={category} valueSetter={(value) => {
          setCategory(value);
        }}/>)}
      >
        {category ? category : DEFAULT_CATEGORY}
      </FormCategory>
      <FormInput placeholder="eg. Get groceries" type="text" value={text} onChange={(event) => {console.log(event); setText(event.target.value); }} />  
      {searchText.length > 0 && <PostponedTaskButton type="button" onClick={() => {clearSelection()}}>x</PostponedTaskButton>}
    </Form>
    {itemElements}
  </DayLevelScreen>
}); //ref={input => input && input.focus()}
