import React, { useState } from 'react';
import { gapiAuth } from "../data/gapi"
import { logEvent, sameDateOnADifferentYear } from '@habline/common/utils';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { describeDate, mainStore } from '@habline/common/MainStore';
import { HistoricDailyItemList } from './HistoricDailyItemList';
import { AddTaskDialog} from './add';
import { uiStore } from '../data/UIStore';
import { dayDataStore } from '../data/DayDataStore';
import { HoverButton, BigHoverButton } from './HoverButton';
import { copyPasteStore } from './CopyPasteStore';
import { fullNote } from './Notes';
import { Coaching } from "./Coaching"
import { DailyItemList } from './DailyItemList';
import { Center } from '@habline/common/primitives';
import { action } from 'mobx';
import { Divider } from './styles/components';

const Content = styled.div`
  text-align:center;
  color: ${({theme}) => theme.textLevel1};
  padding: 1.5rem;
`;


const AYearAgo = observer<{date:Date}>(({date}) => {
  const [aYearAgo, setAYearAgo] = useState(null);

  React.useEffect(() => {
    setAYearAgo(dayDataStore.requestDayData(sameDateOnADifferentYear(date, -1)))
  }, [date]);
  
  return aYearAgo && aYearAgo.didAnyThingHappendOnThisDay && <div onClick={() => {
    mainStore.setCurrentDate(aYearAgo.date)
  }}>On a same day a year ago ...</div>
});

export const DayView = observer((props) => {
  
  function openAddTask() {
    logEvent('open_add_dialog');
    uiStore.push(<AddTaskDialog date={mainStore.currentDate} onClose={() => uiStore.pop()}/>)
  }

  let content = null;
  let dayData = dayDataStore.dayData;

  if (!gapiAuth.workspace || !dayData) {

  } else if (mainStore.showingCompletedItems) {
    content = <React.Fragment>
      <Content style={{cursor: "pointer"}} onClick={() => {mainStore.showingCompletedItems = !mainStore.showingCompletedItems}}>
        <span>Back</span>
        
      </Content>
      <Divider style={{marginLeft: "1em", marginRight: "1em"}} />
      
      <HistoricDailyItemList isMultiDay={false} items={dayData.completedItems} />

    </React.Fragment>
  } else {
    if (dayData.sealed) {
      content = <>
        <Content>
          <Center>Your score for {describeDate(dayData.date)}:</Center>
          <div style={{fontSize: 30}}>
            <Center>{dayData.score}</Center>
          </div>
        </Content>

        { (dayData.date.getTime() === mainStore.today.getTime()) &&<Content><button onClick={action(() => {
          dayData.sealed = false;
          dayData.save();
        })}>Reopen {describeDate(mainStore.currentDate)}</button></Content>}

        {dayData.sealed && <>{fullNote(dayData)}

        <Content>
          <AYearAgo date={dayData.date}/>
        </Content></>}
      </>
    } else {
      content = <>
        <Content>
          <Coaching dayData={dayData} />
          { dayData.itemsAndEndersSequence.length > 0 && <Divider /> }
        </Content>

        { (dayData.date.getTime() < mainStore.today.getTime() || (dayData.date.getTime() === mainStore.today.getTime() && dayData.itemsAndEndersSequence.length === 0)) && <Content><button style={{height: "auto"}} onClick={() => {
          //let {iceBoxed, skipped} = dayData.ripTasks();
          //let text = skipText(iceBoxed, skipped, false);
          //if (text.length > 0) {
          //  uiStore.toast(text);
          //}
          dayData.sealed = true;
          dayData.save();
        }}>Seal {describeDate(mainStore.currentDate)}</button></Content>}

        <DailyItemList dayData={dayData} />
      </>
    }
  }

  return <div>
    {content}

    {!copyPasteStore.isReusing && gapiAuth.user ? <HoverButton onClick={openAddTask} position="right"><i style={{"--ggs": 1.4} as React.CSSProperties} className="gg-math-plus"></i></HoverButton> : ""}
    {copyPasteStore.isReusing && gapiAuth.user ? <BigHoverButton onClick={() => copyPasteStore.execute()} position="right">{copyPasteStore.description}</BigHoverButton> : ""}
  </div>
});