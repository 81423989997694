import { filterInPlace } from "@habline/common/utils"
import { autorun, makeAutoObservable } from "mobx"
import { gapiAuth } from "../../data/gapi"
import { fb, newId } from "@habline/common/firebase";
import { hackToSolveAccessError } from "../../data/ItemStore"
import { HabitDef } from "./HabitDef"
import { HabitItem } from "./HabitItem"
import { Status } from "@habline/common/Status"
import { Item } from "../../data/Item"
import { DayData } from "../../data/DayData"
import { HabitDefSource } from "./HabitDefSource"

export let i = hackToSolveAccessError;

class HabitStore {
  _habitDefs: HabitDefSource = new HabitDefSource();

  constructor() {
    makeAutoObservable(this);
  }

  init() {
    autorun(() => {
      if (gapiAuth.workspace) {
        this._habitDefs.subscribeToChanges();
      } else {
        this._habitDefs.unsubscribeFromChanges();
      }
    });
  }

  get habitDefs() {
    return this._habitDefs.habitDefs;
  }

  get deactivatedDefs() {
    return this._habitDefs.deactivatedDefs;
  }

  get timeItemAndAnchorStore() {
    return this._habitDefs;
  }

  get isLoadedForTheFirstTime() {
    return this._habitDefs.isLoadedForTheFirstTime;
  }

  get habitDefMap(): {[id: string]: HabitDef} {
    return Object.assign({}, ...this.habitDefs.map((habitDef) => ({[habitDef.id]: habitDef})));
  }

  get dbReference() {
    return fb.firestore().collection(`/workspaces/${gapiAuth.user.uid}/habitDefs`).withConverter(HabitDefSource).doc(HabitDefSource.DB_ID);
  }

  habitsForDate(date: Date) {
    var habitsForDate = [];
      
    for (let habitDef of this.habitDefs) {
      if (habitDef.isInThisSet(date) && habitDef.isActive) {
        habitsForDate.push(new HabitItem({
          id: newId(),
          defId: habitDef.id,
          date: date,
          status: Status.UNKNOWN,
        }))
      }
    }

    return habitsForDate;
  }

  injectIntoItems(dayData: DayData, computedHabitItems: HabitItem[], currentItems: Item[] = []) {
    if (!this.isLoadedForTheFirstTime) return;

    const originaLength = dayData.items.length;
    
    //No need to update as all fields are taken automatically via getters from habit, this makes it reliant on existanance of a habit but less data duplication
    let forInsertion = computedHabitItems.filter(habitItem => !dayData.items.find(item => item instanceof HabitItem && item.defId === habitItem.defId && !item.reused))

    dayData.injectIntoItems(...forInsertion);

    //keep items that changed status or are in still in computerd items
    filterInPlace(dayData.items, item =>
      item.type !== "habit" ||
      item.status !== Status.UNKNOWN ||
      computedHabitItems.find(habitItem => habitItem.defId === item.defId) ||
      item.reused
    );

    console.log(`Injected ${computedHabitItems.length} habits into ${dayData.id} (${originaLength}) -> (${dayData.items.length})`, );
  }
}
  
export const habitStore = new HabitStore();