import React, { useEffect, useState } from "react";
import styled, { css } from 'styled-components';

export const ContextMenuOption = styled.div<{selected?:boolean}>`
  margin-bottom: 2rem;
  font-size: 1.5rem;
  display: flex;
  align-content: center;
  flex-direction: row;
  
  ${({selected, theme}) => selected && css`
    border: 1px ${theme.backgroundLevel2.toString()} solid;
  `}

  border-radius: 3px;
`;

export const ContextMenuDivider = styled.div`
  color: ${({theme}) => theme.backgroundLevel1.toString()};
  border-bottom: 1px solid ${({theme}) => theme.backgroundLevel3.toString()};
  margin-bottom: 2rem;
`;

export const ContextMenuIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  margin-left: -1rem;
`;

export const ContextMenuRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 2rem;

  align-items: center;
  > * {
    margin-bottom: 0;
  }
`; 

export const ContextMenuTextOption = styled(ContextMenuOption)`
  overflow: scroll;
  max-height: 40vh;
  flex-grow: 1;
`;

export const ContextMenuInlineButton = styled.button<{isToggled?: boolean}>`
  cursor: pointer;
  color: ${({isToggled, theme}) => isToggled ? theme.textLevel2.toString() : "none"};
  padding-left: 10px;
  padding-right: 10px;
  min-height: 3.5rem;
  margin: 0;
`;

export const ContextMenuActionButton = styled.button`
  flex-grow: 2;
  padding: 0 5px;
  margin-right: 10px;

  &:last-of-type {
    margin-right: 0;
  }
`;
  
const ContextMenuPanel = styled.div<{visible: boolean}>`
  background-color: rgba(0,0,0,0.5);
  color: ${({theme}) => theme.textLevel0};
  display: flex;
  pointer-events: ${({visible}) => visible ? "auto" : "none"};
  opacity: ${({visible}) => visible ? "1.0" : "0.0"};
  margin-top: 0.5em;
  min-width: 50%;
  z-index: 15000;
  position: fixed;
  bottom: 0;
  transition: opacity 0.3s;
  right: 0;
  width: 100%;
  height: 100%;
  flex-direction: column-reverse;
`;

const ContextMenuOptions = styled.div<{visible:boolean}>`
  background-color: ${({theme}) => theme.backgroundLevel1.toString()};
  padding: 2rem;
  padding-bottom: 3rem;
  margin-bottom: ${(({visible}) => visible ? 0 : -100)}px;
  transition: margin-bottom 0.3s;
  
  &>:last-child {
    margin-bottom: 0;
  }
`;

export const ContextMenu = ({children, visible, onClose}) => {
  //Start transition from invisible state
  const [firstRender, setfirstRender] = useState(true);
  const wasFirstRender = firstRender;
  useEffect(() => {
    setTimeout(() => {
      setfirstRender(false);
    }, 0)
  }, [])

  //Block scrolling
  const [scrollingBlocked, setScrollingBlocked] = useState(false);

  useEffect(() => {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = 'fixed';
    setScrollingBlocked(true);
    return () => {
      if (scrollingBlocked) {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
        setScrollingBlocked(false);
      }
    };// eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!visible) {
      if (scrollingBlocked) {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
        setScrollingBlocked(false);
      }
    }
  }, [visible, scrollingBlocked]);

  return <ContextMenuPanel visible={!wasFirstRender && visible} onClick={() => onClose()}>
    <ContextMenuOptions visible={!wasFirstRender && visible} >
        {children}
    </ContextMenuOptions>
  </ContextMenuPanel>
};