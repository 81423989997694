export * from './utils.common';
import React from 'react';

export function newLinesToBR(str:string) {
  let lines = str.split("\n");
  return <span>{lines.map(function(item, idx) {
    return (
      <span key={idx}>
          {item}
          {idx < lines.length - 1 && <br/>}
      </span>
    )
  })}
  </span>
}