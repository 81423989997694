import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { observer } from "mobx-react-lite";
import { Help } from "../../components/styles/components";
import { msToDuration, msToTime } from '@habline/common/time';

import { DragDropContext, Droppable, Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { action, toJS } from 'mobx';
import { correctAnchors } from '../data/HabitDefList';
import { HabitDef } from '../data/HabitDef';
import { HabitDefSource } from '../data/HabitDefSource';
import { uiStore } from '../../data/UIStore';
import { HabitsDefView } from './HabitsDefEditor';
import { datePlusDays, datesInRange } from '@habline/common/utils';
import { mainStore } from '@habline/common/MainStore';
import { dayDataStore } from '../../data/DayDataStore';
import { extractStats } from '../../data/StatsStore';
import { Item, ItemPriority } from '../../data/Item';
import { HabitItem } from '../data/HabitItem';
import { backgroundForItem } from '../../components/DailyItemView';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const HabitsDefListItem = styled.div<{active?: boolean, isDragging?: boolean, priority: ItemPriority}>`
  text-align: left;
  padding-bottom: 0.5em;
  text-decoration: ${props => props.active ? "initial": "line-through"};
  display:block;

  user-select: 'none';
  background: ${({theme, isDragging, priority}) => backgroundForItem(priority, isDragging, theme)};
`;

const EmptySeparatorView = observer(({provided, snapshot}: {provided: DraggableProvided, snapshot: DraggableStateSnapshot}) => {
  return <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      >
    <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "5px", padding: "2px"}}>
      <div style={{height: "1px", backgroundColor:"#EEE", width: "50%"}}>
      </div>
    </div>
  </div>
});

const HabitDefListItemView = observer(({habitDef, provided, snapshot}: {habitDef: HabitDef, provided: DraggableProvided, snapshot: DraggableStateSnapshot}) => {
  const [stats, setStats] = useState({completionRatio: 1});
  
  useEffect(() => {
    const recentDays = datesInRange(datePlusDays(mainStore.today, -30), datePlusDays(mainStore.today, -1))
    const recentDaysData = recentDays.map(date => dayDataStore.requestDayData(date));
    const stats = extractStats(recentDaysData, {
      itemTest: (item: Item) => item instanceof HabitItem && item.defId === habitDef.id,
      isDayApplicableTest: (dayData, dailyStats) => dayData.date.getTime() <= mainStore.today.getTime() && habitDef.isInThisSet(dayData.date) && dailyStats.successful + dailyStats.ignored > 0,
      referenceDuration: habitDef.duration
    })
    setStats(stats);
  }, [habitDef])

  return <HabitsDefListItem
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
    isDragging={snapshot.isDragging}
    key={habitDef.id}
    active={habitDef.isActive}
    priority={habitDef.priority}
    onClick={() => uiStore.push(<HabitsDefView habitDef={habitDef} />)}>
      {habitDef.category} {habitDef.name}
      
      {stats.completionRatio < 0.25 ?
        <Help style={{color: "red"}}>⚠ Low adherence in last 30 days.</Help>
      :
        <Help>{habitDef.autoExpression} {habitDef.duration > 0 && <>for {msToDuration(habitDef.duration)}</>}</Help>
      }
  </HabitsDefListItem>
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//: FunctionComponent<{labelStore: , itemRenderer: }> 
export const HabitDefList = observer(
  ({itemAndAnchorStore}: {itemAndAnchorStore: HabitDefSource}) => {
    
    console.log(itemAndAnchorStore.anchors);

    let onDragEnd = action((result: any) => {
      const { source, destination } = result;

      // dropped outside the list
      if (!destination || source.droppableId !== "droppable" || destination.droppableId !== "droppable") {
          return;
      }

      let addingAfter = source.index <= destination.index;
      let srcPositioningData = indexToPositioningData[source.index];
      let destPositioningData = indexToPositioningData[destination.index + (addingAfter ? 1 : 0)];

      let item = itemAndAnchorStore.timeItems.find(item => item.id === srcPositioningData.itemId);
      item.timeAnchor = destPositioningData.timeAnchor
      item.positionRelativeToAnchor = destPositioningData.positionRelativeToAnchor;
      
      console.log("NEW POS", item.timeAnchor, item.positionRelativeToAnchor, source, destination)

      correctAnchors(itemAndAnchorStore);
      itemAndAnchorStore.save();

      itemAndAnchorStore.unblockUpdates();
    });

    let draggableItem = (item: HabitDef, index: number) => <Draggable
      key={item.id}
      draggableId={item.id}
      index={index}
      isDragDisabled={false || false}>
      {(provided, snapshot) => (
        <HabitDefListItemView habitDef={item} provided={provided} snapshot={snapshot}/>
      )}
    </Draggable>

    let items = [];
    let indexToPositioningData: {timeAnchor: number, positionRelativeToAnchor: number, itemId: string}[] = [];

    let index = 0;
    let first = true;
    let prevAnchor = null;
    for (let anchor of itemAndAnchorStore.anchors) {

      if (first) {
        first = false;
      } else {
        
        ((prevAnchor, index, separatorRenderer, nextAnchor) => {
          items.push(
            <Draggable
              isDragDisabled={true}
              key={anchor.time + "separator"}
              draggableId={anchor.time + "separator"}
              index={index}>
              {(provided, snapshot) => (
                <EmptySeparatorView provided={provided} snapshot={snapshot} />
              )}
            </Draggable>
          )
        })(prevAnchor, index, anchor);
        index++;
      }

      let prevPosition = Number.MIN_SAFE_INTEGER;
      let nextPosition = (anchor.before.length > 0 ? anchor.before[0].positionRelativeToAnchor: 0) - 1 ;

      for (let item of anchor.before) {
        prevPosition = nextPosition
        nextPosition = item.positionRelativeToAnchor;
        indexToPositioningData.push({timeAnchor: anchor.time, positionRelativeToAnchor: (prevPosition + nextPosition) / 2, itemId: item.id})
        items.push(draggableItem(item, index))
        index++;
      }

      prevPosition = nextPosition
      nextPosition = 0;
      indexToPositioningData.push({timeAnchor: anchor.time, positionRelativeToAnchor: (prevPosition + nextPosition) / 2, itemId: null});
      ((prevAnchor, index) => {
        items.push(
          <Draggable
            key={anchor.time}
            isDragDisabled={true}
            draggableId={anchor.time.toString()}
            index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    >
                  <div style={{display: "flex", justifyContent: "center", padding: "0.6em"}}><b>{msToTime(anchor.time)}</b></div>
                </div>
            )}
          </Draggable>
        )
      })(prevAnchor, index);
      
      index++;

      for (let item of anchor.after) {
        prevPosition = nextPosition
        nextPosition = item.positionRelativeToAnchor;
        indexToPositioningData.push({timeAnchor: anchor.time, positionRelativeToAnchor: (prevPosition + nextPosition) / 2, itemId: item.id})
        items.push(draggableItem(item, index))
        index++;
      }

      prevPosition = nextPosition
      nextPosition = Number.MAX_SAFE_INTEGER;
      indexToPositioningData.push({timeAnchor: anchor.time, positionRelativeToAnchor: (prevPosition + nextPosition) / 2, itemId: null})

      prevAnchor = anchor;
    }

    console.log("indexToPositioningData", indexToPositioningData)
    console.log("items", toJS(itemAndAnchorStore.timeItems))
    console.log("labels", toJS(itemAndAnchorStore.anchors))

    return <DragDropContext onDragEnd={onDragEnd} onDragStart={() => itemAndAnchorStore.blockUpdates()}>
      <Droppable droppableId="droppable">
          {(provided, snapshot) =>
            <div ref={provided.innerRef}>
              {items}
              {provided.placeholder}
            </div>
          }
      </Droppable>
    </DragDropContext>
});