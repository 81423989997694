import React from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { DayLevelScreen } from '../styles/components';
import { uiStore } from '../../data/UIStore'

export const WEIGHT_UNIT = "task";
export const WEIGHT_UNIT_PLURAL = "tasks";

export function withUnit(pts) {
  if (pts === 1) {
    return <Value>{pts} {WEIGHT_UNIT}</Value>
  } else {
    return <Value>{pts} {WEIGHT_UNIT_PLURAL}</Value> 
  }
}

export const HelpPage = observer((props) => {
  return <DayLevelScreen title="Help" onClose={() => uiStore.pop()}>
    <Content>
      <p>This is a help page</p>
      <p>Each task you complete adds {withUnit(1)} to your score if the tasks duration is less than 30 minutes (more points for longer tasks). Partial completion adds half of this score.</p>
    </Content>
  </DayLevelScreen>
});

const Value = styled.span`
  font-weight: "bold";
  font-family: "Trebuchet MS", Helvetica, sans-serif;
`;

const Content = styled.div`
  text-align:left;
  color: ${({theme}) => theme.textLevel1};
  padding: 1.5rem;
`;