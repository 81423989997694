import { LinearColorInterpolator } from "./LinearColorInterpolator";

export class Color {
  readonly r: number;
  readonly g: number;
  readonly b: number;
  readonly s: string;
  
  constructor(hexOrObject: string|{r: number, g: number, b: number}) {
    var obj: {r: number, g: number, b: number};
    if (hexOrObject instanceof Object) {
        obj = hexOrObject;
    } else {
        obj = LinearColorInterpolator.convertHexToRgb(hexOrObject);
    }
    this.r = obj.r;
    this.g = obj.g;
    this.b = obj.b;
    this.s = "rgb("+this.r+", "+this.g+", "+this.b+")";
  }

  toString() {
    return this.s;
  }
}