import { makeAutoObservable } from "mobx"
import { datePlusDays, datesInRange, median } from "@habline/common/utils"
import { mainStore } from "@habline/common/MainStore";
import { dayDataStore } from "./DayDataStore";
import { Status } from "@habline/common/Status";
import { DayData } from "./DayData";
import { DURATION_SECOND, pointWeight } from "@habline/common/time";
import { Item } from "./Item";

export function medianInfo(daysData: DayData[]) {
  const {dailyStats} = extractStats(daysData, {});
  const meaningfulDailyStats = dailyStats.filter(({successful}) => successful > 0)

  return {
    medianSuccessful: median(meaningfulDailyStats.map(({successful}) => successful)),
  }
}

export function extractCategories(daysData: DayData[]) {
  let categories = new Set<string>();

  for (let dayData of daysData) {
    for (let item of dayData.items) {
      categories.add(item.category.toString())
    }
  }

  return categories;
}

export function extractStats(daysData: DayData[], {itemTest, isDayApplicableTest, referenceDuration}: {itemTest?: (item: Item) => boolean, isDayApplicableTest? : (dayData: DayData, dailyStats: {date: Date, successful: number, unknown: number, ignored: number, total: number}) => boolean, referenceDuration?: number} = {}) {
  let dailyStats: {date: Date, successful: number, unknown: number, ignored: number, total: number, overlapping: number, errors: number}[] = [];
  let completionTimes = [];
  let completionDurations = [];
  let totalApplicableDays = 0;

  for (let dayData of daysData) {
    let successful = 0;
    let unknown = 0;
    let overlapping = 0;
    let errors = 0;
    let ignored = 0;
    let prevItem = null;

    for (let item of dayData.items) {
      if (!itemTest || itemTest(item)) {
        if (item.status === Status.SUCCESS) { successful += 1 * item.pointWeight; }
        if (item.status === Status.PARTIAL) { successful += 0.5 * item.pointWeight; }
        if (item.isMissed) { ignored += 1; }
        if (item.isUnknown) { unknown += item.pointWeight }

        if (item.hasError && item.isUnknown) { errors += item.pointWeight }
        if (!item.isPartOfPlan && item.status === Status.UNKNOWN && !item.dayData.sealed) { overlapping += item.pointWeight }

        if (item.status === Status.SUCCESS || item.status === Status.PARTIAL) {
          completionTimes.push(item.completionTime);

          let maxBefore = Math.max(...dayData.items.map(item => item.completionTime).filter(completionTime => completionTime < item.completionTime));
          if (!item.startsSegment && isFinite(maxBefore)) {
            completionDurations.push(item.completionTime - maxBefore);
          }

          if (prevItem != null) {
            completionDurations.push(item.completionTime - prevItem.completionTime);
          }
        }
      }

      prevItem = item;
    }

    let currentStats = {date: dayData.date, successful, unknown, ignored, total: successful + unknown, overlapping, errors};

    if (isDayApplicableTest && isDayApplicableTest(dayData, currentStats)) {
      totalApplicableDays++;
    }
    
    dailyStats.push(currentStats)
  }

  let successful = dailyStats.reduce((a, {successful}) => a + successful, 0);
  let unknown = dailyStats.reduce((a, {unknown}) => a + unknown, 0);
  let total = dailyStats.reduce((a, {total}) => a + total, 0);
  let ignored = dailyStats.reduce((a, {ignored}) => a + ignored, 0);
  let numberOfDaysWihAnySuccessful = dailyStats.reduce((a, {successful}) => a + (successful > 0 ? 1 : 0), 0);
  let overlapping = dailyStats.reduce((a, {overlapping}) => a + overlapping, 0);
  let errors = dailyStats.reduce((a, {errors}) => a + errors, 0);
   
  let completionRatio = successful / (totalApplicableDays * (referenceDuration != null ? pointWeight(referenceDuration) : 1));
  let medianCompletionTime = median(completionTimes);
  let guessedDurationTime = median(completionDurations.filter(duration => duration > DURATION_SECOND * 10));

  return {successful, overlapping, errors, unknown, total, ignored, numberOfDaysWihAnySuccessful, dailyStats, completionRatio, totalApplicableDays, medianCompletionTime, guessedDurationTime};
}

export function calculateStats(daysData: DayData[], refDaysData: DayData[]) {
  const allLoadedDaysData = dayDataStore.allLoadedDaysData;
  console.log("allLoadedDaysData", allLoadedDaysData)
  const categories = extractCategories([...daysData, ...refDaysData]);
  let stats = [...categories].map(category => { return {
    category: category,
    current: extractStats(daysData, {itemTest: (item) => !category || item.category === category}),
    ref: extractStats(refDaysData, {itemTest: (item) => !category || item.category === category}),
    all: extractStats(allLoadedDaysData, {itemTest: (item) => !category || item.category === category}),
  }});

  let statsWithSort = stats.map(({category, current, ref, all}) => { return {
    category,
    current,
    ref,
    sort: all.total //current.successful/* - toPercent(ref.successful, ref.total)*/
  }})

  statsWithSort.sort(function (a, b) {
    if (a.sort > b.sort) return -1;
    if (a.sort < b.sort) return 1;
    return 0;
  });

  return statsWithSort;
}

class StatsStore {
  constructor() {
    makeAutoObservable(this);
  }

  get avgPerformance() {
    const dates = datesInRange(datePlusDays(mainStore.today, -14), datePlusDays(mainStore.today, -1));
    const daysData = dates.map(date => dayDataStore.requestDayData(date));
    const {successful, total, numberOfDaysWihAnySuccessful} = extractStats(daysData, {});
    const performance = {successful: successful / numberOfDaysWihAnySuccessful, total: total / numberOfDaysWihAnySuccessful};
    console.log("Recalculated avg performance", performance)
    return performance;
  }

  get todaysPerformance() {
    const nowMs =  mainStore.msElapsedToday;
    const dates = datesInRange(datePlusDays(mainStore.today, -14), datePlusDays(mainStore.today, -1));
    const daysData = dates.map(date => dayDataStore.requestDayData(date));
    const meaningfulDaysData = extractStats(daysData).dailyStats.filter(({successful}) => successful > 0).map(({date}) => dayDataStore.requestDayData(date));
    
    let performance = {
      referencePendingPerformance: 0,
      referencePerformanceSoFar: 0,
      currentDatePeformanceSoFar: 0,
      currentDateRemainingTasks: 0,
      currentDateTotalTasks: 0,
    }

    // referencePendingPerformance
    if (mainStore.currentDate.getTime() < mainStore.today.getTime()) {
      performance.referencePendingPerformance = 0;
    } else if (mainStore.currentDate.getTime() === mainStore.today.getTime()) {
      performance.referencePendingPerformance = median(extractStats(meaningfulDaysData, {itemTest: (item) => item.time > nowMs}).dailyStats.map(({successful}) => successful));
    } else {
      performance.referencePendingPerformance = median(extractStats(meaningfulDaysData, {itemTest: (item) => true}).dailyStats.map(({successful}) => successful));
    }

    // referencePerformanceSoFar
    if (mainStore.currentDate.getTime() < mainStore.today.getTime()) {
      performance.referencePerformanceSoFar = median(extractStats(meaningfulDaysData, {itemTest: (item) => true}).dailyStats.map(({successful}) => successful));
    } else if (mainStore.currentDate.getTime() === mainStore.today.getTime()) {
      performance.referencePerformanceSoFar = median(extractStats(meaningfulDaysData, {itemTest: (item) => item.time <= nowMs}).dailyStats.map(({successful}) => successful));
    } else {
      performance.referencePerformanceSoFar = 0;
    }

    {
      const {successful, unknown, total} = extractStats([dayDataStore.dayData], {});

      performance.currentDatePeformanceSoFar = successful;
      performance.currentDateRemainingTasks = unknown;
      performance.currentDateTotalTasks = total;
    }

    console.log("Recalculated performance", performance)
    return performance;
  }

  get allCategories() {
    console.log("Recalculated allCategories")
    const allLoadedDaysData = dayDataStore.allLoadedDaysData;
    return extractCategories(allLoadedDaysData);
  }

  get allSortedCategories() {
      let categories = [...this.allCategories];
      categories.sort((a, b) => {
        if (this.allCategoriesStats[a].total > this.allCategoriesStats[b].total) return -1;
        if (this.allCategoriesStats[a].total < this.allCategoriesStats[b].total) return 1;
        return 0;
      });

      return categories;
  }

  get allCategoriesStats() {
    const stats = {};
    for (const category of this.allCategories) {
      stats[category] = extractStats(dayDataStore.allLoadedDaysData, {itemTest: (item) =>  !category || item.category === category});
    }
    return stats;
  }
}

export const statsStore = new StatsStore();