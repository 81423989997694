import firebase from 'firebase/app';

export function datesInRange(startDate: Date, endDate: Date) {
  let dates = [];
  for (let d = startDate; d.getTime() <= endDate.getTime(); d = datePlusDays(d, 1)) {
    dates.push(d);
  }
  return dates;
}

export function filterInPlace(array: any[], condition: (element:any, i:number, a3:any[]) => boolean, thisArg = null) {
  let j = 0;

  array.forEach((e, i) => { 
    if (condition.call(thisArg, e, i, array)) {
      if (i!==j) array[j] = e; 
      j++;
    }
  });

  array.length = j;
  return array;
}

export function err(...args:any[]) {
  console.log("ERROR", ...args);
  let message = args.join(" ");
  alert(message);

  if (typeof Error !== "undefined") {
    throw new Error(message);
  }
  throw args.join(message); // Fallback
}

export function isNumeric(s:string) {
  return !isNaN((s as any) - parseFloat(s));
}

export function fromSimpleDate(s: string|null) {
  if (!s) {
    return null;
  }

  var b = s.split(/\D/);
  if (b.length !== 3 || !isNumeric(b[0])|| !isNumeric(b[1]) || !isNumeric(b[2]) || parseInt(b[1]) < 1 || parseInt(b[1]) > 12 || parseInt(b[2]) < 1 || parseInt(b[2]) > 31 ) {
    return null;
  }
  
  return new Date(parseInt(b[0]), parseInt(b[1])-1, parseInt(b[2]));
}

export function timeFromDate(date: Date) {
  return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
}

export function dateFromSimpleDateAndTime(dateStr: string, timeStr: string) {
  let itemDateWithTime = fromSimpleDate(dateStr);

  if (itemDateWithTime == null) {
    return null;
  }

  let [hoursString, minutesString] = timeStr.split(":");
  itemDateWithTime.setHours(parseInt(hoursString), parseInt(minutesString), 0, 0);
  return itemDateWithTime;
}

export function toExcelDate(date: Date) {
  if (date == null) {
    return "???"
  }
  return `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`
}

export function removeItemOnce<T>(arr: T[], value: T) { 
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export function removeItemAll<T>(arr: T[], value: T) {
  var i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

export function logEvent(eventName: string, ...args: any[]) {
  firebase.analytics().logEvent(eventName, ...args);
}

export function isEmpty(str: string) {
  return (!str || str.length === 0 || !str.trim());
};

export function daysDiff(first: Date, second: Date) {
    // Copy date parts of the timestamps, discarding the time parts.
    var one = new Date(first.getFullYear(), first.getMonth(), first.getDate());
    var two = new Date(second.getFullYear(), second.getMonth(), second.getDate());

    // Do the math.
    var millisecondsPerDay = 1000 * 60 * 60 * 24;
    var millisBetween = two.getTime() - one.getTime();
    var days = millisBetween / millisecondsPerDay;

    // Round down.
    return Math.round(days);
}

export function weeksDiff(date1: Date, date2: Date) {
  console.log("M", date1, getMonday(date1), getMonday(date2), daysDiff(getMonday(date1), getMonday(date2)));
  return daysDiff(getMonday(date1), getMonday(date2)) / 7;
}

export function assertUniqueId(list: {id: string}[], scopeName: string) {
  let seenIds = new Set();
  for (let item of list) {
    if (seenIds.has(item.id)) {
      alert(`Duplicate id '${item.id}' found in ${scopeName}`);
      throw Error(`Duplicate id '${item.id}' found in ${scopeName}`);
    } if (item.id === null) {
      err(`Null id found in ${scopeName}`);
    } else {
      seenIds.add(item.id);
    }
  }
}

export function datePlusDays(date: Date, days: number) {
  var d = new Date(date);
  d.setDate(d.getDate() + days);
  return d;
}

export function sameDateOnADifferentYear(date: Date, yearsDelta: number) {
  var d = new Date(date);
  d.setFullYear(d.getFullYear() + yearsDelta);
  return d;
}

export function firstDayOfMonth(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function lastDayOfMonth(date: Date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export function datesInAMonth(date: Date) {
  return datesInRange(firstDayOfMonth(date), lastDayOfMonth(date));
}

export function datePlusMonths(date: Date, months: number) {
  return new Date(date.getFullYear(), date.getMonth() + months, 1);
}

export function getMonday(d: Date) {
  d = new Date(d);
  var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

export function isValidDate(date: Date) {
  return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date.getTime());
}

function treatAsUTC(date: Date) {
  var result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
}

export function daysBetween(startDate: Date, endDate: Date) {
  var millisecondsPerDay = 24 * 60 * 60 * 1000;
  return (treatAsUTC(endDate).getTime() - treatAsUTC(startDate).getTime()) / millisecondsPerDay;
}

export function toPercent(val: number, maxValue: number) {
  return Math.round((maxValue === 0 ? 0 : val / maxValue) * 100)
}

export function toPercentStr(val: number, maxValue: number) {
  return `${toPercent(val, maxValue).toFixed(0)}%`
}

export function median(arr: number[]) {
  if (arr.length === 0) return undefined;
  const mid = Math.floor(arr.length / 2);
  const nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
}

export function ordinalSuffixOf(i: number) {
  var j = i % 10,
      k = i % 100;
  if (j === 1 && k !== 11) {
      return i + "st";
  }
  if (j === 2 && k !== 12) {
      return i + "nd";
  }
  if (j === 3 && k !== 13) {
      return i + "rd";
  }
  return i + "th";
}

export function today() {
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
}

export function randomSubarrayWithWeights<T>(array: (T&{weight: number})[], size: number) {
  let items = array.slice();
  let ret: (T&{weight: number})[] = [];

  for (let j = 0; j < size && items.length > 0; j++) {
    let sumWeights = items.reduce((a, item) => a + item.weight, 0)
    let random = Math.random() * sumWeights;
    
    
    let i = 0;
    while (i < items.length - 1) {
      random -= items[i].weight;
      if (random < 0)
        break;
      i++
    }

    ret.push(items[i]);
    items.splice(i, 1);
  }
  
  return ret;
}

export function capitalizeFirstLetter(str:string) {
  if (str.length == 0) {
    return ""
  } else {
    return str.charAt(0).toUpperCase() + str.substring(1);
  }
}

export function capitalizeAllFirstLetters(str:string) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
  }
  return splitStr.join(' '); 
}

/**
* Returns the index of the last element in the array where predicate is true, and -1
* otherwise.
* @param array The source array to search in
* @param predicate find calls predicate once for each element of the array, in descending
* order, until it finds one where predicate returns true. If such an element is found,
* findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
*/
export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
  let l = array.length;
  while (l--) {
      if (predicate(array[l], l, array))
          return l;
  }
  return -1;
}