import { makeAutoObservable } from "mobx"
import { isInThisDaySet } from "../../data/DayTagStore";
import { dayDataStore } from "../../data/DayDataStore";
import { habitStore } from "./HabitStore";
import { timeToMs } from "@habline/common/time";
import { finalParser } from "../../data/every";
import { timeBasedOnAnchor } from "./HabitDefList";
import { UpdatableInPlace } from "../../data/WithProtectedSaveDelay";
import { ItemPriority } from "../../data/Item";

export const DEFAULT_HABIT_CATEGORY = "📝";
export const DEFAULT_HABIT_PRIORITY = 50;

export class HabitDef implements UpdatableInPlace<HabitDef> {
  id: string;
  name: string;
  category:string;
  description: string;
  isActive: boolean;
  duration: number;
  canBeDoneLater: boolean;
  autoExpression: string;
  timeAnchor: number;
  positionRelativeToAnchor: number;
  priority: ItemPriority;

  get timeBasedOnAnchor() {
    return timeBasedOnAnchor(this, habitStore.timeItemAndAnchorStore)
  }

  constructor({id = null, name = "", category = DEFAULT_HABIT_CATEGORY, description = "", isActive = true, duration = 0, canBeDoneLater = false, autoExpression = "every day", timeAnchor = 0, positionRelativeToAnchor = 1, priority = DEFAULT_HABIT_PRIORITY} = {}) {
    this.id = id;
    this.name = name;
    this.category = category;
    this.description = description;
    this.isActive = isActive;
    this.duration = duration;
    this.canBeDoneLater = canBeDoneLater;
    this.autoExpression = autoExpression;
    this.timeAnchor = timeAnchor;
    this.positionRelativeToAnchor = positionRelativeToAnchor;
    this.priority = priority;

    makeAutoObservable(this);
  }

  get compiledAutoExpression() { return finalParser.parse(this.autoExpression.toLowerCase()); }

  get exists() {
    return this.id !== null;
  }

  toFirestore() {
    return {
      id: this.id,
      name: this.name,
      category: this.category,
      description: this.description,
      isActive: this.isActive,
      duration: this.duration,
      canBeDoneLater: this.canBeDoneLater,
      autoExpression: this.autoExpression,
      timeAnchor: this.timeAnchor,
      positionRelativeToAnchor: this.positionRelativeToAnchor,
      priority: this.priority,
    };
  }

  updateFromFirebase(habitDef: HabitDef|undefined) {
    console.assert(this.id === habitDef.id);

    this.name = habitDef.name;
    this.category = habitDef.category;
    this.description = habitDef.description;
    this.isActive = habitDef.isActive;
    this.duration = habitDef.duration;
    this.canBeDoneLater = habitDef.canBeDoneLater;
    this.autoExpression = habitDef.autoExpression;
    this.timeAnchor = habitDef.timeAnchor;
    this.positionRelativeToAnchor = habitDef.positionRelativeToAnchor;
    this.priority = habitDef.priority;
  }

  fromFirestore(data: any, version: number) {
    if (version < 1) {
      console.log("UPDATING HABITDEF FROM OLD VERSION")
      
      let expr = data.autoExpression;
      if (data.autoExpression && data.autoExpression.indexOf("every") !== 0) {
        if (data.autoExpression === "any") expr = "every day";
        if (data.autoExpression === "monday") expr = "every monday";
        if (data.autoExpression === "tuesday") expr = "every tuesday";
        if (data.autoExpression === "thursday") expr = "every thursday";
        if (data.autoExpression === "monday & 10c") expr = "every monday with #10c";
        if (data.autoExpression === "thursday & 10c") expr = "every thursday with #10c";
        if (data.autoExpression === "friday") expr = "every friday";
        if (data.autoExpression === "saturday") expr = "every saturday";
        if (data.autoExpression === "friday") expr = "every friday";
        if (data.autoExpression === "sunday") expr = "every sunday";

        if (data.autoExpression === "tuesday | friday") expr = "every tuesday and friday";
        if (data.autoExpression === "saturday | sunday") expr = "every saturday and sunday";
        if (data.autoExpression === "friday|saturday") expr = "every friday and saturday";
        if (data.autoExpression === "(!saturday)&(!sunday)") expr = "every weekday";

        if (data.autoExpression === "wednesday | saturday | sunday") expr = "every wednesday, saturday and sunday";
        if (data.autoExpression === "grooming") expr = "every #grooming";
        if (data.autoExpression === "monday | wednesday | friday") expr = "every monday, wednesday and friday";
        if (data.autoExpression === "10c") expr = "every #10c";
        if (data.autoExpression === "(monday | thursday) & 10c") expr = "every monday, thursday with #10c";
        if (data.autoExpression === "first:julek") expr = "every first of #julek";
        if (data.autoExpression === "last:julek") expr = "every last of #julek";

        if (data.autoExpression === "(monday|thursday) & 10c") expr = "every monday and thursday with #10c";

        if (data.autoExpression === "julek&(!first:julek)") expr = "every #julek except first of #julek";
        if (data.autoExpression === "julek&(!last:julek)") expr = "every #julek except last of #julek";
        //if (data.autoExpression === "(!even-week)&thursday") expr = "every other thursday";
        
      }

      if (expr === "*") {
        expr = "any"
      }

      let time = typeof data.time === 'string' ? timeToMs(data.time) : data.time; // legacy

      data.timeAnchor = time;
      data.positionRelativeToAnchor = 1;
      data.autoExpression = expr != null ? expr : "";
      data.duration = data.duration != null ? data.duration : 0; // legacy
      data.canBeDoneLater = data.canBeDoneLater != null ? data.canBeDoneLater : false; //legacy
    }
    
    this.id = data.id
    this.name = data.name
    this.category = !!data.category ? data.category : DEFAULT_HABIT_CATEGORY
    this.description = data.description
    this.isActive = data.isActive
    this.duration = data.duration
    this.canBeDoneLater = data.canBeDoneLater
    this.autoExpression = data.autoExpression
    this.timeAnchor = data.timeAnchor
    this.positionRelativeToAnchor = data.positionRelativeToAnchor
    this.priority = data.priority != null ? data.priority : DEFAULT_HABIT_PRIORITY;
  }

  isInThisSet(date: Date) {
    return this.isActive && isInThisDaySet(date, this);
  }

  requestCheckForExistance(): Promise<Date> {
    if (this.id) {
      return dayDataStore.collection.where('habitDefIds', 'array-contains', this.id).limit(1).get().then(docs => {
        if (docs.docs.length === 0) {
          return null;
        }

        let dayData = docs.docs[0].data();
        return dayData.date;
      })
    } else {
      return new Promise((resolve) => resolve(null));
    }
  }

  save(): void {
    habitStore.timeItemAndAnchorStore.save();
  }

  delete() {
    habitStore.timeItemAndAnchorStore.delete(this)
  }
}
