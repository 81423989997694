import { datePlusDays, toExcelDate, filterInPlace } from "@habline/common/utils"
import { action, makeAutoObservable, reaction } from "mobx"
import { mainStore } from "@habline/common/MainStore"
import { gapiAuth } from "../../data/gapi"
import { hackToSolveAccessError } from "../../data/ItemStore"
import { EventItem } from "./EventItem"
import { dayDataStore } from "../../data/DayDataStore"
import { Status } from "@habline/common/Status"
import { newId } from "@habline/common/firebase"
import { DayData } from "../../data/DayData"
const gapi = window.gapi;

export let i = hackToSolveAccessError;

function shouldBeConvertedIntoEvent(rawEvent: any) {
  if (rawEvent.summary.trim().toLowerCase() === "blocked") {
    return false;
  }

  if (!rawEvent.attendees) {
    return true;
  }

  for (let attendee of rawEvent.attendees) {
    if (attendee.email === "maciej.cielecki@10clouds.com" || attendee.email === "cielecki@cielecki.com") {
      if (attendee.responseStatus === "declined") {
        return false;
      }
    }
  }
  
  return true;
}

class EventStore {  

  constructor() {
    makeAutoObservable(this);
  }

  init() {
    //
    // Load current raw calendar events
    //
    reaction(
      () => [gapiAuth.workspace, mainStore.currentDate],
      () => {
        if (gapiAuth.workspace) {
          let loadDate = new Date(mainStore.currentDate);
          let dayData = dayDataStore.requestDayData(loadDate);

          console.log(`Loading raw calendar events for ${toExcelDate(loadDate)}`, gapiAuth.workspace);

          const timeMin = new Date(loadDate);
          const timeMax = datePlusDays(new Date(timeMin), 1);
        
          const calendarPromises = (gapiAuth.workspace.calendars ? gapiAuth.workspace.calendars : [])
            .map(calendar => gapi.client.calendar.events.list({
              'calendarId': calendar.id,
              'timeMin': timeMin.toISOString(),
              'timeMax': timeMax.toISOString(),
              'showDeleted': false,
              'singleEvents': true,
              'maxResults': 100,
              'orderBy': 'startTime'
            }));

          dayData.isLoadingRawEvents = true;
          Promise.all(calendarPromises).then(action((calendarResponses: any[]) => {
            let rawEvents = [];
            for (let calendarResponse of calendarResponses) {
              rawEvents.push(...calendarResponse.result.items);
            }

            console.log(`Loaded raw calendar events for ${toExcelDate(loadDate)} (${rawEvents.length})`, rawEvents);
            dayData.loadedRawEvents = rawEvents;
            dayData.isLoadingRawEvents = false;
          }));
        }
      }
    );
  }

  itemsFromRawEvents(dayData) {
    let items = [];
    for (let rawEvent of dayData.loadedRawEvents) {
      if (shouldBeConvertedIntoEvent(rawEvent)) {
        items.push(new EventItem({
          id: newId(),
          rawEvent: rawEvent,
          date: new Date(dayData.date),
          status: Status.UNKNOWN,
          lastUpdate: new Date(),
        }))
      }
    }
    return items;
  }

  injectIntoItems(dayData: DayData, calendarEventItems: any[]) {
    const originalLength = dayData.items.length;

    let forInsertion = [];
    for (let eventItem of calendarEventItems) {
      let currentItem = dayData.items.find(item => item instanceof EventItem && item.rawEvent && item.rawEvent.id === eventItem.rawEvent.id && !item.reused) as EventItem;
      if (currentItem) {
        //update data
        currentItem.rawEvent = eventItem.rawEvent;
      } else {
        forInsertion.push(eventItem);
      }
    }

    dayData.injectIntoItems(...forInsertion);

    //keep items that changed status or are in calendar
    filterInPlace(dayData.items, item =>
      item.type !== "event" ||
      item.status !== Status.UNKNOWN ||
      calendarEventItems.find(eventItem => eventItem.rawEvent.id === item.rawEvent.id) ||
      item.reused
    );

    console.log(`Injected ${calendarEventItems.length} events into ${dayData.id} (${originalLength}) -> (${dayData.items.length})`, );
  }
}
  
export const eventStore = new EventStore();