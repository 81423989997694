import React, { FunctionComponent } from 'react'
import { observer } from 'mobx-react-lite'
import { DayLevelScreen } from "../styles/components"
import styled from 'styled-components'
import { uiStore } from '../../data/UIStore'
import { statsStore } from '../../data/StatsStore'
import { searchEmoji } from "@habline/common/emojis"

export const CategoryEditor: FunctionComponent<{ value: string, valueSetter: (arg0: string) => void}> = observer(
  ({value, valueSetter}) => {
    const [text, setText] = React.useState("");

    let options = statsStore.allSortedCategories.slice();

    let onSave = (category: string|null) => {
      valueSetter(category);
      uiStore.pop();
    }

    let autoAddEmojis = ["🎯","🛏","🏠","📖", "💻", "👔","🏃‍♂️","💰","👔","🌴","💊","☯️", "👪", "👥"]
    let autoAddThreshold = autoAddEmojis.length + 3;

    autoAddEmojis.forEach(emoji => {
      if (options.length < autoAddThreshold && options.indexOf(emoji) === -1) options.push(emoji)
    })

    if (text.length > 1) {
      options = searchEmoji(text)
      console.log(searchEmoji(text));
    }
    
    return <DayLevelScreen title="Select a category" onBack={() => uiStore.pop()}>
      <Form action="my_redirect_url" method="post" onSubmit={e => {onSave(text); e.preventDefault()}}>
        <FormCategory>Search:</FormCategory>
        <FormInput placeholder="eg. grin, thumb, or a specific emoji" type="text" value={text} onChange={(event) => {console.log(event); setText(event.target.value);}} ref={input => input && input.focus()}/>  
        {text.length > 0 && <PostponedTaskButton type="button" onClick={() => setText("")}>x</PostponedTaskButton>}
      </Form>
      <CategoryButtonsContainer>
        {options.map(option => <CategoryButton key={option} onClick={() => {setText(option); onSave(option)}}>{option}</CategoryButton>)}
        {options.length % 5 !== 0 && <>
          {options.length % 5 <= 1 && <CategoryFiller />}
          {options.length % 5 <= 2 && <CategoryFiller />}
          {options.length % 5 <= 3 && <CategoryFiller />}
          {options.length % 5 <= 4 && <CategoryFiller />}
        </>}

        <CategoryButton onClick={() => {setText(""); onSave(null)}}>Clear</CategoryButton>
        
      </CategoryButtonsContainer>
    </DayLevelScreen>
  }
);


//TODO: Those styles are copied ...
const Form = styled.form`
  display: flex;
  flex-direction: row;
`;

const FormCategory = styled.div`
  flex-grow: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding-right: 10px;
`;

const FormInput = styled.input`
  flex-grow: 1;
  width: "100%";
  margin: 0;
`;

const PostponedTaskButton = styled.button`
  flex-grow: 0;
  padding: 0px 10px;
  margin: 0px 5px;
`;


export const CategoryButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CategoryButton = styled.button`
  margin-right: 5px;
  flex: 1 0 calc(20% - 5px);
  padding: 0;
  font-size: 1em;
  border: 0;
`;

export const CategoryFiller = styled.div`
  margin-right: 5px;
  flex: 1 0 calc(20% - 5px)
`;