import React from 'react';
import { capitalizeAllFirstLetters, logEvent } from '@habline/common/utils';
import styled from 'styled-components';
import { uiStore } from '../data/UIStore';
import { DayContextMenu } from './context/DayContextMenu';
import { dateSubtitle, describeDate, mainStore } from '@habline/common/MainStore';
import { observer } from 'mobx-react-lite';
import { extractStats } from '../data/StatsStore';
import { dayDataStore } from '../data/DayDataStore';
import { HeaderProgressBar } from './ProgressChangeBar';
import { gapiAuth } from '../data/gapi';
import { DEFAULT_TIME_POINT_DENSITY } from '@habline/common/time';
import { loadingStore } from '../data/loading';

const Title = styled.a`
  text-align: center;
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  color: ${({theme}) => theme.backgroundLevel4.toString()};

  &:active {
    color: ${({theme}) => theme.backgroundLevel4.toString()}; // <Thing> when hovered
  }

  &:hover {
    color: ${({theme}) => theme.backgroundLevel4.toString()}; // <Thing> when hovered
  }
`;

const NextPrevButton = styled.a`
  font-size: 3.0em;
  padding-left: 0.3em;
  padding-right: 0.3em;
  padding-top: 0.0em;
  margin-top: 0;
  margin-bottom: -3px;
  line-height: 1.4;
  text-align: center;
  color: ${({theme}) => theme.backgroundLevel4.toString()};

  &:active {
    color: ${({theme}) => theme.backgroundLevel4.toString()}; // <Thing> when hovered
  }

  &:hover {
    color: ${({theme}) => theme.backgroundLevel4.toString()}; // <Thing> when hovered
  }
`;

const SubTitle = styled.h3`
  text-align: center;
  font-size: 0.9em;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  color: ${({theme}) => theme.backgroundLevel3.toString()};
  letter-spacing: 0.05em;
  margin: 0px;
`;

const CenterSection = styled.div`
  flex-grow: 1;
`;

const Spacer = styled.div`
  height: 65px;
`;

const Container = styled.div`
  display: flex;
`;

const Header = styled.div`
  background-color: ${({theme}) => theme.backgroundLevel1.toString()};
  top: 0;
  width: 100%;
  position:fixed;
  box-shadow:0px 2px 2px rgba(0, 0, 0, 0.2);
  z-index: 2000;
`;

export const DateHeader = observer(() => {
  let date = mainStore.displayDate;
  let title = capitalizeAllFirstLetters(describeDate(date, {mode: mainStore.dateMode}))
  let {successful, unknown, overlapping, errors} = gapiAuth.user ? extractStats([dayDataStore.requestDayData(date)], {}) : {successful:0, unknown:0, overlapping:0, errors: 0};

  //Pretty convoluted correction of overlapping data, this might no longer be true as data evolves
  unknown -= overlapping;
  overlapping -= errors;

  let dayData = dayDataStore.dayData;
  
  let totalStillAvailableTime = dayData != null ? dayData.dayEndTime - dayData.dayStartTime : 0; 
  let totalUsed = dayData != null ? dayData.totalDuration : 0;

  let totalTime = dayData != null ? dayData.dayEndTime - dayData.dayStartTime : 0;
  let pointDensity = successful + unknown > 0 ? totalTime / (successful + unknown) : DEFAULT_TIME_POINT_DENSITY;
  let free = Math.max((totalStillAvailableTime - totalUsed)/pointDensity, 0);

  return <>
    <Spacer></Spacer>
    <Header>
      <Container>
        <NextPrevButton className="dateSwitch" onClick={(e) => {logEvent('prev_date'); mainStore.changeDateByDelta(-1)}}>❮</NextPrevButton>
        <CenterSection onClick={() => { logEvent('reset_date'); uiStore.showContextMenu(<DayContextMenu date={date} />); }}>
          <Title>{title}</Title>
          <SubTitle>{dateSubtitle(date, {mode: mainStore.dateMode})}</SubTitle>
        </CenterSection>
        <NextPrevButton className="dateSwitch" onClick={(e) => {logEvent('prev_date'); mainStore.changeDateByDelta(+1)}}>❯</NextPrevButton>
      </Container>
      <HeaderProgressBar successful={successful} unknown={unknown} free={free} overlapping={overlapping} isLoading={loadingStore.isLoading} errors={errors}></HeaderProgressBar>
    </Header>
  </>
});
