import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/analytics';

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyC6wgpOocRZMm9nTmkDzPQTdygsMODtuNw",
  authDomain: "arcane-splicer-315706.firebaseapp.com",
  projectId: "arcane-splicer-315706",
  storageBucket: "arcane-splicer-315706.appspot.com",
  messagingSenderId: "106845891855",
  appId: "1:106845891855:web:ee214bf1d5d4b589fb227a",
  measurementId: "G-MJY5CT3DFH",
  databaseURL: "https://arcane-splicer-315706.firebaseio.com",
};
  
// Initialize Firebase
export const fb = firebase.initializeApp(FIREBASE_CONFIG);

export function newId() {
  return fb.firestore().collection("workspaces").doc().id;
}