import React from 'react'
import ReactDOM from 'react-dom'
import 'css.gg'
import '@atlaskit/css-reset'
import App from './components/app'
import { eventStore } from './googlecalendar/data/EventStore'
import { dayDataStore } from './data/DayDataStore'
import { itemStore } from './data/ItemStore'
import { habitStore } from './habits/data/HabitStore'
import { dayTagStore } from './data/DayTagStore'
import reportWebVitals from './reportWebVitals';
import packageJson from '../package.json';
import { notifyAboutItemStatus } from './components/notifyAboutItemStatus'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { globalPrefsStore } from './data/GlobalPrefsStore'

console.log("VERSION", packageJson.version);

Sentry.init({
  dsn: "https://0362596131594525b0230e53c64a42c9@o919586.ingest.sentry.io/5863825",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

//TODO: </React.StrictMode>
ReactDOM.render(<App />, document.getElementById('root'));

habitStore.init();
eventStore.init();
dayTagStore.init(); 
itemStore.init();
dayDataStore.init();
globalPrefsStore.init();

itemStore.notifyAboutItemStatus = notifyAboutItemStatus;

console.log("INIT COMPLETED");

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();