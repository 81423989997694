import { CopyPasteOp, Item, ItemConstructorParameters } from "./Item";
import { timeToMs } from "@habline/common/time";
import { makeObservable, computed, action } from "mobx";
import { Status } from "@habline/common/Status";

export const DEFAULT_TASK_PRIORITY = 50;

export class TaskItem extends Item {
  constructor ({...args}: ItemConstructorParameters) {
    super(args)

    makeObservable(this, {
      canBeDeleted: computed,
      canBePostponed: computed,
      canOverrideName: computed,
      canOverrideCategory: computed,
      canOverrideDescription: computed,
      canOverrideDuration: computed,
      performItemTypeUpdateFromFirebase: action,
      toFirestore: action,
    });
  }

  get type() { return "task" }
  get canBeDeleted() { return true }
  get canBePostponed() { return [Status.MISSED].includes(this.status) }
  get canOverrideName() { return true }
  get canOverrideCategory() { return true }
  get canOverrideDescription() { return true }
  get canOverrideDuration() { return true }
  get autoInsertionTime() { return timeToMs("00:00"); } //the gapiAuth.workspace.tasksInjectionTime can be removed from data and code if this proves to work
  get autoPriority() { return DEFAULT_TASK_PRIORITY; }

  instanceForCopy(id: string, date: Date) {
    return new TaskItem({id, date});
  }

  performItemTypeSpecificCopyOps(newItem: Item, type:CopyPasteOp) {

  }
  
  toFirestore() {
    return {
      ...super.toFirestore()
    }
  }

  fromFirestore(data: any, version: number) {
    super.fromFirestore(data, version);
  }

  performItemTypeUpdateFromFirebase(item: Item): void {
    
  }
}