import { makeAutoObservable } from "mobx"

class GlobalPrefsStore {
  darkMode = false;

  setDarkMode(darkMode) {
    this.darkMode = darkMode;
    window.localStorage.setItem('theme', darkMode ? 'dark' : 'light')
  }

  init() {
    const browserDarkModeSet = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const localTheme = window.localStorage.getItem('theme');

    if (browserDarkModeSet || localTheme === 'dark') {
      this.setDarkMode(true)
    }
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export const globalPrefsStore = new GlobalPrefsStore();