import React, { useState } from 'react'

import { observer } from "mobx-react-lite";

import { DragDropContext, Droppable, Draggable, DraggableProvided, DraggableStateSnapshot, BeforeCapture, DropResult, DragUpdate, ResponderProvided } from 'react-beautiful-dnd';
import { action } from 'mobx';
import { Item } from '../data/Item';
import { DayData } from '../data/DayData';
import { ItemEnder } from "../data/ItemEnder";

import { DailyItemView, EnderView} from './DailyItemView';
import { SegmentStartView } from './SegmentStartView';
import { itemStore } from '../data/ItemStore';

export const DailyItemList = observer(({dayData}: {dayData: DayData}) => {
  const [subDraggingIds, setSubDraggingIds] = useState(new Set<string>());
  const [noMoveDrag, setNoMoveDrag] = useState(false);
  const [clickBlockId, setClickBlockId] = useState(null); //Workaround around a bug where drag is initiated and release without moving the finger on iOS (causes firing both drag end and onclick)

  let onBeforeCapture = action((before:BeforeCapture) => {
    setNoMoveDrag(true);

    let itemSeparatorOrEnder = dayData.itemsAndEndersSequence.find((itemSeparatorOrEnder => itemSeparatorOrEnder.id === before.draggableId));
    let subDraggingIds = new Set<string>();

    if (itemSeparatorOrEnder instanceof Item) {
      if (itemStore.isInSelection(itemSeparatorOrEnder)) {
        for (let item of itemStore.selectedItems) {
          if (item.id !== itemSeparatorOrEnder.id) {
            subDraggingIds.add(item.id)
          }
          for (let enclosedItem of item.enclosedItems) {
            subDraggingIds.add(enclosedItem.id)
          }
        }
      } else {
        for (let enclosedItem of itemSeparatorOrEnder.enclosedItems) {
          subDraggingIds.add(enclosedItem.id)
        }
      }
    }

    setSubDraggingIds(subDraggingIds);
  });

  let onDragUpdate = action((initial: DragUpdate, provided: ResponderProvided) => {
    setNoMoveDrag(false);
  })

  let onDragEnd = action((result: DropResult) => {
    setNoMoveDrag(false);

    clearTimeout(clickBlockId);
    setClickBlockId(setTimeout(() => {
      setClickBlockId(null);
    }, 50));
    

    const { source, destination } = result;

    // dropped outside the list
    if (!destination || source.droppableId !== "droppable" || destination.droppableId !== "droppable") {
        return;
    }

    //select or deselect if dropped in the same spot
    if (noMoveDrag && source.index === destination.index) {
      let item = renderedItems[source.index].itemSeparatorOrEnder;

      if (item instanceof Item) {
        if (itemStore.isInSelection(item)) {
          itemStore.removeFromSelection(item);
        } else {
          itemStore.addToSelection(item);
        }

        setSubDraggingIds(new Set<string>([]));
        return;
      }
    }

    let itemSeparatorOrEnder = renderedItems[source.index].itemSeparatorOrEnder;
    let addingAfter = source.index <= destination.index;

    let dayDataItemSequenceDestinationPosition = source.index;
    let lastPositionOutsideEnclosing = source.index;

    let startIndex = addingAfter ? 0 : dayData.itemsAndEndersSequence.length - 1;
    let indexAfterLast = addingAfter ? dayData.itemsAndEndersSequence.length : -1;
    let increment = addingAfter ? 1 : -1;

    let sourceActualIndex = dayData.itemsAndEndersSequence.findIndex(item => item.id === renderedItems[source.index].itemSeparatorOrEnder.id);
    let destinationActualIndex = dayData.itemsAndEndersSequence.findIndex(item => item.id === renderedItems[destination.index].itemSeparatorOrEnder.id);;

    let inItem = null;

    for (let i = startIndex; i !== indexAfterLast; i += increment) {
      let potentialBlocker = dayData.itemsAndEndersSequence[i];

      if (potentialBlocker instanceof Item && potentialBlocker.startsSegment) {
        inItem = addingAfter ? potentialBlocker.id : null;
      }

      if (potentialBlocker instanceof ItemEnder) {
        inItem = addingAfter ? null : potentialBlocker.itemId;
      }

      if (addingAfter ? i > sourceActualIndex : i < sourceActualIndex) {
        if (potentialBlocker.isAnchored && itemSeparatorOrEnder.isAnchored) {
          break;
        }

        dayDataItemSequenceDestinationPosition = potentialBlocker.position + increment / 2;

        if (inItem === null) {
          lastPositionOutsideEnclosing = dayDataItemSequenceDestinationPosition;
        }
      }

      if (addingAfter ? i >= destinationActualIndex : i <= destinationActualIndex) {
        break;
      }
    }

    if (itemSeparatorOrEnder instanceof Item && (itemSeparatorOrEnder.isAnchored || itemSeparatorOrEnder.startsSegment)) {
      dayDataItemSequenceDestinationPosition = lastPositionOutsideEnclosing
    }

    for (let draggableId of subDraggingIds) {
      let itemSeparatorOrEnder = dayData.itemsAndEndersSequence.find(itemSeparatorOrEnder => itemSeparatorOrEnder.id === draggableId)
      if (itemSeparatorOrEnder != null) {
        itemSeparatorOrEnder.position = dayDataItemSequenceDestinationPosition + 0.4 / subDraggingIds.size;
      }
    }

    itemSeparatorOrEnder.position = dayDataItemSequenceDestinationPosition;

    console.log("NEW POS", dayDataItemSequenceDestinationPosition, itemSeparatorOrEnder, source, destination)

    setSubDraggingIds(new Set<string>([]));

    dayData.save();
    dayData.unblockUpdates();
  });

  let renderedItems: {rendered: JSX.Element, itemSeparatorOrEnder: (Item|ItemEnder)}[] = [];
  let inItem = null;

  for (let itemOrEnder of dayData.itemsAndEndersSequence) {
    if (itemOrEnder instanceof Item) {
      let item = itemOrEnder;
      renderedItems.push({itemSeparatorOrEnder: itemOrEnder, rendered: <Draggable
        key={item.id}
        draggableId={item.id}
        index={renderedItems.length}>
        {((inItem) => (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => 
          <DailyItemView clickBlock={!!clickBlockId} item={item} inItem={inItem} provided={provided} snapshot={snapshot} subDraggingIdsCount={snapshot.isDragging && !noMoveDrag ? subDraggingIds.size : 0} isBeingSubDragged={subDraggingIds.has(itemOrEnder.id) && !noMoveDrag} />
        )(inItem)}
      </Draggable>});

      if (item.startsSegment) {
        inItem = item;
      }
    } else if (itemOrEnder instanceof ItemEnder) {
      console.assert(inItem != null, "inItem != null")
      
      let ender = itemOrEnder as ItemEnder;
      let endingItem = inItem;

      renderedItems.push({itemSeparatorOrEnder: itemOrEnder, rendered: <Draggable
        key={ender.id}
        draggableId={ender.id}
        isDragDisabled={true}
        index={renderedItems.length}>
        {(provided, snapshot) => <EnderView ender={ender} inItem={endingItem} provided={provided} snapshot={snapshot} isBeingSubDragged={subDraggingIds.has(itemOrEnder.id) && !noMoveDrag} />}
      </Draggable>});

      inItem = null;
    }
  }

  return <>
    <SegmentStartView
      isVisible={renderedItems.length > 0 && dayData.segments[0].items.length > 0}
      segmentStartTime={dayData.dayStartTime}
      setSegmentStartTime={(time) => {dayData.manualDayStartTime = time; dayData.save()}}
      segment={dayData.segments[0]}
      provided={{
        innerRef: null,
        draggableProps: {"data-rbd-draggable-context-id": "", "data-rbd-draggable-id": ""},
        dragHandleProps: {'data-rbd-drag-handle-draggable-id': '', 'data-rbd-drag-handle-context-id': '', 'aria-describedby': '', 'role': null, 'tabIndex': null, 'draggable': null, 'onDragStart': null},
      }}
      snapshot={
        {isDragging: false, isDropAnimating: false}
      }
    />

    <DragDropContext onBeforeCapture={onBeforeCapture} onDragUpdate={onDragUpdate} onDragEnd={onDragEnd} onDragStart={() => dayData.blockUpdates()}>
      <Droppable droppableId="droppable">
          {(provided, snapshot) =>
            <div ref={provided.innerRef}>
              {renderedItems.map(({rendered}) => rendered)}
              {provided.placeholder}
            </div>
          }
      </Droppable>
    </DragDropContext>
    

    <SegmentStartView
      isVisible={renderedItems.length > 0}
      segmentStartTime={dayData.dayEndTime}
      setSegmentStartTime={(time) => {dayData.manualDayEndTime = time; dayData.save()}}
      segment={null}
      provided={{
        innerRef: null,
        draggableProps: {"data-rbd-draggable-context-id": "", "data-rbd-draggable-id": ""},
        dragHandleProps: {'data-rbd-drag-handle-draggable-id': '', 'data-rbd-drag-handle-context-id': '', 'aria-describedby': '', 'role': null, 'tabIndex': null, 'draggable': null, 'onDragStart': null},
      }}
      snapshot={
        {isDragging: false, isDropAnimating: false}
      }
    />
  </>
});