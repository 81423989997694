import React, { MouseEventHandler } from 'react'
import { useTheme } from 'styled-components'

import { observer } from "mobx-react-lite";
import { DURATION_HOUR, DURATION_MINUTE, humanReadableDuration, msToTime, pointWeight, timeToMs } from '@habline/common/time';

import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { Item } from '../data/Item';
import { uiStore } from '../data/UIStore';
import { Description, DescriptionBigLine, DescriptionSmallLine, IconContainer, ItemContainer, ItemInfoContainer } from './DailyItemView';
import { TextEditor } from './preferences/TextEditor';
import { action } from 'mobx';
import { DAY_END, ItemSegment } from '../data/ItemSegment';
import { ItemEnder } from '../data/ItemEnder';

export const SegmentStartView = observer(({segment, segmentStartTime, setSegmentStartTime, isBeingSubDragged, provided, snapshot, onClick, isVisible}: {
  segment?: ItemSegment,
  segmentStartTime: number,
  isVisible: boolean,
  setSegmentStartTime: (time: number) => void,
  inItem?: Item,
  isBeingSubDragged?: boolean,
  provided: DraggableProvided,
  snapshot: DraggableStateSnapshot,
  onClick?: (event?: MouseEventHandler) => void}) => 
{
  const theme = useTheme();

  let onClickChangeTime = () => uiStore.push(
    <TextEditor
      label="Wake up time"
      help="Time you wake up."
      placeholder="6:30"
      value={msToTime(segmentStartTime)}
      valueSetter={(value) => setSegmentStartTime(timeToMs(value))}
    />
  );

  let openAnchorContextMenu = action((event) => {
    onClick && onClick(event);
    event.preventDefault();
    event.stopPropagation();
  })

  let availableTime = segment != null ? segment.availableTime : 0;

  let roundTime = (time: number) => humanReadableDuration(Math.round(time / 1000 / 60) * 60 * 1000)

  let name = "ERROR";

  if (segment == null) {
    name = "Bedtime"
  } else {
    if (segment.start instanceof Item) {
      name = `${segment.start.category} ${segment.start.name}`
    } else {
      if (segment.end instanceof Item) {
        name = `Before „${segment.end.name}”`; 
      } else {
        if (segment.end.id === DAY_END) {
          name = `Before bedtime`
        }
      }
    }

    if (segment.start instanceof ItemEnder && segment.end instanceof Item && segment.duration < DURATION_HOUR) {
      name = "Break"
    }
  }


  let inclinedToFillFreeSpace = availableTime > DURATION_MINUTE * 5 && (Math.abs(availableTime) / segment.duration >= 0.15) && !(segment.start instanceof Item) 
  let inclinedToReduceOverflow = segment != null && availableTime < 0 && Math.abs(availableTime) >= DURATION_MINUTE * 5 && (Math.abs(availableTime) / segment.duration >= 0.15)
  let inclinedToDoSomethingAboutNotPlanned = segment != null && segment.notInPlanTime > DURATION_MINUTE * 5 && (Math.abs(segment.notInPlanTime) / segment.duration >= 0.15) && !(segment.start instanceof Item) 
  
  let isMarginal = segment != null && !segment.start.hasError && segment.duration <= DURATION_MINUTE * 10 && segment.items.length <= 0;

  return <ItemContainer
      isPartOfPlan={true}
      isNew={false}
      isMarginal={!isVisible || isMarginal}
      isSelected={false}
      isHighlighted={false}
      priority={99}
      pointWeight={pointWeight(segment != null ? Math.max(segment.availableTime, 0) : 0)}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      isDragging={snapshot.isDragging}
      isDoable={true}
      hasError={segment != null && segment.start.hasError}
      isBeingSubDragged={isBeingSubDragged}
    >
      <ItemInfoContainer>
        <IconContainer important={true} onClick={onClickChangeTime}>
          {msToTime(segmentStartTime)}
          <DescriptionSmallLine>{segment != null ? roundTime(segment.duration) : <>&nbsp;</>}</DescriptionSmallLine>
        </IconContainer>
        <Description onClick={segment != null ? openAnchorContextMenu : null}>
          <DescriptionBigLine important={true} lineThrough={false}>{name}</DescriptionBigLine>
          {segment != null && <DescriptionSmallLine>
            { inclinedToReduceOverflow && <span style={{color: theme.red.toString(), paddingRight: "0.5em"}}>{humanReadableDuration(-segment.availableTime)} overflow</span> }
            { inclinedToFillFreeSpace && <span style={{color: theme.lightGreen.toString(), paddingRight: "0.51em"}}>{roundTime(availableTime)} free</span> }
            { inclinedToDoSomethingAboutNotPlanned && <span style={{color: theme.textLevel1.toString(), paddingRight: "0.5em"}}>skipping {roundTime(segment.notInPlanTime)}</span> }
          </DescriptionSmallLine> }
        </Description>
      </ItemInfoContainer>
  </ItemContainer>
})
