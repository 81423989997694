import React, { FunctionComponent } from 'react'
import { observer } from 'mobx-react-lite'
import { DayLevelScreen, Divider, FixedFooter, Help } from "../styles/components"
import styled, { useTheme } from 'styled-components'
import { uiStore } from '../../data/UIStore'
import { isEmpty, logEvent } from '@habline/common/utils'
import { dayTagStore,  } from "../../data/DayTagStore"
import { DayTag, DayTagCommon } from "../../data/DayTag"
import { TextEditButton, Errors } from "./SettingsView"
import { CalendarView } from '../CalendarView'
import { Center } from '@habline/common/primitives'
import { DateExpressionEditor } from './DateExpressionEditor'
import { action } from 'mobx'
import { mainStore } from '@habline/common/MainStore'

export const DayTagEditor: FunctionComponent<{tag:DayTagCommon}> = observer(
  ({tag}) => {
    const [currentDate, setCurrentDate] = React.useState(mainStore.today);
    const theme = useTheme();

    return <DayLevelScreen title={"#" + tag.name} onBack={() => uiStore.pop()} >
      { tag instanceof DayTag && <div>
        <br/>
        <TextEditButton
          title="Description"
          placeholder="eg. Every other week I take care of my aunt, hence those are my aunt days"
          value={tag.description}
          valueSetter={(value) => {
            tag.description = value;
            tag.save();
          }}
          area={true} >
            {!isEmpty(tag.description) ? tag.description : "Description ..."}
        </TextEditButton>
        <Divider />
        <DateExpressionEditor autoExpression={tag.autoExpression} onExpressionChanged={action((value: string) => {tag.autoExpression = value; tag.save()})} />
        <Divider />
      </div>}
      
      <CalendarView
        currentDate={currentDate}
        onDateChanged={(date: Date) => setCurrentDate(date)}
        infoForDate={(date: Date) => {
          let text = date.getDate().toString();
      
          if (tag.isInThisSet(date)) {
            return {text, backgroundColor: theme.backgroundLevel2.toString()};
          } else {
            return {text, backgroundColor: theme.backgroundLevel1.toString()};
          }
        }}
        onDateClicked={tag instanceof DayTag && action((date: Date) => tag.manuallyToggleDate(date))}
      />

      {tag instanceof DayTag && <SaveButton onClick={() => {logEvent('day_set_deleted'); tag.delete(); uiStore.pop();}}>Delete</SaveButton>}
      {tag instanceof DayTag && tag.error && <FixedFooter><Errors>{tag.error}</Errors></FixedFooter>}
    </DayLevelScreen>
  }
)

export const DayTagsView = observer((props) => {
  return <DayLevelScreen
    title="Tags"
    onBack={() => uiStore.pop()}
    onPlus={() => {
      let name = prompt("Name of the tag");
      let dayTag = new DayTag({id: name});
      if (dayTag.error) {
        uiStore.toast(dayTag.error)
      } else {
        dayTag.save();
      }
    }}>
    <br/>
    {dayTagStore.customDayTags.length === 0 ? <Center><Help>No custom day tags</Help></Center>: dayTagStore.customDayTags.map(tag => <DayTagListItem key={tag.id} readonly={false} onClick={() => uiStore.push(<DayTagEditor tag={tag} />)}>#{tag.name}</DayTagListItem>)}
    <Divider/>
    {dayTagStore.autoDayTags.map(tag => <DayTagListItem key={tag.id} readonly={true} onClick={() => uiStore.push(<DayTagEditor tag={tag} />)}>#{tag.name}</DayTagListItem>)}
  </DayLevelScreen>
});

export const SaveButton = styled.button`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
  clear: both;
`;

export const DayTagListItem = styled.div<{readonly?: boolean}>`
  text-align: center;
  height: 30px;
  padding: 0.5em;
  font-style: ${props => props.readonly ? "italic": "normal"};
  display:block;
`;