import { AnyDaySet, DayOfWeekSet, DaySet, DateExpression, MonthReference, Segment, SegmentType, TagReference, WeekDaySet } from './recur';

import * as P from 'parsimmon'
/*
var TOKENTYPES = {
  eof: /^$/,
rank: /^((\d+)(st|nd|rd|th)?)\b/,
  time: /^((([0]?[1-9]|1[0-2]):[0-5]\d(\s)?(am|pm))|(([0]?\d|1\d|2[0-3]):[0-5]\d))\b/,
  dayName: /^((sun|mon|tue(s)?|wed(nes)?|thu(r(s)?)?|fri|sat(ur)?)(day)?)\b/,
  monthName: /^(jan(uary)?|feb(ruary)?|ma((r(ch)?)?|y)|apr(il)?|ju(ly|ne)|aug(ust)?|oct(ober)?|(sept|nov|dec)(ember)?)\b/,
  yearIndex: /^(\d\d\d\d)\b/,
  every: /^every\b/,
  after: /^after\b/,
  before: /^before\b/,
  second: /^(s|sec(ond)?(s)?)\b/,
  minute: /^(m|min(ute)?(s)?)\b/,
  hour: /^(h|hour(s)?)\b/,
  day: /^(day(s)?( of the month)?)\b/,
  dayInstance: /^day instance\b/,
  dayOfWeek: /^day(s)? of the week\b/,
  dayOfYear: /^day(s)? of the year\b/,
  weekOfYear: /^week(s)?( of the year)?\b/,
  weekOfMonth: /^week(s)? of the month\b/,
  weekday: /^weekday\b/,
  weekend: /^weekend\b/,
  month: /^month(s)?\b/,
  year: /^year(s)?\b/,
  between: /^between (the)?\b/,
  start: /^(start(ing)? (at|on( the)?)?)\b/,
  at: /^(at|@)\b/,
  and: /^(,|and\b)/,
  except: /^(except\b)/,
  also: /(also)\b/,
  first: /^(first)\b/,
  last: /^last\b/,
  "in": /^in\b/,
  of: /^of\b/,
  onthe: /^on the\b/,
  on: /^on\b/,
  through: /(-|^(to|through)\b)/
};*/


/*
Composite ::= Schedule ('also' Schedule)* (('except' Schedule) ('also' Schedule)*)?

Schedule ::= (
('on the' ('first' | 'last' | Number_Range) Period) |
('every' ('weekend' | 'weekday' | ((Number Period)) (('starting on the' Number Period) | ('between the' Number 'and' Number))?)) |
('on' Day (Day_Range)?) |
('of' Month (Month_Range)?) |
('in' Year (Year_Range)?)

)+

let designation = string("in").pipe(
  then(int().pipe(
    between(whitespace())
  )),
)

Range ::= (('-' | 'through') Value) | ((',' | 'and') Value)+


const separatorParser: P.Parser<string[]> = P.oneOf(",-/ .").many();
*/

const numberParser: P.Parser<number> = P.regexp(/(0|[1-9][0-9]*)/)
  .map(Number)
  .desc("number")

const monthNames: {[id:string]: number} = {
  january: 1,
  february: 2,
  march: 3,
  april: 4,
  may: 5,
  june: 6,
  july: 7,
  august: 8,
  september: 9,
  october: 10,
  november: 11,
  december: 12
};

const numberMonth: P.Parser<number> = numberParser.chain(n => {
  if (n >= 1 && n <= 12) {
    return P.succeed(n)
  } else {
    return P.fail("Month must be between 1 and 12")
  }
})

const namedMonth: P.Parser<number> = P.letters.chain(s => {
  const n = monthNames[s.toLowerCase()];
  if (n) {
    return P.succeed(n);
  } else {
    return P.fail(`${s} is not a valid month`);
  }
});

const monthParser: P.Parser<number> = P.alt(numberMonth, namedMonth).trim(P.optWhitespace)

// Accept suffixes like 1st, 2nd, etc.
// Note: For our own convenience we'll accept invalid names like 1nd  or 4st
// (If you were implementing something like a programming language,
// you'd want to be more strict)
const numberSuffixParser: P.Parser<string> = P.alt(
  P.string("st"),
  P.string("nd"),
  P.string("rd"),
  P.string("th")
);

// Parse a day of the month (1–31)

function word(word: string) {
  return P.string(word).trim(P.optWhitespace)
}

function oneOrMore<T>(parser: P.Parser<T>, separator: P.Parser<string> = separators): P.Parser<T[]> {
  let rest:P.Parser<T[]> = P.seq(separator, parser).map(([_, o]) => o).many()
  return P.seq(parser, rest).map(([o, os]) => [o, ...os]);
}

// Prints [1, 2, 3]:

let namesOfDaysShort=["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
let namesOfDaysLong=["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

const longWeekDayParser: P.Parser<DayOfWeekSet> = P.alt(...namesOfDaysLong.map(s => P.string(s))).map(s => new DayOfWeekSet(namesOfDaysLong.indexOf(s)))
const shortWeekDayParser: P.Parser<DayOfWeekSet> = P.alt(...namesOfDaysShort.map(s => P.string(s))).map(s => new DayOfWeekSet(namesOfDaysShort.indexOf(s)))
const weekDayParser: P.Parser<DayOfWeekSet> = P.alt(longWeekDayParser, shortWeekDayParser).trim(P.optWhitespace)

export const tagNameParser = P.regexp(/[\da-z]+/)

const tagParser: P.Parser<TagReference> = P.seq(P.string("#"), tagNameParser).map(([_, tagId]) => {return new TagReference(tagId, true)}).trim(P.optWhitespace)

const nthParser: P.Parser<number> = P.alt(
  numberParser.map(s => Number(s)).skip(numberSuffixParser),
  word("first").map(s => 1),
  word("last").map(s => -1)
).trim(P.optWhitespace)

const nthOfParser: P.Parser<MonthReference> = P.alt(
  P.seq(nthParser, word("of"), monthParser).map(([nth, _of, monthNum]) => new MonthReference(nth, null, monthNum)),
  P.seq(nthParser, weekDayParser, word("of"), monthParser).map(([nth, weekDaySet, _of, monthNum]) => new MonthReference(nth, weekDaySet.dayOfWeek, monthNum)),
  P.seq(nthParser, weekDayParser).map(([nth, weekDaySet]) => new MonthReference(nth, weekDaySet.dayOfWeek, null)),
  nthParser.map(nth => new MonthReference(nth, null, null)),
).trim(P.optWhitespace);

const nthOfTag: P.Parser<TagReference> = P.alt(
  P.seq(nthParser, word("of"), P.string("#"), tagNameParser).map(([nth, _of, _hash, tagId]) => {return new TagReference(tagId, true, nth)}),
).trim(P.optWhitespace)

const wdParser: P.Parser<DaySet> = P.alt(nthOfTag, nthOfParser, weekDayParser, tagParser, word("day").map(_ => new AnyDaySet()), word("weekday").map(_ => new WeekDaySet()))

const wdParserSafeInOther: P.Parser<DaySet> = weekDayParser

const separators = P.alt(P.string(","), P.string("and"))

const proportionParser = P.alt(
  P.seq(numberParser.map(s => Number(s) / 100).skip(P.string("%")).skip(P.string("of").trim(P.optWhitespace))),
  P.of(null).map(_ => 1),
)

const occurenceParser: P.Parser<Segment> = P.seq(
  proportionParser,
  P.string("every").trim(P.optWhitespace),
  P.alt(
    P.seq(P.string("other").trim(P.optWhitespace), oneOrMore(wdParserSafeInOther)),
    P.seq(P.of(null), oneOrMore(wdParser)),
  ),
  P.alt(
    P.seq(P.string("with").trim(P.optWhitespace), oneOrMore(tagParser)).map(([_, withResult]) => withResult),
    P.of(null),
  ),
).map(([rand, _, [other, components], withResult]) => {
  return new Segment(
    rand,
    other != null ? SegmentType.EVERY_OTHER : SegmentType.EVERY,
    components,
    withResult,
  );
})

const exceptParser: P.Parser<Segment> = P.seq(
  proportionParser,
  P.alt(P.string("except"), P.string("without")).skip(P.optWhitespace).skip(P.alt(P.string("every"), P.string("")).skip(P.optWhitespace)),
  P.alt(
    P.seq(P.string("other").trim(P.optWhitespace), oneOrMore(wdParserSafeInOther)),
    P.seq(P.of(null).trim(P.optWhitespace), oneOrMore(wdParser)),
  ),
  P.alt(
    P.seq(P.string("with").trim(P.optWhitespace), oneOrMore(wdParserSafeInOther)).map(([_, withResult]) => withResult),
    P.of(null),
  ),
).map(([rand, _, [other, components], withResult]) => {
  return new Segment(
    rand,
    other != null ? SegmentType.EXCEPT_OTHER : SegmentType.EXCEPT,
    components,
    withResult,
  )
})

const occOrExcept: P.Parser<Segment> = P.alt(occurenceParser, exceptParser).trim(P.optWhitespace)
const sequenceSep: P.Parser<string> = P.alt(separators, P.string(";"), P.string("")).trim(P.optWhitespace)
export const finalParser: P.Parser<DateExpression> = oneOrMore(occOrExcept, sequenceSep).map(seq => new DateExpression(seq))

//TODO: 1st of may, every 1st, every fi

/*

function test(expression: string) {
  expression = expression.toLowerCase();
  let numDays = 28;

  let dates: Date[] = [];
  for (let i = 0; i < numDays; i++) {
    dates.push(datePlusDays(today(), i));
  }

  console.log(
    expression,
    finalParser.tryParse(expression),
    ...dates.map(date => finalParser.tryParse(expression).isInThisSet(date) ? "*" : ".")
  );
}

//console.log(numberMonth.parse("12")); // {status: true, value: 123}

test("every Tue, Monday and Friday");
test("every other Tue")
test("every #julek")
test("every day except #julek")
test("every Tue except every other Tuesday")
test("every Tue and #julek except every other tuesday")
test("every first of #julek")
test("every last of #julek")
test("every 3rd of #julek")
test("every other Tuesday")
test("every day except sunday")
test("every 1st, 10th")
test("every first")
test("every last wednesday")
test("every 3rd sunday")
test("every 3rd of february")
test("every last wednesday of january")
test("every monday and thursday with #10c")
test("every monday and every other tuesday, friday, saturday and sunday")

*/