import React from 'react';
import { toExcelDate, toPercentStr } from '@habline/common/utils';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { ProgressChangeBar } from './ProgressChangeBar';
import { HistoricDailyItemList } from './HistoricDailyItemList';
import { extractStats, calculateStats } from '../data/StatsStore';
import { DateMode, mainStore } from '@habline/common/MainStore';
import { DayData } from '../data/DayData';
import { Item } from '../data/Item';
import { action } from 'mobx';

const DAY_SHORT_NAMES = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]

enum ColorMode {
  higlight = 'highlight',
  normal = 'normal',
  remaining = 'remaining',
}


export function extractItems(daysData, itemTest) {
  let items = [];
  for (const dayData of daysData) {
    for (const item of dayData.items) {
      if (itemTest(item)) {
        items.push(item);
      }
    }
  }
  return items;
}

export const DayBars = observer<{onClick?: ()=>void, daysData: DayData[]}>(({onClick, daysData}) => {
  const maxValue = Math.max(...daysData.map(dayData => {
    const stats = extractStats([dayData], {});
    return stats.successful + stats.unknown;
  }));
  console.log("MAX", maxValue)

  const dayBars = [];
  for (let dayData of daysData) {
    const {successful, unknown} = extractStats([dayData], {});
    
    dayBars.push(<DayInAWeekContainer key={toExcelDate(dayData.date)} style={{height: "150px"}} onClick={action(() => {mainStore.setCurrentDate(dayData.date); mainStore.dateMode = DateMode.DAY})}>
      {daysData.length <= 7 ? <BarValue>{DAY_SHORT_NAMES[dayData.date.getDay()]}</BarValue> : "" }
      <ScoreBar mode={ColorMode.normal} value={successful} maxValue={maxValue}></ScoreBar>
      <ScoreBar mode={ColorMode.remaining} value={unknown} maxValue={maxValue}></ScoreBar>
      {daysData.length <= 7 && dayData.totalDuration > 0 && <BarValue>{`${toPercentStr(dayData.totalDuration, dayData.dayEndTime - dayData.dayStartTime)}`}</BarValue>}
      {daysData.length <= 7 && successful > 0 && <BarValue>{successful}</BarValue>}
    </DayInAWeekContainer>)
  }

  return <DayBarsContainer onClick={onClick}>
    {dayBars}
  </DayBarsContainer>
});

export const CategoryStats = observer<{thisPeriodDaysData: DayData[], prevPeriodDaysData:DayData[]}>(({thisPeriodDaysData, prevPeriodDaysData}) => {
  const [selectedCategory, selectCategory] = React.useState(null);

  const categoryStats = calculateStats(thisPeriodDaysData, prevPeriodDaysData)

  const categoryStatsMaxValue = Math.max(...categoryStats.map(({current}) => {
    return current.total;
  }));

  return <CategoryStatsContainer>
    {
      categoryStats.filter(({current}) => current.total > 0).map(({category, current, ref}) => {
        //const refSuccessRatio = ref.successful / ref.total;
        return <React.Fragment key={category}>
          <CategoryStatsDOM onClick={() => selectCategory(category === selectedCategory ? null : category)}>
            <Category>{category}</Category>
            <ProgressChangeBar desc={current.successful.toString()} value={current.successful} refValue={current.unknown} total={categoryStatsMaxValue}>
            </ProgressChangeBar>
          </CategoryStatsDOM>
          {category === selectedCategory ? <div>
            <HistoricDailyItemList isMultiDay={thisPeriodDaysData.length > 1} items={extractItems(thisPeriodDaysData, (item: Item) =>  item.category === category)} />
          </div> : ""}
        </React.Fragment>
      })
    }
  </CategoryStatsContainer>;
});

export function toDiff(val, refVal) {
  const diff = isNaN(val) || isNaN(refVal) ? 0 : val - refVal;
  const icon = diff > 0 ? "▲" : "▼";

  return diff !== 0 ? <Diff diff={diff}>{icon} {Math.abs(diff * 100).toFixed(0)}%</Diff> : "";
}

/*
const Score = styled.div`
  font-size: 400%;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
`;
*/

const Diff = styled.span<{diff: number}>`
  display: ${props => props.diff === 0 ? "none" : "inline"};
  opacity: 0.7;
  font-size: 80%;
`;

const Category = styled.div<{}>`
  width: 2.5rem;
  text-align: left;
`;

const CategoryStatsContainer = styled.div<{}>`
  
`;

const CategoryStatsDOM = styled.div<{}>`
  padding-left: 0em;
  padding-right: 0em;
  height: 30px;
  line-height: 30px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row; 
`;

const ScoreBar = styled.div<{mode: ColorMode, value: number, maxValue: number}>`
  background-color: ${({mode, theme}) => {
    switch (mode) {
      case 'highlight': return theme.backgroundLevel4.toString();
      case 'normal': return theme.backgroundLevel3.toString();
      case 'remaining': return theme.backgroundLevel2.toString();
      default: return theme.backgroundLevel3.toString();
    }
  }};
  height: ${({value, maxValue}) => (value / maxValue * 100).toFixed(3)}px;
  width: 80%;
`;

const BarValue = styled.span<{highlight?: boolean}>`
  font-weight: ${({highlight}) => highlight ? "bolder" : "normal"};
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  color: ${({highlight, theme}) => highlight ? theme.textLevel0 : theme.textLevel1};
`;

const DayInAWeekContainer = styled.div<{}>`
  width: 14%;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
`;

const DayBarsContainer = styled.div<{}>`
  display:flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 2.5rem;
`;

export const Content = styled.div<{}>`
  text-align:center;
  color: ${({theme}) => theme.textLevel1};
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;