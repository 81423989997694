import { makeAutoObservable, action } from "mobx"
import { datePlusDays, datePlusMonths, daysDiff, getMonday, toExcelDate, weeksDiff, ordinalSuffixOf, today } from "@habline/common/utils"

export enum DateMode {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}

const DATE_SWITCH_DELAY = 500;

const months: {[idx:number]:string} = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December'
}

export function describeDate(date:Date, {mode = DateMode.DAY, short = false} = {}) {
  switch (mode) {
    case DateMode.DAY:
      var weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      let diff = daysDiff(mainStore.today, date);

      if (diff === 0) return "today";
      if (diff === 1) return "tomorrow";
      if (diff === -1) return "yesterday";
      if (diff === -2) return "two days ago";
      if (diff === -3) return "three days ago";
      if (diff === -4) return "four days ago";
      if (diff === -5) return "five days ago";
      if (diff === -6) return "six days ago";
      if (diff === -7) return `last ${weekday[date.getDay()]}`;
      //if (diff < 0 && diff >= -7) return `last ${weekday[date.getDay()]}`;
      if (diff > 0 && diff <= 7) return weekday[date.getDay()];
      if (diff > 0 && diff <= 14) return `following ${weekday[date.getDay()]}`;

      return `${months[date.getMonth()]} ${ordinalSuffixOf(date.getDate())}`;

    case DateMode.WEEK:
      const firstDay = getMonday(date);
      const lastDay = datePlusDays(firstDay, 6);

      switch (weeksDiff(mainStore.today, date)) {
        case 0: return "this Week"; 
        case 1: return "next Week"; 
        case -1: return "last Week"; 
        default: 
          return `week ${ordinalSuffixOf(firstDay.getDate())} - ${ordinalSuffixOf(lastDay.getDate())}`;
      }
    case DateMode.MONTH:
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      const monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      return (short ? monthNamesShort : monthNames)[date.getMonth()];
    default:
      throw Error(`Unknown mode ${mode}`)
  }
}

export function dateSubtitle(date:Date, {mode = DateMode.DAY} = {}) {
  switch (mode) {
    case DateMode.DAY: return date.toDateString();
    case DateMode.WEEK:
      const firstDay = getMonday(date);
      const lastDay = datePlusDays(firstDay, 6);
      if (firstDay.getMonth() === lastDay.getMonth()) {
        return `${describeDate(firstDay, {mode: DateMode.MONTH})} ${date.getFullYear()}`;
      } else {
        return `${describeDate(firstDay, {mode: DateMode.MONTH, short: true})}  ${date.getFullYear()} / ${describeDate(lastDay, {mode: DateMode.MONTH, short: true})} ${date.getFullYear()}`;
      }
      
      
    case DateMode.MONTH: return date.getFullYear();
    default: throw Error(`Unknown mode ${mode}`)
  }
}


class MainStore {
  displayDate: Date;
  currentDate: Date;
  
  dateMode = DateMode.DAY;
  today = today();
  now = new Date();

  timeOutId: number|null = null;

  showingCompletedItems = false;

  constructor() {
    this.displayDate = this.today;
    this.currentDate = this.displayDate;

    makeAutoObservable(this);
  }
  
  get msElapsedToday() {
    return this.now.getTime() - this.today.getTime();
  }

  updateClock() {
    this.now = new Date();
    console.log("Clock update", this.now);
    
    let newToday = today();
    if (newToday.getTime() !== this.today.getTime()) this.today = newToday; //Not sure if that is needed, just to be sure we don't trigger anything
  }

  changeDateByDelta(delta: -1|1) {
    switch (this.dateMode) {
      case DateMode.DAY: this.setCurrentDate(datePlusDays(mainStore.displayDate, delta)); break;
      case DateMode.WEEK: this.setCurrentDate(datePlusDays(mainStore.displayDate, delta * 7)); break;
      case DateMode.MONTH: this.setCurrentDate(datePlusMonths(mainStore.displayDate, delta)); break;
      default: throw Error("Unknown date mode");
    }
  }

  setCurrentDate(date: Date) {
    let newDate = new Date(date);
    this.displayDate = newDate
    this.showingCompletedItems = false;

    console.log("Changing date to", toExcelDate(newDate))

    if (this.timeOutId != null) clearTimeout(this.timeOutId);
    this.timeOutId = setTimeout(action(() => {
      this.setCurrentDateImmediatelly(newDate);
    }), DATE_SWITCH_DELAY);
  }

  setCurrentDateImmediatelly(date: Date) {
    let newDate = new Date(date);

    console.log("Changed date to", toExcelDate(newDate))
    this.displayDate = newDate
    this.showingCompletedItems = false;
    this.currentDate = newDate;
    this.updateClock();
    this.timeOutId = null;
  }
}

export const mainStore = new MainStore();

function scheduleClockUpdate() {
  let timeToWaitToExcactMinute = 60000 - (new Date().getTime() % 60000);

  setTimeout(action(() => {
    mainStore.updateClock();
    scheduleClockUpdate();
  }), timeToWaitToExcactMinute);
}

scheduleClockUpdate();