import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { dayDataStore } from '../data/DayDataStore';
import { Content, DayBars, CategoryStats } from '../components/DailyStats';
import { datePlusMonths, datesInRange, firstDayOfMonth, lastDayOfMonth, ordinalSuffixOf } from '@habline/common/utils';
import { Notes } from './Notes';
import { medianInfo } from '../data/StatsStore';

export const MonthView = observer(() => {
  const [stats, setStats] = useState({medianSuccessful: 0});
  const [thisPeriodDaysData, setThisPeriodDaysData] = useState([]);
  const [prevPeriodDaysData, setPrevPeriodDaysData] = useState([]);

  useEffect(() => {
    const dayData = dayDataStore.dayData;

    const currentDate = dayData.date;

    const firstDay = firstDayOfMonth(currentDate);
    const lastDay = lastDayOfMonth(currentDate);
    
    const firstDayOfNext = datePlusMonths(firstDay, +1);
    const lastDayOfNext = lastDayOfMonth(datePlusMonths(firstDay, +1));
    
    const thisPeriodDaysData = datesInRange(firstDay, lastDay).map(date => dayDataStore.requestDayData(date));
    const prevPeriodDaysData = datesInRange(firstDayOfNext, lastDayOfNext).map(date => dayDataStore.requestDayData(date));

    setThisPeriodDaysData(thisPeriodDaysData);
    setPrevPeriodDaysData(prevPeriodDaysData);
    setStats(medianInfo(thisPeriodDaysData));
  }, [])

  return <>
    <Content>
      <p>Average score: {stats.medianSuccessful}</p>
      <DayBars daysData={thisPeriodDaysData} />
      <CategoryStats thisPeriodDaysData={thisPeriodDaysData} prevPeriodDaysData={prevPeriodDaysData}/>
    </Content>
    
    <Notes daysData={thisPeriodDaysData} dateToHeader={date => ordinalSuffixOf(date.getDate())} />
  </>
});