import React, { FunctionComponent } from 'react';
import { gapiAuth } from "../../data/gapi";
import { observer } from 'mobx-react-lite';
import { DayLevelScreen, Divider } from "../styles/components";
import styled from 'styled-components';
import { uiStore } from '../../data/UIStore';
import { DayTagsView } from "./DayTagEditor";
import { HabitsDefEditor } from '../../habits/components/HabitsDefEditor';
import { logEvent } from "@habline/common/utils";
import { Help } from '../styles/components';
import { HelpPage } from './HelpPage';
import { TextEditor, TextEditorProps } from './TextEditor';
import { Center } from '@habline/common/primitives';
import packageJson from '../../../package.json';
import { CalendarsEditor } from "../../googlecalendar/components/CalendarsEditor";
import { GeneralSettingsEditor } from './GeneralSettingsEditor';

export const SettingsView = observer((props) => {

  const signout = () => {
    gapiAuth.signout();
    uiStore.pop()
  }
  
  return <DayLevelScreen title="Settings" onClose={() => uiStore.pop()}>
    <Divider/>
    <ProfileButton onClick={()=>{gapiAuth.user ? signout() : gapiAuth.signin()}}>{gapiAuth.user ? <span><ProfileImage src={gapiAuth.user.photoURL} /> {gapiAuth.user.displayName} (Sign Out)</span> : (gapiAuth.isLoading ? "Loading ..." : "Not logged in")}</ProfileButton>
    <Divider/>
    {gapiAuth.user ? <Button onClick={() => {logEvent('open_calendars_editor'); uiStore.push(<GeneralSettingsEditor />)}}>General</Button> : null }
    {gapiAuth.user ? <Button onClick={() => {logEvent('open_calendars_editor'); uiStore.push(<CalendarsEditor />)}}>Calendars</Button> : null }
    {gapiAuth.user ? <Button onClick={() => {logEvent('open_habits_editor'); uiStore.push(<HabitsDefEditor />)}}>Habits</Button> : null }
    {gapiAuth.user ? <Button onClick={() => {logEvent('open_day_sets_editor'); uiStore.push(<DayTagsView />)}}>Tags</Button> : null }
    {gapiAuth.user ? <Button onClick={() => {logEvent('open_help_page'); uiStore.push(<HelpPage />)}}>Help</Button> : null }
    <Divider/>
    {gapiAuth.user ? <Button onClick={() => {logEvent('reset_everything'); if (window.confirm("Are you sure? This is not reversible")) { gapiAuth.resetEverything(); uiStore.pop()}}}>Delete account</Button> : null }
    <Divider />
    <Help><Center>Build: {packageJson.version}</Center></Help>
  </DayLevelScreen>
});

export const ProfileButton = styled.div`
  text-align: center;
  padding: 0.5em;
  display:block;
`;


export const ProfileImage = styled.img`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  vertical-align:middle;
`;

export const Errors = styled.div`
  text-align: center;
  color: #ffffff;
  height: 30px;
  padding: 0.5em;
  display:block;
  font-weight: bolder;
`;

export const Label = styled.label`
  text-align: left;
  margin: 0em;
  padding: 0em;
  padding-bottom: 0.5em;
  display:block;
`;

export const CompactLabel = styled.label`
  text-align: left;
  padding: 0.0em;
  margin: 0em;
  display:block;
`;

export const Button = styled.div`
  text-align: center;
  height: 30px;
  padding: 0.5em;
  display:block;
`;

interface TextEditButtonProps extends TextEditorProps {
  title?: string,
}

export const TextEditButton = observer<TextEditButtonProps>(
  ({label, title, help, placeholder, value, error, children, valueSetter, area = false}) => {
    return <PropertySection
      label={label}
      onClick={() => uiStore.push(<TextEditor label={title ? title : label}
        area={area}
        help={help}
        placeholder={placeholder}
        value={value}
        error={error}
        valueSetter={valueSetter}/>)
      }
      hasValue={!!value}
    >
      {children}
    </PropertySection>
  }
);


export const PropertySection = observer<{label: string, onClick: () => void, hasValue: boolean, help?: string}>(
  ({label, onClick, hasValue, children, help}) => {
    return <CheckBoxContainer
    hasValue={hasValue}
      onClick={onClick}>
      <CheckBoxInnerContainer>
        { label ? <CheckBoxLabel>{label}</CheckBoxLabel> : ""}
        <TextEditButtonDOM expansive={!label}>{children}</TextEditButtonDOM>
      </CheckBoxInnerContainer>
      {help ? <Help>{help}</Help> : ""}
    </CheckBoxContainer>
  }
);

export const TextEditButtonDOM = styled.div<{expansive?: boolean}>`
  text-align: left;
  display:block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: ${expansive => expansive ? "wrap" : "nowrap"};
`;

export const CheckBox: FunctionComponent<{label: string, style?: object, help: string, value: boolean, valueSetter: (boolean) => void}> = ({label, style = {}, help, value, valueSetter}) => {
  return <CheckBoxContainer style={style} hasValue={true}>
    <CheckBoxInnerContainer>
      <CheckBoxLabel>{label}</CheckBoxLabel>
      <CheckBoxInput type="checkbox" checked={value} onChange={(event) => { valueSetter(event.target.checked) }} />
    </CheckBoxInnerContainer>
    {help ? <Help>{help}</Help> : ""}
  </CheckBoxContainer>
};

export const EditorProperty: FunctionComponent<{label: string, style?: object, help: string, onClick: () => void}> = ({children, label, style = {}, help, onClick}) => {
  return <CheckBoxContainer style={style} hasValue={true} onClick={onClick}>
    <CheckBoxInnerContainer>
      <CheckBoxLabel>{label}</CheckBoxLabel>
      {children}
    </CheckBoxInnerContainer>
    {help ? <Help>{help}</Help> : ""}
  </CheckBoxContainer>
};

export const CheckBoxContainer = styled.div<{hasValue: boolean}>`
  opacity: ${({hasValue}) => hasValue ? 1.0 : 0.25};
  margin-bottom: 1.5rem;
`;

export const CheckBoxLabel = styled.label`
  text-align: left;
  flex-grow: 1;
  padding: 0.0em;
  padding-right: 0.5em;
  margin: 0em;
  white-space: nowrap;
`;

export const CheckBoxInnerContainer = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
`;

export const CheckBoxInput = styled.input`
  margin: 0;
  text-align: left;
`;

export const CalendarContainer = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: ${(32 + 4)*7}px;
  display: block;
`;

export const DayInCalendar = styled.div<{firstDayOfWeek: boolean, active: boolean}>`
  text-align: center;
  margin: 2px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-family: monospace;
  display: block;
  float: left;
  color: white;
  font-weight: bold;
  background-color: ${({theme, active}) => active ? theme.backgroundLevel4.toString() : theme.backgroundLevel1.toString() };
  ${props => props.firstDayOfWeek ? "clear:both;" : ""}
`;

export const DayInCalendarFiller = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 32px;
  height: 32px;
  margin: 2px;
  display: block;
  float: left;
`;

export const Clear = styled.div`
  clear:both;
  margin-bottom: 1em;
`;