import React from 'react';
import { datePlusDays, ordinalSuffixOf, randomSubarrayWithWeights } from '@habline/common/utils';
import { observer } from 'mobx-react-lite';
import { describeDate, mainStore } from '@habline/common/MainStore';
import { dayDataStore } from '../data/DayDataStore';
import { extractStats, statsStore } from '../data/StatsStore';
import { withUnit } from './preferences/HelpPage';
import seedrandom from "@habline/common/seedrandom"
import { DayData } from '../data/DayData';
import { Status } from '@habline/common/Status';
import { HabitItem } from '../habits/data/HabitItem';
import { makeAutoObservable, reaction } from 'mobx';
import { loadingStore } from '../data/loading';
import styled from 'styled-components';
import { habitStore } from '../habits/data/HabitStore';
import { useSwipeable } from 'react-swipeable';
import { gapiAuth } from '../data/gapi';
import { openContextMenu } from './context/ItemActionsContextMenu';

export const VIABILITY_LOW = 0.6;
export const VIABILITY_HIGH = 0.8;

const ANIMATION_WAIT_DURATION_SECONDS = 10;
const ANIMATION_TRANSITION_DURATION_SECONDS = 0.1;

const MAX_COACHING_HINTS_IN_SLIDER = 5
const MAX_COACHING_HINTS_IN_SUMMARY = 3

function goBackInTime(startDate: Date, fun: (pastDayData: DayData) => boolean) {
  let currentDate = datePlusDays(startDate, -1);
  while (true) {
    let pastDayData = dayDataStore.getDayData(currentDate)
    if (!pastDayData) { break; }

    if (!fun(pastDayData)) {
      break;
    }

    currentDate = datePlusDays(currentDate, -1);
  }
}

function generateCoachingCandidates(dayData: DayData) {
  let candidates: {hint: JSX.Element, weight: number, onClick: () => void}[] = [];

  function add(weight: number, hint: JSX.Element, onClick: () => void) {
    if (weight > 0) {
      candidates.push({hint, weight, onClick});
    }
  }

  const perf = statsStore.todaysPerformance;
  const expectedExecutionLevel = perf.referencePendingPerformance / perf.currentDateRemainingTasks;

  {
    // Stats around outperforming your average score
    let delta = perf.currentDatePeformanceSoFar - perf.referencePerformanceSoFar;
    if (delta > 0) {
      if (mainStore.currentDate.getTime() === mainStore.today.getTime()) {
        add(delta*2, <>You are outperforming yourself by {withUnit(delta)}</>, () => {});
      }
  
      if (mainStore.currentDate.getTime() < mainStore.today.getTime()) {
        add(delta * 2, <>You have outperformed yourself by {withUnit(delta)}</>, () => {});
      }
    }
  }

  {
    // Stats around beating your high score
    let numDaysBeaten = 0;
    let allTimeHigh = true;
    const dayDataPerformance = extractStats([dayData], {});

    goBackInTime(dayData.date, (pastDayData: DayData) => {
      const pastDataPerformance = extractStats([pastDayData], {});

      if (pastDataPerformance.successful < dayDataPerformance.successful) {
        numDaysBeaten += 1;
        return true;
      } else {
        allTimeHigh = false;
        return false;
      }
    });

    if (numDaysBeaten > 2) {
      if (allTimeHigh) {
        add(100.0, <>You have set a new record of <b>{dayDataPerformance.successful}</b> tasks done.</>, () => {});
      } else {
        add(numDaysBeaten * 5, <>You have beaten a score for your last <b>{numDaysBeaten}</b> days.</>, () => {});  
      }
    }
  }

  {
    // Stats around maintaining your peformance
    let numDaysKeptInRange = 1;
    let ALLOWED_DEVIATION = 0.25;
    let MIN_SCORE_TO_ALLOW = 5;

    const currentPerformance = Math.min(perf.currentDatePeformanceSoFar + perf.referencePendingPerformance, perf.currentDateTotalTasks); //We are guessing the total but it should be no larger by total number of planned
    const showHintThreshold = currentPerformance * 0.75

    if (perf.currentDatePeformanceSoFar >= Math.max(MIN_SCORE_TO_ALLOW, showHintThreshold)) {
      goBackInTime(dayData.date, (pastDayData: DayData) => {
        const pastDataPerformance = extractStats([pastDayData], {});

        if (Math.abs(pastDataPerformance.successful - currentPerformance) < currentPerformance * ALLOWED_DEVIATION) {
          numDaysKeptInRange += 1;
          return true;
        } 
      })

      let weight = 0;
      if (numDaysKeptInRange > 1) weight = 10;
      if (numDaysKeptInRange > 2) weight = 20;
      if (numDaysKeptInRange > 3) weight = numDaysKeptInRange * 10;

      add(weight, <>You {perf.currentDateRemainingTasks === 0 ? "have maintained" : "are maintaining"} a steady score for the last <b>{numDaysKeptInRange}</b> days.</>, () => {});
    }
  }

  // Hints on realism of your plan
  if (mainStore.currentDate.getTime() >= mainStore.today.getTime()) {
    
    if (expectedExecutionLevel < VIABILITY_LOW && expectedExecutionLevel > 0) {
      add(10.0, <>Stats show that you will do around <b>{(expectedExecutionLevel * 100).toFixed(0)}%</b><br/> of your remaining {withUnit(perf.currentDateRemainingTasks)}.</>, () => {});
    }

    if (expectedExecutionLevel > VIABILITY_HIGH) {
      add(5.0, <>You plan for {withUnit(perf.currentDateRemainingTasks)}. <br/>Stats show that you may still squeeze something in.</>, () => {});
    }
  }

  if (mainStore.currentDate.getTime() > mainStore.today.getTime() && expectedExecutionLevel <= VIABILITY_HIGH && expectedExecutionLevel >= VIABILITY_LOW) {
    add(5.0, <>You plan for {withUnit(perf.currentDateRemainingTasks)}. <br/>This is an optimal plan! 👌</>, () => {});
  }

  //Score tracking
  if (perf.currentDatePeformanceSoFar > 0 && dayData.isToday) {
    add(1.0, <>Your current score is {perf.currentDatePeformanceSoFar.toString()}</>, () => {});
  }
  
  // Things to say if nothing else is to say
  if (perf.currentDateRemainingTasks === 0 && perf.currentDatePeformanceSoFar > 10) {
    add(0.1, <>There is a lot of things you did right 👌.</>, () => {});
  }

  if (perf.currentDateTotalTasks === 0) {
    //Hints when there are no items
    if (mainStore.currentDate.getTime() >= mainStore.today.getTime()) {
      add(0.1, <>Nothing planned for {describeDate(mainStore.currentDate)} yet.</>, () => {});
    } else {
      add(0.1, <>Nothing was planned for {describeDate(mainStore.currentDate)}.</>, () => {});
    }
  }

  //Item hints
  for (let item of dayData.items) {
    if (item.status === Status.SUCCESS && item.pointWeight > 1) {
      let weight = item.pointWeight - 1.5;
      add(weight, <><em>{item.name}</em> was a big thing 👍</>, () => openContextMenu([item], true));
    }

    //Opportunities to break streaks
    if (mainStore.currentDate.getTime() <= datePlusDays(mainStore.today, 1).getTime() && item instanceof HabitItem && item.defId && item.isUnknown) {
      let habitItem: HabitItem = item;
      let timesMissed = 0;

      goBackInTime(dayData.date, (pastDayData: DayData) => {
        let anyDone = pastDayData.items.some((pastItem) => pastItem instanceof HabitItem && pastItem.defId === habitItem.defId && (pastItem.status === Status.PARTIAL || pastItem.status === Status.SUCCESS));
        if (anyDone) {
          return false;
        }

        let anyMissed = pastDayData.items.some((pastItem) => pastItem instanceof HabitItem && pastItem.defId === habitItem.defId && pastItem.isMissed);
        if (anyMissed) {
          timesMissed++;
        }

        return true;
      });

      if (timesMissed > 1) {
        let weight = timesMissed * 0.1;
        add(weight, <><em>Opportunity to break a streak of {timesMissed} misses of<br/>{item.category} {item.name}</em></>, () => openContextMenu([item], true));
      }
    }

    if (item instanceof HabitItem && item.defId && item.status === Status.SUCCESS) {
      let habitItem: HabitItem = item;
      
      //Streaks of success
      let streak = 1;
      
      goBackInTime(dayData.date, (pastDayData: DayData) => {
        for (let pastItem of pastDayData.items) {
          if (pastItem instanceof HabitItem && pastItem.defId === habitItem.defId) {
            if (pastItem.status === Status.SUCCESS || pastItem.status === Status.PARTIAL) {
              streak++;
              return true;
            } else {
              return false;
            }
          }
        }
        return true;
      });

      if (streak >= 3) {
        if ([3, 5].indexOf(streak) !== -1) {
          add(1.5 * item.pointWeight, <><em>{item.name}</em><br/> for the {ordinalSuffixOf(streak)} time in a row 💪.</>, () => openContextMenu([item], true));
        }

        if ([10, 15, 20, 30, 40, 50, 60, 75, 100, 150, 200, 250, 300, 400, 500, 600, 700, 800, 900, 1000, 1500, 2000].indexOf(streak) !== -1) {
          add(3.0 * item.pointWeight, <><em>{item.name}</em><br/> for the {ordinalSuffixOf(streak)} time in a row 💪.</>, () => openContextMenu([item], true));
        }
      }

      //Broken streaks of failure
      let daysWithoutSuccess = 0;
      let foundAtLeastOneFailure = false;
      goBackInTime(dayData.date, (pastDayData: DayData) => {
        for (let pastItem of pastDayData.items) {
          if (pastItem instanceof HabitItem && pastItem.defId === habitItem.defId) {
            if (pastItem.status === Status.SUCCESS || pastItem.status === Status.PARTIAL) {
              return false;
            } else {
              foundAtLeastOneFailure = true;
              break;
            }
          }
        }

        daysWithoutSuccess++;
        return true;
      });

      if (daysWithoutSuccess > 3 && foundAtLeastOneFailure) {
        let weight = 2.0;
        add(weight, <>Did <em>{item.name}</em><br/> after {daysWithoutSuccess + 1} day break 🎉.</>, () => openContextMenu([item], true));
      }
    }
  }

  return candidates;
}


class CoachingStore {
  coachingHints = [];
  currentHintIndex = null;
  coachingHintChangeTimeout = null;
  currentAnimation = null;
  currentAnimationLength = 0;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => !loadingStore.isLoading && gapiAuth.user && dayDataStore.dayData.itemsAndEndersSequence,
      (ready) => {
        if (ready) {
          let coachingHints = generateCoachingCandidates(dayDataStore.dayData);
          console.debug("Coaching hints", coachingHints.length, dayDataStore.dayData, mainStore.currentDate, habitStore.habitDefs.length);
          this.coachingHints = randomSubarrayWithWeights(coachingHints, MAX_COACHING_HINTS_IN_SLIDER);
          this.currentHintIndex = 0;
          this.currentAnimation = "slide-in-left";
          this.currentAnimationLength = ANIMATION_TRANSITION_DURATION_SECONDS;
          clearTimeout(this.coachingHintChangeTimeout);
          this.coachingHintChangeTimeout = null;
        }
      }
    )
  }

  get coachingHint() { return this.coachingHints.length > 0 && this.currentHintIndex != null ? this.coachingHints[this.currentHintIndex % this.coachingHints.length].hint : null }
  get coachingOnClick() { return this.coachingHints.length > 0 && this.currentHintIndex != null ? this.coachingHints[this.currentHintIndex % this.coachingHints.length].onClick : null }


  swipeLeft() {
    this.currentAnimation = "slide-out-left"
    this.currentAnimationLength = ANIMATION_TRANSITION_DURATION_SECONDS;
  }

  swipeRight() {
    this.currentAnimation = "slide-out-right"
    this.currentAnimationLength = ANIMATION_TRANSITION_DURATION_SECONDS;
  }

  onAnimationEnd() {
    if (this.currentAnimation === "slide-in-left") {
      this.currentAnimation = "slide-in-left-wait"
      this.currentAnimationLength = ANIMATION_WAIT_DURATION_SECONDS;
    } else if (this.currentAnimation === "slide-in-left-wait") {
      this.currentAnimation = "slide-out-left"
      this.currentAnimationLength = ANIMATION_TRANSITION_DURATION_SECONDS;
    } else if (this.currentAnimation === "slide-out-left") {
      this.currentHintIndex++
      if (this.currentHintIndex >= this.coachingHints.length) {
        this.currentHintIndex = 0;
      }
      this.currentAnimation = "slide-in-left"
      this.currentAnimationLength = ANIMATION_TRANSITION_DURATION_SECONDS;
    } else if (this.currentAnimation === "slide-in-right") {
      this.currentAnimation = "slide-in-right-wait"
      this.currentAnimationLength = ANIMATION_WAIT_DURATION_SECONDS;
    } else if (this.currentAnimation === "slide-in-right-wait") {
      this.currentAnimation = "slide-out-right"
      this.currentAnimationLength = ANIMATION_TRANSITION_DURATION_SECONDS;
    } else if (this.currentAnimation === "slide-out-right") {
      this.currentHintIndex--
      if (this.currentHintIndex < 0) {
        this.currentHintIndex = this.coachingHints.length - 1;
      }
      this.currentAnimation = "slide-in-right"
      this.currentAnimationLength = ANIMATION_TRANSITION_DURATION_SECONDS;
    }
  }
}

const coachingStore = new CoachingStore();

export function selectedCoachingHints(dayData: DayData) {
  seedrandom(dayData.date.getTime(), { global: true });
  let candidates = generateCoachingCandidates(dayData);
  let chosenHints = randomSubarrayWithWeights(candidates, MAX_COACHING_HINTS_IN_SUMMARY);

  return chosenHints;
}

export const Coaching = observer<{dayData:DayData}>(({dayData}) => {
  const handlers = useSwipeable({
    onSwipedLeft: () => coachingStore.swipeLeft(),
    onSwipedRight: () => coachingStore.swipeRight(),
    onTap: () => coachingStore.coachingOnClick(),
    ...{},
  });
  return <CoachingDiv >
    {coachingStore.coachingHint && <InnerCoachingDiv {...handlers} animationLength={coachingStore.currentAnimationLength} animationName={coachingStore.currentAnimation} onAnimationEnd={() => coachingStore.onAnimationEnd()}>{coachingStore.coachingHint}</InnerCoachingDiv>}
  </CoachingDiv>
});

const InnerCoachingDiv = styled.div<{animationName: string, animationLength: number}>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  animation-duration: ${(({animationLength}) => animationLength)}s;
  animation-name: ${(({animationName}) => animationName)};
  animation-fill-mode: both;
`;

const CoachingDiv = styled.div`
  margin: 0;
  height: 3.2em;
  display: flex;
  justify-content: center;
  align-items: center;
`;


