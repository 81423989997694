import { makeAutoObservable, autorun } from "mobx"
import { toExcelDate } from "@habline/common/utils"
import { gapiAuth } from "./gapi";
import { fb } from "@habline/common/firebase";
import { mainStore } from "@habline/common/MainStore"
import { DayData } from "./DayData"

class DayDataStore {
  daysData = new Map<string, DayData>();

  constructor() {
    makeAutoObservable(this);
  }

  get allLoadedDaysData() { return [...this.daysData.values()] }

  get dayData() { return this.getDayData(mainStore.currentDate); }

  get isLoadingAny() {
    for (let dayData of this.daysData.values()) {
      if (!dayData.isLoadedForTheFirstTime) {
        return true;
      }
    }
    return false;
  }

  get isDirtyAny() {
    return Array.from(this.daysData.values()).some(dayData => dayData.isDirty);
  }

  get isSavingAny() {
    return Array.from(this.daysData.values()).some(dayData => dayData.isSaving);
  }

  get justSavedAny() {
    return Array.from(this.daysData.values()).some(dayData => dayData.justSaved);
  }

  get collection() {
    return fb.firestore().collection(`/workspaces/${gapiAuth.user.uid}/days`).withConverter(DayData);
  }

  init() {
    //
    // Load day data
    //
    autorun(() => {
      if (gapiAuth.workspace && mainStore.currentDate) {
        this.requestDayData(mainStore.currentDate)
      } else {
        for (let dayData of this.daysData.values()) {
          dayData.unsubscribeFromChanges();
        }

        this.daysData.clear();
      }
    }, {name: "Load day data"});
  }

  hasReadyDayData(date: Date) {
    let dayData = this.daysData.get(toExcelDate(date));
    return dayData && dayData.isLoadedForTheFirstTime;
  }

  getDayData(date: Date) {
    return this.daysData.get(toExcelDate(date));
  }

  requestDayData(date: Date) {
    let dateKey = toExcelDate(date);

    if (this.daysData.has(dateKey)) {
      return this.daysData.get(dateKey);
    } else {
      let dayData = new DayData({id: dateKey, date: date});
      this.daysData.set(dateKey, dayData);
      dayData.subscribeToChanges();
      
      return dayData;
    }
  }
}

export const dayDataStore = new DayDataStore();