import { toExcelDate, fromSimpleDate, logEvent, datePlusDays } from "@habline/common/utils"
import { dayDataStore } from "./DayDataStore"
import firebase from 'firebase/app';
import { extractIconFromName, stripIconFromName } from "@habline/common/emojis"
import { habitStore } from "../habits/data/HabitStore";
import { action, computed, makeObservable, observable } from "mobx";
import { Status } from "@habline/common/Status";
import { DayData } from "./DayData";
import { UpdatableInPlace } from "./WithProtectedSaveDelay";
import { newId } from "@habline/common/firebase";
import { pointWeight, timeToMs } from "@habline/common/time";
import { ItemEnder } from "./ItemEnder";
import { itemStore } from "./ItemStore";
import { DAY_START } from "./ItemSegment";

export const DEFAULT_CATEGORY = "⭐";
export const DEFAULT_PRIORITY = 50;

export enum CopyPasteOp {
  MOVE = "move", // Move an item in icebox or outside of it, creates a copy and then marks the original item as skipped and out of inbox
  ADD_FROM_ICEBOX = "add_from_ice", // Adds an item currently in the inbox, then set the original inbox item to skipped (it should be set like that or it's a copy - so we need to set it) and moves it out of inbox.
  COPY = "copy", // Creates a copy without touching the original except for reuses
  COPY_TO_INBOX = "copy_to_ice", // Creates a copy without touching the original except for reuses. Sets it to inbox = true but status UNKNOWN since it was never postponed (and that is how it's distinqushed from that state)
}

export enum RelocateOption {
  BEGINING,
  END
}

export type ItemPriority = number;

export interface ItemConstructorParameters {
  id: string,
  date: Date,
  status?: Status,
  manuallyPutInIcebox?: boolean,
  position?: number,
  reuses?: [],
  reused?: null,
  completionTime?: number,
  lastUpdate?: Date,
  createdOn?: Date,
  insightNote?: string,
  manualName?: string,
  manualCategory?: string,
  manualDescription?: string,
  manualInsertionTime?: number,
  manualDuration?: number,
  manualIsAnchored?: boolean,
  manualPriority?: ItemPriority,
  manualIsPartOfPlan?: boolean,
}

export type ItemDataV0 = {
  id: string,
  status: string,
  defId: string,
}

export type ItemDataV1 = {
  id: string,
  date: string,
  status: Status,
  manuallyPutInIcebox: boolean,
  isInIcebox?: boolean, //legacy
  position: number,
  reuses: {id: string, date: string, type: CopyPasteOp}[],
  reused: {id: string, date: string, type: CopyPasteOp, count: number};
  completionTime: number,
  lastUpdate: firebase.firestore.Timestamp,
  createdOn: firebase.firestore.Timestamp,
  insightNote: string,
  manualName: string,
  name?: string, // legacy
  manualCategory: string,
  manualDescription: string,
  description?: string, // legacy
  manualTime: number,
  timeOverride?: number, // legacy
  sortingTime?: number, // legacy
  manualDuration?: number,
  manualIsAnchored?: boolean,
  manualPriority?: ItemPriority;
  manualIsPartOfPlan?: boolean;
}

export abstract class Item implements UpdatableInPlace<Item> {
  readonly id: string;
  readonly date: Date;
  status: Status;
  
  position: number;
  reuses: {id: string, date: Date, type: CopyPasteOp}[];
  reused: {id: string, date: Date, type: CopyPasteOp, count: number};
  completionTime: number;
  lastUpdate: Date;
  createdOn: Date;

  insightNote: string;

  manuallyPutInIcebox: boolean;

  manualName?: string;
  manualCategory?: string;
  manualDescription?: string;
  manualInsertionTime?: number;
  manualDuration?: number;
  manualIsAnchored?: boolean;
  manualPriority?: ItemPriority;
  manualIsPartOfPlan?: boolean;
  
  isNew = false; //Set externally by ui
  isHighlighted = false; //Set externally by ui

  constructor ({id, date, status = Status.UNKNOWN, manuallyPutInIcebox = false, reuses = [], reused = null, position = 0, completionTime = null, lastUpdate = new Date(), createdOn = new Date(),
                insightNote = "", manualName = null, manualCategory = null, manualDescription = null, manualInsertionTime = null, manualDuration = null, manualIsAnchored = null, manualPriority = null, manualIsPartOfPlan = null}: ItemConstructorParameters) {
    this.id = id;
    this.date = date;
    this.status = status;
    this.manuallyPutInIcebox = manuallyPutInIcebox;
    this.position = position;
    this.reuses = reuses;
    this.reused = reused;
    this.createdOn = createdOn;
    this.completionTime = completionTime;
    this.lastUpdate = lastUpdate;
    this.insightNote = insightNote;

    this.manualName = manualName;
    this.manualCategory = manualCategory;
    this.manualDescription = manualDescription;
    this.manualInsertionTime = manualInsertionTime;
    this.manualDuration = manualDuration;
    this.manualIsAnchored = manualIsAnchored;
    this.manualPriority = manualPriority;
    this.manualIsPartOfPlan = manualIsPartOfPlan;
    
    makeObservable(this, {
      id: observable,
      date: observable,
      status: observable,
      position: observable,
      
      reuses: observable,
      reused: observable,
      createdOn: observable,
      completionTime: observable,
      lastUpdate: observable,
      insightNote: observable,

      manuallyPutInIcebox: observable,
    
      manualName: observable,
      manualCategory: observable,
      manualDescription: observable,
      manualInsertionTime: observable,
      manualDuration: observable,
      manualIsAnchored: observable,
      manualPriority: observable,
      manualIsPartOfPlan: observable,
      
      startsSegment: computed,
      
      isNew: observable,
      isHighlighted: observable,
      isPartOfPlan: computed,

      isInIcebox: computed,
      isMissed: computed,

      enclosedItems: computed,
      time: computed,
      segmentThatThisContinues: computed,
      segmentThatThisStarts: computed,
      
      name: computed,
      category: computed,
      description: computed,
      insertionTime: computed, //ms since midnight
      duration: computed,
      isAnchored: computed,
      priority: computed,
      
      isPostponedToSpecificDate: computed,
      pointWeight: computed,
      dayData: computed,

      isACopy: computed,
      numPostpones: computed,
      completionDate: computed,

      isNoLongerRelevant: computed,

      __setFields: action,
      markReusedBy: action,
      setUnknown: action,
      setSuccess: action,
      setPartial: action,
      ignore: action,
      copyToIcebox: action,
      moveToIcebox: action,
      removeFromIcebox: action,
      setName: action,
      setCategory: action,
      setDescription: action,
      setDuration: action,
      setIsAnchored: action,
      updateFromFirebase: action,
    })
  }

  makeSureThatYouAreNotHidden(): void {
    
  }

  updateFromFirebase(item: Item): void {
    console.assert(this.id === item.id);
    console.assert(this.date.getTime() === item.date.getTime());

    this.status = item.status;
    this.manuallyPutInIcebox = item.manuallyPutInIcebox;
    this.position = item.position;
    this.reuses = item.reuses;
    this.reused = item.reused;
    this.createdOn = item.createdOn;
    this.completionTime = item.completionTime;
    this.lastUpdate = item.lastUpdate;
    this.insightNote = item.insightNote;

    this.manualName = item.manualName;
    this.manualCategory = item.manualCategory;
    this.manualDescription = item.manualDescription;
    this.manualInsertionTime = item.manualInsertionTime;
    this.manualDuration = item.manualDuration;
    this.manualIsAnchored = item.manualIsAnchored;
    this.manualPriority = item.manualPriority;
    this.manualIsPartOfPlan = item.manualIsPartOfPlan;
    
    this.status = item.status;

    this.performItemTypeUpdateFromFirebase(item);
    
    //isNew = false; //Set externally by ui
    //isHighlighted = false; //Set externally by ui
  }

  abstract instanceForCopy(id: string, date: Date): Item;
  abstract performItemTypeSpecificCopyOps(newItem: Item, type:CopyPasteOp): void;
  abstract performItemTypeUpdateFromFirebase(item: Item): void;
  
  get time() {
    let time = this.dayData.itemOrEnderTimes[this.id];
    return time != null ? time : this.insertionTime;
  }

  get segmentThatThisContinues() {
    return this.dayData.lookupSegment(this.id);
  }

  get segmentThatThisStarts() {
    return this.dayData.lookupSegment(this.id, false);
  }

  get enclosedItems() {
    let ret: (Item|ItemEnder)[] = [];

    if (this.startsSegment) {
      let inThisItem = false;
      for (let itemSeparatorOrEnder of this.dayData.itemsAndEndersSequence) {
        if (inThisItem) {
          if (itemSeparatorOrEnder instanceof ItemEnder) {
            ret.push(itemSeparatorOrEnder);
            inThisItem = false;
          } else {
            if (itemSeparatorOrEnder instanceof Item) {
              ret.push(itemSeparatorOrEnder);
            }
          }
        } else {
          if (itemSeparatorOrEnder.id === this.id) {
            inThisItem = true;
          }
        }
      }
    }

    return ret;
  }

  get autoName() { return "" }
  get autoCategory() { return DEFAULT_CATEGORY }
  get autoDescription() { return "" }
  get autoInsertionTime() { return 0 }
  get autoDuration() { return timeToMs("00:15") }
  get autoIsAnchored() { return false }
  get autoPriority() { return DEFAULT_PRIORITY }
  

  get isNoLongerRelevant() { return false }
  get isInIcebox() { return (this.manuallyPutInIcebox || (this.isMissed && this.status === Status.UNKNOWN && this.canBeDoneLater)) && !this.isNoLongerRelevant }
  get isMissed() { return this.status === Status.MISSED || !this.isPartOfPlan }
  get isUnknown() { return this.status === Status.UNKNOWN && this.isPartOfPlan }

  get name() { return this.manualName !== null ? this.manualName : stripIconFromName(this.autoName) }
  get category() { return this.manualCategory !== null ? this.manualCategory : extractIconFromName(this.autoName, this.autoCategory) }
  get description() { return this.manualDescription !== null ? this.manualDescription : this.autoDescription }
  get insertionTime() { return this.manualInsertionTime !== null ? this.manualInsertionTime : (this.reused ? 0 : this.autoInsertionTime) } //ms since midnight
  get duration() { return this.manualDuration !== null ? this.manualDuration : this.autoDuration }
  get isAnchored() { return this.manualIsAnchored !== null ? this.manualIsAnchored : this.autoIsAnchored }
  get startsSegment() { return this.isAnchored }
  get priority() { return this.manualPriority !== null ? this.manualPriority : this.autoPriority }
  get isPartOfPlan() { return !this.dayData.sealed && this.manualIsPartOfPlan !== false && !this.dayData.autoMissedItemIds.has(this.id)}
  
  get isNameOverriden() { return this.manualName != null }
  get isCategoryOverriden() { return this.manualCategory != null }
  get isDescriptionOverriden() { return this.manualDescription != null }
  get isTimeOverriden() { return this.manualInsertionTime != null }
  get isDurationOverriden() { return this.manualDuration != null }
  get isAnchoredOverriden() { return this.manualIsAnchored != null }
  get isPriorityOverriden() { return this.manualPriority != null }

  get isPostponedToSpecificDate() { return this.reuses.some(({type}) => type === CopyPasteOp.ADD_FROM_ICEBOX) }
  get isACopy() { return this.numPostpones === 0 && this.reused && ([CopyPasteOp.ADD_FROM_ICEBOX, CopyPasteOp.COPY_TO_INBOX, CopyPasteOp.COPY].indexOf(this.reused.type) !== -1) }
  get numPostpones() {
    return (this.status === Status.MISSED && (this.isPostponedToSpecificDate || this.isInIcebox) ? 1 : 0) +
           (this.reused ? this.reused.count : 0)
  }

  get completionDate() {
    let HOURS_24 = 1000 * 60 * 60 * 24;
    let daysDelta = 0;
    let completionTime = this.completionTime;

    while (completionTime < 0) {
      daysDelta -= 1;
      completionTime += HOURS_24;
    }

    while (completionTime >= HOURS_24) {
      daysDelta += 1;
      completionTime -= HOURS_24;
    }

    console.assert(completionTime >= 0 && completionTime < HOURS_24);

    return datePlusDays(this.date, daysDelta);
  }

  get type() { return "item" }
  get canBeDeleted() { return !!this.reused }
  get canBeDoneLater() { return true }
  get canOverrideName() { return false }
  get canOverrideCategory() { return false }
  get canOverrideDescription() { return false }
  get canOverrideDuration() { return false }
  get canOverrideIsAnchored() { return true }
  get canBePostponed() { return false }
  get canOverridePriority() { return true }

  get hasError() {
    if (this.isAnchored) {
      if (this.segmentThatThisContinues != null && this.segmentThatThisContinues.start.id !== DAY_START && this.segmentThatThisContinues.duration < 0) {
        return true;
      }
    }

    return false;
  }

  get pointWeight() {
    return pointWeight(this.duration);
  }

  get dayData(): DayData {
    return dayDataStore.getDayData(this.date);
  }

  __setFields(fieldsDict: { [x: string]: any }) {
    let dayData = this.dayData;

    for (let field of Object.keys(fieldsDict)) {
      this[field] = fieldsDict[field];

      if (field === "status") {
        const now = new Date();
        this.completionTime = now.getTime() - this.date.getTime()
      }
    }

    this.lastUpdate = new Date();

    console.log("SAVING", dayData);
    dayData.save();
  }

  markReusedBy(newItem: Item, type : CopyPasteOp) {

    let sts = {} as any

    if (type === CopyPasteOp.MOVE) {
      sts.manuallyPutInIcebox = false;
      sts.status = Status.MISSED;
    } else if (type === CopyPasteOp.ADD_FROM_ICEBOX) {
      sts.manuallyPutInIcebox = false;
      sts.status = Status.MISSED;
    } else if (type === CopyPasteOp.COPY) {
      
    } else if (type === CopyPasteOp.COPY_TO_INBOX) {
      
    } else {
      throw Error("Unknown type " + type)
    }

    this.__setFields({
      reuses: [...this.reuses, {
        id: newItem.id,
        date: newItem.date,
        type: type,
      }],
      ...sts,
    });
  }

  setInsertionTime(ms?: number): void {
    //Note: We don't update the lastUpdate on purpose, this is a minor change

    this.manualInsertionTime = ms;
    this.manualIsAnchored = ms != null;
    this.dayData.save();
  }

  setUnknown() {
    this.__setFields({status: Status.UNKNOWN, manuallyPutInIcebox: false});
    itemStore.notifyAboutItemStatus != null && itemStore.notifyAboutItemStatus(this);
  }

  setSuccess() {
    this.__setFields({status: Status.SUCCESS, manuallyPutInIcebox: false});
    itemStore.notifyAboutItemStatus != null && itemStore.notifyAboutItemStatus(this);
  }

  setPartial() {
    this.__setFields({status: Status.PARTIAL, manuallyPutInIcebox: false});
    itemStore.notifyAboutItemStatus != null && itemStore.notifyAboutItemStatus(this);
  }

  ignore() {
    this.__setFields({status: Status.MISSED, manuallyPutInIcebox: false});
    itemStore.notifyAboutItemStatus != null && itemStore.notifyAboutItemStatus(this);
  }

  doHighlight() {
    const HIGHLIGHT_DURATION = 2000;
    this.isHighlighted = true;
    setTimeout(action(() => { this.isHighlighted = false; }), HIGHLIGHT_DURATION);
  }

  doNewItemEntry() {
    const IS_NEW_DURATION = 1000;
    this.isNew = true;
    setTimeout(action(() => { this.isNew = false; }), IS_NEW_DURATION);
  }

  moveTo(option: RelocateOption) {
    //TODO: Does not understand structure, like enders etc, should it?

    let targetPosition = null;

    switch (option) {
      case RelocateOption.BEGINING:
        if (this.dayData.itemsAndEndersSequence.length > 0) {
          targetPosition = this.dayData.itemsAndEndersSequence[0].position - 0.1;
        }
        break;
      case RelocateOption.END:
        if (this.dayData.itemsAndEndersSequence.length > 0) {
          targetPosition = this.dayData.itemsAndEndersSequence[this.dayData.itemsAndEndersSequence.length - 1].position + 0.1;
        }
        break;
    }

    if (targetPosition != null) {
      let diff = targetPosition - this.position;
      this.position = targetPosition;
      this.dayData.save();
      return diff;
    } else {
      return null;
    }
  }

  performCopy(date: Date, type: CopyPasteOp) {
    var item = this.instanceForCopy(newId(), date);
  
    item.status = Status.UNKNOWN;
    item.manuallyPutInIcebox = type === CopyPasteOp.COPY_TO_INBOX;
    item.position = this.position + 0.1;
    item.reused = {id: this.id, date: this.date, type: type, count: [CopyPasteOp.COPY, CopyPasteOp.COPY_TO_INBOX].indexOf(type) !== -1 ? 0 : this.numPostpones }
    //item.completionTime = (new Date()).getTime() - this.date.getTime()

    if (item.canOverrideName && item.name !== this.name) item.manualName = this.name;
    if (item.canOverrideCategory && item.category !== this.category) item.manualCategory = this.category;
    if (item.canOverrideDescription && item.description !== this.description) item.manualDescription = this.description;
    if (item.canOverrideDuration && item.duration !== this.duration) item.manualDuration = this.duration;
    if (item.canOverridePriority && item.priority !== this.priority) item.manualPriority = this.priority;

    //insight note is intentionally ommited
    //time is intentionally ommited
    
    this.performItemTypeSpecificCopyOps(item, type);

    return Promise.all([dayDataStore.requestDayData(date).addItem(item), this.markReusedBy(item, type)]);
  }

  copyToIcebox() {
    this.performCopy(this.date, CopyPasteOp.COPY_TO_INBOX);
  }

  moveToIcebox() {
    this.__setFields({status: Status.MISSED, manuallyPutInIcebox: true});
    itemStore.notifyAboutItemStatus != null && itemStore.notifyAboutItemStatus(this);
  }

  removeFromIcebox() {
    this.__setFields({manuallyPutInIcebox: false});
    itemStore.notifyAboutItemStatus != null && itemStore.notifyAboutItemStatus(this);
  }

  //Status.MISSED_FORWARDED Status.IGNORED_FORWARDED???
  //setIsInIcebox a moze jednak inaczej? Status.IGNORED Status.MISSED_ICEBOXED Status.IGNORED_ICEBOXED

  setInsightNote(insightNote: string) {
    this.__setFields({insightNote});
  }

  setName(name: string) {
    logEvent('set_item_name', {name: name});
    this.__setFields({manualName: name});
  }

  setCategory(category: string) {
    logEvent('set_item_category', {category: category});
    this.__setFields({manualCategory: category});
  }

  setDescription(description: string) {
    logEvent('set_item_description', {description: description});
    this.__setFields({manualDescription: description});
  }

  setDuration(duration: number) {
    logEvent('set_item_duration', {duration: duration});
    this.__setFields({manualDuration: duration});
  }

  setIsAnchored(isAnchored: boolean) {
    logEvent('set_item_is_anchored', {isAnchored: isAnchored});
    this.__setFields({manualIsAnchored: isAnchored});
  }

  setPriority(priority: ItemPriority) {
    logEvent('set_item_priority', {priority});
    this.__setFields({manualPriority: priority});
  }

  setIsPartOfPlan(isPartOfPlan: boolean) {
    logEvent('set_item_is_part_of_plan', {isPartOfPlan});
    this.__setFields({manualIsPartOfPlan: isPartOfPlan});
  }

  toFirestore(): ItemDataV1 {
    return {
      id: this.id,
      date: toExcelDate(this.date),
      status: this.status,
      manuallyPutInIcebox: this.manuallyPutInIcebox,
      position: this.position,
      reuses: this.reuses.map(({id, date, type}) => { return {id, date: toExcelDate(date), type} }),
      reused: this.reused ? {id: this.reused.id, date: toExcelDate(this.reused.date), type: this.reused.type, count: this.reused.count} : null,
      createdOn: firebase.firestore.Timestamp.fromDate(this.createdOn),
      completionTime: this.completionTime,
      lastUpdate: firebase.firestore.Timestamp.fromDate(this.lastUpdate),
      insightNote: this.insightNote,

      manualName: this.manualName,
      manualCategory: this.manualCategory,
      manualDescription: this.manualDescription,
      manualTime: this.manualInsertionTime,
      manualDuration: this.manualDuration,
      manualIsAnchored: this.manualIsAnchored,
      manualPriority: this.manualPriority,
      manualIsPartOfPlan: this.manualIsPartOfPlan,
    }
  }

  fromFirestore(data: ItemDataV1, version: number) {
    if (version < 1) {
      let legacyData: ItemDataV0 = data as unknown as ItemDataV0;

      console.log("CONVERSION FROM OLD VERSION", version, " OF ", legacyData)
      const STATUS_RIP = "X";
      const STATUS_REUSED = "⮕";
      const STATUS_IGNORED = "I";

      data.manuallyPutInIcebox = data.status === Status.MISSED && (!legacyData.defId || habitStore.habitDefMap[legacyData.defId].canBeDoneLater); //TODO: Do one time conversion for everything
      
      if (legacyData.status === STATUS_RIP) data.status = Status.MISSED;
      if (legacyData.status === STATUS_IGNORED) data.status = Status.MISSED;
      if (legacyData.status === STATUS_REUSED) data.status = Status.MISSED;

      data.reuses = [];

      if (legacyData.status === STATUS_REUSED) {
        data.reuses.push({id: null, date: null, type: CopyPasteOp.ADD_FROM_ICEBOX})
      }  
    }

    function mapCopyOp(type:any): CopyPasteOp {
      if (type == null) return CopyPasteOp.ADD_FROM_ICEBOX;
      if (type === CopyPasteOp.ADD_FROM_ICEBOX) return CopyPasteOp.ADD_FROM_ICEBOX;
      if (type === CopyPasteOp.COPY) return CopyPasteOp.COPY;
      if (type === CopyPasteOp.COPY_TO_INBOX) return CopyPasteOp.COPY_TO_INBOX;
      if (type === CopyPasteOp.MOVE) return CopyPasteOp.MOVE;
      if (type === "postpone") return CopyPasteOp.ADD_FROM_ICEBOX;
      if (type === "copy_inbox") return CopyPasteOp.COPY_TO_INBOX;
      throw Error(`Unknown type ${type}`)
    }

    console.assert(this.id === data.id);
    console.assert(this.date.getTime() === fromSimpleDate(data.date).getTime());
    this.status = data.status;
    this.manuallyPutInIcebox = !!(data.isInIcebox || data.manuallyPutInIcebox);
    this.position = data.position != null ? data.position : 0;
    this.reuses = data.reuses.map(({id, date, type}) => { return {id, date: fromSimpleDate(date), type: mapCopyOp(type)} })
    this.reused = data.reused ? {id: data.reused.id, date: fromSimpleDate(data.reused.date), type: mapCopyOp(data.reused.type), count: !!data.reused.count ? data.reused.count : 0} : null;

    let minDate = new Date(1, 0);
    this.createdOn = data.createdOn ? data.createdOn.toDate() : minDate;

    let legacyCompletionDate = new Date(this.lastUpdate);
    legacyCompletionDate.setHours(0, 0, 0, 0);
    
    let legacyCompletionTime = null;

    if (data.status !== Status.UNKNOWN) {
      if (legacyCompletionDate.getTime() === this.date.getTime()) {
        legacyCompletionTime = this.lastUpdate.getTime() - this.date.getTime();
      }
    }
    
    this.completionTime = data.completionTime != null ? data.completionTime : legacyCompletionTime;
    this.lastUpdate = data.lastUpdate.toDate();
    this.insightNote = data.insightNote != null ? data.insightNote : "";

    this.manualName = data.manualName != null ? data.manualName : (data.name != null ? data.name : null);
    this.manualCategory = data.manualCategory != null ? data.manualCategory : null;
    this.manualDescription = data.manualDescription != null ? data.manualDescription : (data.description != null ? data.description : null);
    this.manualInsertionTime = data.manualTime != null ? data.manualTime : (data.timeOverride != null ? data.timeOverride : (data.sortingTime != null ? data.sortingTime : null));
    this.manualDuration = data.manualDuration != null ? data.manualDuration : null;
    this.manualIsAnchored = data.manualIsAnchored != null ? data.manualIsAnchored : null;
    this.manualPriority = data.manualPriority != null ? data.manualPriority : null;
    this.manualIsPartOfPlan = data.manualIsPartOfPlan != null ? data.manualIsPartOfPlan : null;
  }
}
