import { action, computed, makeObservable, observable } from "mobx";
import { CopyPasteOp, Item, ItemConstructorParameters } from "../../data/Item";
import { habitStore } from "./HabitStore";
import { Status } from "@habline/common/Status";
import { DEFAULT_HABIT_CATEGORY } from "./HabitDef"
import { mainStore } from "@habline/common/MainStore";
import { datePlusDays } from "@habline/common/utils.common";

interface HabitItemConstructorParameters extends ItemConstructorParameters {
  defId?: string|undefined
}

export class HabitItem extends Item {
  defId: string|undefined

  constructor({defId = undefined, ...args }: HabitItemConstructorParameters) {
    super(args);
    this.defId = defId;

    makeObservable(this, {
      defId: observable,
      canBeDoneLater: computed,
      canOverrideDuration: computed,
      canBePostponed: computed,
      autoName: computed,
      autoCategory: computed,
      autoDescription: computed,
      autoDuration: computed,
      autoIsAnchored: computed,
      autoPriority: computed,
      habitDef: computed,
      toFirestore: action,
      instanceForCopy: action,
      fromFirestore: action,
      performItemTypeUpdateFromFirebase: action,
    });
  }

  get type() { return "habit" }
  get canBeDoneLater() { return this.habitDef ? this.habitDef.canBeDoneLater : false; }
  get canOverrideDuration() { return true }

  get canBePostponed() { return this.canBeDoneLater && [Status.UNKNOWN, Status.MISSED].includes(this.status) }
  
  get autoName() { return this.habitDef ? this.habitDef.name : "???"; }
  get autoCategory() { return this.habitDef ? this.habitDef.category : DEFAULT_HABIT_CATEGORY }
  get autoDescription() { return this.habitDef ? this.habitDef.description : ""; }
  get autoInsertionTime() { return this.habitDef ? this.habitDef.timeBasedOnAnchor : 0; }
  get autoDuration() { return this.habitDef ? this.habitDef.duration : 0 }
  get autoIsAnchored() { return false } // This false here makes it so when habitDef is deleted - I loose weight information ... should habit info be stored in each item?
  get autoTimeAnchor() { return this.habitDef ? this.habitDef.timeAnchor : 0 }
  get autoPositionRelativeToAnchor() { return this.habitDef ? this.habitDef.positionRelativeToAnchor : 1 }
  get autoPriority() { return this.habitDef ? this.habitDef.priority : 0 }

  get habitDef() { return habitStore.habitDefMap[this.defId]; }

  get isNoLongerRelevant() {
    let currentDate = mainStore.today;
    while (currentDate.getTime() > this.date.getTime()) {
      if (this.habitDef.isInThisSet(currentDate)) {
        return true;
      }
      currentDate = datePlusDays(currentDate, -1)
    }
    return false;
  }

  toFirestore() {
    return {
      defId: this.defId,
      ...super.toFirestore()
    };
  }

  instanceForCopy(id: string, date: Date) {
    return new HabitItem({id, date});
  }

  performItemTypeSpecificCopyOps(newItem: Item, type: CopyPasteOp) { 
    if (newItem instanceof HabitItem) {
      newItem.defId = this.defId;
    }
  }

  fromFirestore(data: any, version: number) {
    super.fromFirestore(data, version);
    this.defId = data.defId;
  }

  performItemTypeUpdateFromFirebase(item: Item): void {
    this.defId = (item as HabitItem).defId
  }
}