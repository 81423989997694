import { isValidDate, fromSimpleDate, err, assertUniqueId, toExcelDate } from "@habline/common/utils"
import { makeAutoObservable, autorun, action } from "mobx"
import { gapiAuth } from "./gapi"
import { fb } from "@habline/common/firebase";
import { DayTag, DayTagCommon } from "./DayTag";

export function isInThisDaySet(date: Date, tagOrHabit) {
  try {
    if (tagOrHabit.compiledAutoExpression.status) {
      tagOrHabit.compiledAutoExpression.value.setSeed(tagOrHabit.id)
      return tagOrHabit.compiledAutoExpression.value.isInThisSet(date);
    } else {
      return false;
    }
  } catch (e) {
    console.log("Error", e, tagOrHabit.autoExpression, tagOrHabit.compiledAutoExpression);
    return false;
  }
}

export function splitText(str: string) {
  console.log(str, str.split(",").map(text => text.trim()))
  return str.split(",").map(text => text.trim()).filter(text => text.length > 0);
}

export function setIdsInDays(days, allowedSetNames) {
  return days.filter(s => allowedSetNames.find(allowedName => allowedName.toLowerCase() === s.toLowerCase()))
}

export function datesInDays(days) {
  return days.filter(s => isValidDate(fromSimpleDate(s)))
}

const POLISH_HOLIDAYS = ["2020-01-01", "2020-01-06", "2020-04-12", "2020-04-13", "2020-05-01", "2020-05-03", "2020-05-31", "2020-06-11", "2020-08-15", "2020-11-01", "2020-11-11", "2020-12-25", "2020-12-26",
                         "2021-01-01", "2021-01-06", "2021-04-04", "2021-04-05", "2021-05-01", "2021-05-03", "2021-05-23", "2021-06-03", "2021-08-15", "2021-11-01", "2021-11-11", "2021-12-25", "2021-12-26"];

class PolishHolidaysTag extends DayTagCommon {
  constructor (id: string) {
    super();
    this.id = id;
  }

  isInThisSet(date) {
    return POLISH_HOLIDAYS.indexOf(toExcelDate(date)) !== -1;
  }
}

class DayTagStore {
  isLoadedForTheFirstTime: boolean = false;
  dayTags: DayTagCommon[] = [];
  dayTagMapping: {[key: string]: DayTag} = {};

  constructor() {
    makeAutoObservable(this);
  }

  tagsForDate(date: Date) {
    return this.dayTags.filter(dayTag => dayTag.isInThisSet(date));
  }

  get customDayTags() { return this.dayTags.filter(tag => tag instanceof DayTag) }
  get autoDayTags() { return this.dayTags.filter(tag => !(tag instanceof DayTag)) }
  get collection() { return fb.firestore().collection(`/workspaces/${gapiAuth.user.uid}/daySets`).withConverter(DayTag); }

  init() {
    //
    // Load ordering data
    //
    let unsubscribe = null;
    autorun((() => {
      if (unsubscribe) {
        unsubscribe()
        unsubscribe = null;
      }

      if (gapiAuth.user) {
        console.log("Loading daySets");
 
        unsubscribe = this.collection.onSnapshot(action(docs => {
          let daySetMapping = {};
          this.dayTags = docs.docs.map((item) => item.data());

          //add syntentic sets
          this.dayTags.push(new PolishHolidaysTag("polishholiday"))

          assertUniqueId(this.dayTags, "day sets");

          for (let daySet of this.dayTags) {
            daySetMapping[daySet.id] = daySet;
          }

          this.dayTagMapping = daySetMapping;
          this.isLoadedForTheFirstTime = true;
          console.log("Loaded daySets");
        }), err)
      } else {
        this.dayTags = [];
      }
    }), {name: "Load daySets"})
  }
}
  
export const dayTagStore = new DayTagStore();