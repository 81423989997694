import { observable, reaction, action, makeObservable, computed } from "mobx"
import { toExcelDate, fromSimpleDate, removeItemOnce, assertUniqueId } from "@habline/common/utils"
import { dayDataStore } from "./DayDataStore"
import { habitStore } from "../habits/data/HabitStore"
import { eventStore } from "../googlecalendar/data/EventStore"
import { TaskItem } from "./TaskItem"
import { HabitItem } from "../habits/data/HabitItem"
import { EventItem } from "../googlecalendar/data/EventItem"
import { Item } from "./Item"
import { mainStore } from "@habline/common/MainStore"
import { dayTagStore } from "./DayTagStore"
import { Status } from "@habline/common/Status"
import { carefullUpdateArray, WithProtectedSaveDelay } from "./WithProtectedSaveDelay"
import { scrollToItem } from "../components/scrollToItem"
import { ItemEnder } from "./ItemEnder"
import { extractStats } from "./StatsStore"
import { gapiAuth } from "./gapi"
import { DayStartEnd, DAY_END, DAY_START, ItemSegment } from "./ItemSegment"

export class DayData extends WithProtectedSaveDelay<DayData> {
  
  static VERSION = 1;

  readonly date: Date

  lastUpdate: Date
  items: Item[]
  itemEnders: ItemEnder[]
  note: string
  sealed: boolean

  manualDayStartTime: number|null
  manualDayEndTime: number|null

  isLoadingRawEvents = false
  loadedRawEvents = null

  areEventsInjectedForTheFirstTime = false
  areHabitsInjectedForTheFirstTime = false

  tmpLog() { }

  constructor({id, checkSum = undefined, date, items = [], itemSeparators = [], itemEnders = [], note = "", sealed = false, manualDayStartTime = null, manualDayEndTime = null, lastUpdate = new Date()}) {
    super(id, checkSum);

    this.date = date;
    this.lastUpdate = lastUpdate;
    this.items = items;
    this.itemEnders = itemEnders;
    this.note = note;
    this.sealed = sealed;
    this.manualDayStartTime = manualDayStartTime;
    this.manualDayEndTime = manualDayEndTime;

    makeObservable(this, {
      lastUpdate: observable,
      items: observable,
      itemEnders: observable,
      note: observable,
      sealed: observable,
      manualDayStartTime: observable,
      manualDayEndTime: observable,

      isLoadingRawEvents: observable,
      loadedRawEvents: observable,

      areEventsInjectedForTheFirstTime: observable,
      areHabitsInjectedForTheFirstTime: observable,

      isToday: computed,
      isPast: computed,
      isFuture: computed,

      dayStartTime: computed,
      dayEndTime: computed,

      isReadyToRunRunners: computed,
      //dbReference: computed,
      eventsFromRawEvents: computed,
      habitsFromDefs: computed,
      habitDefIds: computed,
      successfulyCompletedScore: computed,
      completedItems: computed,
      itemsAndEndersSequence: computed,
      totalDuration: computed,
      score: computed,
      autoMissedItems: computed,

      segments: computed,
      itemOrEnderTimes: computed,
      itemOrEnderIdMap: computed,
      //lookupItemOrEnder: action,
      itemOrEnderSegmentIdMap: computed,
      //lookupSegment: action,
      autoMissedItemIds: computed,

      updateFromFirebase: action,
      preSubScribe: action,
      injectIntoItems: action,
      addItem: action,
      sortItems: action,
    });
  }

  get isToday() {
    return mainStore.today.getTime() === this.date.getTime()
  }

  get isPast() {
    return mainStore.today.getTime() > this.date.getTime()
  }

  get isFuture() {
    return mainStore.today.getTime() < this.date.getTime()
  }

  get didAnyThingHappendOnThisDay() {
    return this.items.length > 0 || this.note.length > 0
  }
  
  get dayStartTime() {
    return Math.min(Math.max(this.manualDayStartTime != null ? this.manualDayStartTime : gapiAuth.workspace.wakeUpTime, mainStore.now.getTime() - this.date.getTime()), this.dayEndTime);
  }

  get dayEndTime() {
    if (this.manualDayEndTime != null) {
      return this.manualDayEndTime;
    } else {
      return gapiAuth.workspace.goodNightTime;
    }
  }

  get isReadyToRunRunners(): boolean {
    return this.isLoadedForTheFirstTime && this.areEventsInjectedForTheFirstTime && this.areHabitsInjectedForTheFirstTime;
  }

  get dbReference() {
    return dayDataStore.collection.doc(this.id)
  }

  get eventsFromRawEvents() {
    if (this.loadedRawEvents != null) {
      return eventStore.itemsFromRawEvents(this);
    } else {
      return null;
    }
  }

  get habitsFromDefs() {
    return habitStore.habitsForDate(this.date);
  }

  get habitDefIds() {
    let habitItems = this.items.filter(item => item instanceof HabitItem) as HabitItem[];
    return habitItems.map(item => item.defId)
  }

  get successfulyCompletedScore() {
    return this.items.filter(item => item.status === Status.SUCCESS).length + this.items.filter(item => item.status === Status.PARTIAL).length/2;
  }

  get completedItems() {
    let completedItems = this.items.filter(item => item.status !== Status.UNKNOWN || !item.isPartOfPlan);
    
    completedItems.sort(function (item1, item2) {
      if (item1.completionTime > item2.completionTime) return -1;
      if (item1.completionTime < item2.completionTime) return 1;
      return 0;
    });

    return completedItems;
  }

  get itemsAndEndersSequence() {
    let rawUnknownAvailableItems = this.items.filter(item => item.status === Status.UNKNOWN && !item.manuallyPutInIcebox);
    let itemsSeparatorsAndEndersSequence = [...rawUnknownAvailableItems, ...this.itemEnders];

    itemsSeparatorsAndEndersSequence.sort(function (item1, item2) {
      if (item1.position < item2.position) return -1;
      if (item1.position > item2.position) return 1;
      return 0;
    });

    return itemsSeparatorsAndEndersSequence;
  }

  get totalDuration(): number {
    let duration = 0;
    let inItem = null;

    for (let itemSeparatorOrEnder of this.itemsAndEndersSequence) {
      if (inItem != null) {
        if (itemSeparatorOrEnder instanceof ItemEnder) {
          inItem = null;
        }
      } else {
        if (itemSeparatorOrEnder instanceof Item) {
          duration += itemSeparatorOrEnder.duration;

          if (itemSeparatorOrEnder.startsSegment) {
            inItem = itemSeparatorOrEnder;
          }
        }
      }
    }

    return duration;
  }

  get score(): number {
    return extractStats([this]).successful
  }

  get autoMissedItems() {
    console.assert(!this.sealed, "Must be not sealed")

    let toIceBox: Item[] = [];
    let toSkip: Item[] = [];
    
    for (const item of this.items) {
      if (item.status === Status.UNKNOWN && !item.isPartOfPlan) {
        if (item.canBeDoneLater) {
          toIceBox.push(item);
        } else {
          toSkip.push(item);
        }
      }
    }

    return {toIceBox, toSkip};
  }

  get segments(): ItemSegment[] {
    let segments = [];

    let itemsSeparatorsOrEnders = this.itemsAndEndersSequence;
    let index = -1;

    let isSegmentTerminator = (itemSeparatorOrEnder) => (itemSeparatorOrEnder instanceof Item && (itemSeparatorOrEnder.isAnchored || itemSeparatorOrEnder.startsSegment)) || (itemSeparatorOrEnder instanceof ItemEnder)
    
    while (index < itemsSeparatorsOrEnders.length) {
      let start: Item|ItemEnder|DayStartEnd = index >= 0 ? itemsSeparatorsOrEnders[index] : new DayStartEnd(DAY_START, this.dayStartTime);
      console.assert((start.id === DAY_START || isSegmentTerminator(start)));

      let items: Item[] = [];

      index++;
      
      while (index < itemsSeparatorsOrEnders.length && !isSegmentTerminator(itemsSeparatorsOrEnders[index])) {
        let itemSeparatorOrEnder = itemsSeparatorsOrEnders[index];
        items.push(itemSeparatorOrEnder as Item); //has to be an item because of how the isSegmentTerminator works
        index++;
      }
      
      let end = index < itemsSeparatorsOrEnders.length ? itemsSeparatorsOrEnders[index] : new DayStartEnd(DAY_END, Math.max(this.dayEndTime, start.insertionTime + start.duration));

      segments.push(new ItemSegment(this, start, items, end));
    }


    console.log("COMPUTING SEGMENTS", toExcelDate(this.date), segments)
    return segments;
  }

  get itemOrEnderTimes() {
    console.log("RECALCULATING itemOrEnderTimes", toExcelDate(this.date))
    let times = {}

    for (let seg of this.segments) {
      let time = seg.start.insertionTime;

      if (seg.end instanceof ItemEnder) {
        times[seg.end.id] = seg.start.insertionTime + seg.start.duration;
      }

      if (seg.end instanceof Item) {
        times[seg.end.id] = seg.end.isAnchored ? seg.end.insertionTime : time;
      }

      let midPoint = seg.items.length / 2;

      for (let i = 0; i < midPoint; i++) {
        let item = seg.items[i];
        times[item.id] = time;
        time += item.duration;
      }

      time = seg.end.insertionTime;

      for (let i = seg.items.length - 1; i >= midPoint; i--) {
        let item = seg.items[i];
        time -= item.duration;
        times[item.id] = time;
      }
    }

    return times;
  }

  get itemOrEnderIdMap() {
    console.log("RECALCUALATING", "itemOrEnderIdMap")
    let itemOrEnderIdMap: {[key: string]: Item | ItemEnder} = {};

    for (let item of this.items) {
      itemOrEnderIdMap[item.id] = item;
    }

    for (let ender of this.itemEnders) {
      itemOrEnderIdMap[ender.id] = ender;
    }

    return itemOrEnderIdMap;
  }

  lookupItemOrEnder(id: string) {
    return this.itemOrEnderIdMap[id];
  }

  get autoMissedItemIds() {
    let autoMissedItemIds = new Set<string>();

    if (!this.isFuture) {
      for (let seg of this.segments) {
        let availableTime = seg.duration;
        let iteratablePart = seg.items.slice(0);
        iteratablePart.sort((a, b) => b.priority - a.priority)

        for (let item of iteratablePart.filter(item => item.manualIsPartOfPlan === true)) {
          availableTime -= item.duration;
        }

        for (let item of iteratablePart.filter(item => item.manualIsPartOfPlan !== true)) {
          if (availableTime >= item.duration || item.duration === 0) {
            availableTime -= item.duration;
          } else {
            autoMissedItemIds.add(item.id);
          }
        }
      }
    }


    console.debug("autoMissedItemIds", toExcelDate(this.date), autoMissedItemIds)

    return autoMissedItemIds;
  }

  get itemOrEnderSegmentIdMap() {
    console.log("RECALCUALATING", "itemOrEnderSegmentIdMap")
    let itemOrEnderIdMap:{[key: string]: ItemSegment}[] = [ {}, {} ]

    for (let segment of this.segments) {
      for (let item of segment.items) {
        itemOrEnderIdMap[0][item.id] = segment;
        itemOrEnderIdMap[1][item.id] = segment;
      }

      let startId = segment.start.id;
      let endId = segment.end.id;

      if (itemOrEnderIdMap[0][startId] == null) itemOrEnderIdMap[0][startId] = segment;
      itemOrEnderIdMap[1][startId] = segment;
      
      itemOrEnderIdMap[0][endId] = segment;
      if (itemOrEnderIdMap[1][endId] == null) itemOrEnderIdMap[1][endId] = segment;
    }

    return itemOrEnderIdMap;
  }

  lookupSegment(itemOrEnderId: string, first: boolean = true) {
    return this.itemOrEnderSegmentIdMap[first ? 0 : 1][itemOrEnderId];
  }

  updateFromFirebase(dayData: DayData): void {
    if (dayData != null) {
      console.assert(this.id === dayData.id, `Mismatch between '${this.id}' and '${dayData.id}'`);
      console.assert(this.date.getTime() === dayData.date.getTime(), `Mismatch between '${this.date}' and '${dayData.date}'`);

      carefullUpdateArray(this.items, dayData.items);
      carefullUpdateArray(this.itemEnders, dayData.itemEnders);
      this.lastUpdate = dayData.lastUpdate;
      this.note = dayData.note;
      this.manualDayStartTime = dayData.manualDayStartTime;
      this.manualDayEndTime = dayData.manualDayEndTime;
      this.sealed = dayData.sealed;
    }

    console.log("Loaded", this.id)
  }

  preSubScribe(): void {
    this.toDisposeOnUnsubscribe.push(reaction(
      () => [this.habitsFromDefs, this.sealed, dayTagStore.isLoadedForTheFirstTime, habitStore.isLoadedForTheFirstTime, this.isLoadedForTheFirstTime],
      () => {
        if (dayTagStore.isLoadedForTheFirstTime && habitStore.isLoadedForTheFirstTime && this.isLoadedForTheFirstTime) {
          if (!this.sealed) {
            habitStore.injectIntoItems(this, this.habitsFromDefs);
          }
          this.areHabitsInjectedForTheFirstTime = true; 
        }
      }
    ))

    this.toDisposeOnUnsubscribe.push(reaction(
      () => [this.eventsFromRawEvents, this.sealed, this.isLoadedForTheFirstTime],
      () => {
        if (this.isLoadedForTheFirstTime && this.eventsFromRawEvents != null) {
          if (!this.sealed) {
            eventStore.injectIntoItems(this, this.eventsFromRawEvents);
          }
          this.areEventsInjectedForTheFirstTime = true;
        }
      }
    ));

    this.toDisposeOnUnsubscribe.push(reaction(
      () => {
        return [
          this.itemsAndEndersSequence.map(itemOrEnder => [
            itemOrEnder.id,
            itemOrEnder instanceof Item && itemOrEnder.insertionTime,
            itemOrEnder instanceof Item && itemOrEnder.startsSegment,
            itemOrEnder.isAnchored,
          ]),
          this.isLoadedForTheFirstTime
        ]
      },
      () => {
        if (!this.isLoadedForTheFirstTime) {
          return;
        }

        // Fix positioning
        this.itemsAndEndersSequence.forEach((itemSeparatorOrEnder, index) => itemSeparatorOrEnder.position = index);

        // Fix enders
        {
          let inItem: Item = null;
          for (let itemSeparatorOrEnder of this.itemsAndEndersSequence) {
            if (itemSeparatorOrEnder instanceof Item) {
              if ((itemSeparatorOrEnder.startsSegment || itemSeparatorOrEnder.isAnchored) && inItem) {
                this.itemEnders.push(new ItemEnder({itemId: inItem.id, position: inItem.position + 0.2, date: this.date}));
                inItem = null;
              }

              if (itemSeparatorOrEnder.startsSegment) {
                inItem = itemSeparatorOrEnder;
              }
            } else if (itemSeparatorOrEnder instanceof ItemEnder) {
              if (inItem) {
                if (inItem.id !== itemSeparatorOrEnder.itemId) {
                  removeItemOnce(this.itemEnders, itemSeparatorOrEnder);
                }
                inItem = null;
              } else {
                removeItemOnce(this.itemEnders, itemSeparatorOrEnder);
              }
            }
          }
          
          if (inItem) {
            this.itemEnders.push(new ItemEnder({itemId: inItem.id, position: inItem.position + 0.2, date: this.date}));
          }
        }

        // Ensure that anchored items are sorted according to time
        let anchoredTime = 0;
        for (let itemOrSeparator of this.itemsAndEndersSequence) {
          if (itemOrSeparator instanceof Item && itemOrSeparator.isAnchored) {
            if (itemOrSeparator.insertionTime < anchoredTime) {
              console.log("Fixing anchored item", itemOrSeparator)
              removeItemOnce(this.items, itemOrSeparator)
              this.injectIntoItems(itemOrSeparator)

              //TODO: This does not move enclosed items, which may lead to chaos!
            } 

            anchoredTime = itemOrSeparator.time;
          }
        }

        // Ensure unique id
        assertUniqueId(this.itemsAndEndersSequence, "currentItems");

        console.log("Updated enders and positions", toExcelDate(this.date));
      }
    ));
  }

  injectIntoItems(...insertedItems: Item[]) {
    insertedItems = insertedItems.filter(insertedItem => !this.items.find(item => item.id === insertedItem.id))
    insertedItems.sort((a, b) => a.time - b.time)

    let newSequence = [];
    let i = 0;
    let inItem = false;
    
    for (let item of this.itemsAndEndersSequence) {

      while (!inItem && !(item instanceof ItemEnder) && i < insertedItems.length && insertedItems[i].time < item.time) {
        newSequence.push(insertedItems[i]);
        i++;
      }

      newSequence.push(item);

      if (item instanceof Item && item.startsSegment) {
        console.assert(inItem === false, "inItem === false", item)
        inItem = true;
      }

      if (item instanceof ItemEnder) {
        inItem = false;
      }
    }

    newSequence = [...newSequence, ...insertedItems.slice(i)];

    newSequence.forEach((itemOrSeparator, index) => itemOrSeparator.position = index);

    this.items.push(...insertedItems);
  }

  addItem(newItem: Item) {
    //This limitation is due to the fact that events are only loaded for current date, therefore runners will only be run on the current date
    console.assert(this.date.getTime() === mainStore.currentDate.getTime()) 

    return new Promise<Item>((resolve) => {
      this.runOnReady.push(() => {
        this.injectIntoItems(newItem);

        newItem.position = -1; //Move to begining
        scrollToItem(newItem);
        this.save();
        
        resolve(newItem);
        console.log("ADDED", this.items)
      });
    });
  }

  sortItems() {
    //TODO: This probably no longer work

    let oldItems = this.items;
    this.items = [];
    this.itemEnders = [];

    this.injectIntoItems(...oldItems);

    this.save();
  }

  static toFirestore(dayData:DayData) {
    let data = {
      version: DayData.VERSION,
      items: dayData.items.map(item => {return {type: item.type, ...item.toFirestore()}}),
      itemEnders: dayData.itemEnders.map(ender => ender.toFirestore()),
      lastUpdate: new Date(),
      date: toExcelDate(dayData.date),
      habitDefIds: dayData.habitDefIds,
      note: dayData.note,
      manualDayStartTime: dayData.manualDayStartTime,
      manualDayEndTime: dayData.manualDayEndTime,
      sealed: dayData.sealed,
    }

    return data;
  }
  
  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    if (!data.version) data.version = 0;

    if (!data.itemSeparators) data.itemSeparators = [];
    if (!data.itemEnders) data.itemEnders = [];

    if (data.manualDayStartTime == null) data.manualDayStartTime = null;
    if (data.manualDayEndTime == null) data.manualDayEndTime = null;

    const ITEM_CONVERTERS = {
      task: TaskItem,
      habit: HabitItem,
      event: EventItem,
    }

    if (data.version !== this.VERSION) {
      console.log("OLD VERSION", data.version, this.VERSION);
    }

    const storedItems = data.items.map((itemData: any) => {
      let obj = new ITEM_CONVERTERS[itemData.type]({id: itemData.id, date: fromSimpleDate(itemData.date)});
      obj.fromFirestore(itemData, data.version);
      return obj;
    });

    const date = fromSimpleDate(data.date);
    
    return new DayData({
      id: snapshot.ref.id,
      date: date,
      items: storedItems,
      itemEnders: data.itemEnders.map((enderData:any) => ItemEnder.fromFirestore(enderData, date)),
      lastUpdate: data.lastUpdate.toDate(),
      manualDayStartTime: data.manualDayStartTime,
      manualDayEndTime: data.manualDayEndTime,
      note: data.note,
      sealed: Boolean(data.sealed),
      checkSum: data.checkSum, //Needed for WithProtectedSaveDelay
    });
  }
}