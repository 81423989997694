import { makeAutoObservable, action } from "mobx"
import { err, logEvent } from "@habline/common/utils"
import { habitStore } from "../habits/data/HabitStore"
import { dayDataStore } from "./DayDataStore"
import { dayTagStore } from "./DayTagStore"
import packageJson from '../../package.json'
import { uiStore } from './UIStore'
import { fb, FIREBASE_CONFIG } from "@habline/common/firebase"
import firebase from 'firebase/app'
import { timeToMs } from "@habline/common/time"

declare global {
  interface Window { gapi: any; }
}

const gapi = window.gapi;

// Client ID and API key from the Developer Console
var CLIENT_ID = '106845891855-bkjepbdmt6svd6440dvk74sgqic105i5.apps.googleusercontent.com';

// Array of API discovery doc URLs for APIs used by the quickstart
var DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
];

var AUTH_SCOPES = [
  "email",
  "profile",
  "https://www.googleapis.com/auth/calendar.readonly"
];

let workspaceMonitoringUnsubscribe: (()=>void)|null = null;


export interface Workspace {
  wakeUpTime: number,
  goodNightTime: number,
  lastBuild: string,
  lastLogin: Date,

  userInfo: {
    displayName: string,
    email: string,
    photoURL: string,  
  }

  [x: string]: any 
}

class GAPIauth {
  user = null;
  workspace: Workspace = null;
  isLoading = false;
  isWorkspaceFresh = false;

  constructor() {
    makeAutoObservable(this);
  }

  saveWorkspace() {
    fb.firestore().collection("workspaces").doc(this.user.uid).set(this.workspace);
  }

  updateSigninStatus(isSignedIn) {
    if (workspaceMonitoringUnsubscribe) {
      workspaceMonitoringUnsubscribe();
      workspaceMonitoringUnsubscribe = null;
    }

    console.log("CURRENT USER", gapi.auth2.getAuthInstance().currentUser.get());

    if (isSignedIn) {
      const auth2 = gapi.auth2.getAuthInstance()
      const currentUser = auth2.currentUser.get()
      const profile = currentUser.getBasicProfile()
  
      console.log(`gapi: ${profile.getName()} signed in!`);
  
      const authResponse = currentUser.getAuthResponse(true)
      const credential = firebase.auth.GoogleAuthProvider.credential(
        authResponse.id_token,
        authResponse.access_token
      )
  
      fb.auth().signInWithCredential(credential)
      .then(({ user }) => {
        console.log(`firebase: ${profile.getName()} signed in!`);
        firebase.analytics().setUserId(user.uid);
        logEvent('login');

        fb.firestore().collection("workspaces").doc(user.uid).get()
        .then(doc => {
          console.log("New workspace", doc.exists);
          let wasJustAdded = !doc.exists;
          let workspace: Workspace = doc.exists ? doc.data() as Workspace : {wakeUpTime: timeToMs("6:30"), goodNightTime: timeToMs("22:30"), lastBuild: "?", lastLogin: new Date(), userInfo: {displayName: "?", email: "?", photoURL: "?", }}
          workspace.userInfo.displayName = user.displayName;
          workspace.userInfo.email = user.email;
          workspace.userInfo.photoURL = user.photoURL;
          workspace.lastLogin = new Date();
          workspace.lastBuild = packageJson.version;

          fb.firestore().collection("workspaces").doc(user.uid).set(workspace)
          .then(() => {
            console.log("User profile saved");

            workspaceMonitoringUnsubscribe = fb.firestore().collection("workspaces").doc(user.uid).onSnapshot(action(doc => {
              console.log("Workspace updated");
              this.user = user;
              this.isWorkspaceFresh = this.isWorkspaceFresh || wasJustAdded;
              wasJustAdded = false;
              this.workspace = doc.data() as Workspace;
              this.isLoading = false;
            }));
          })
          .catch(err);
        })
        .catch(err);
      })
    } else {
      console.log('gapi: user is not signed in')
      this.workspace = null;
      this.user = null;
      this.isLoading = false;
    }
  }

  resetEverything() {
    let deleteItems = [];
    habitStore.dbReference.get().then((doc) => {
      deleteItems.push(doc);
      dayDataStore.collection.get().then((docs) => {
        deleteItems.push(...docs.docs);
        dayTagStore.collection.get().then((docs) => {
          deleteItems.push(...docs.docs);

          console.log(`Deleting ${deleteItems.length} objects`);
          Promise.all(deleteItems.map(e => e.ref.delete())).then(() => {
            return fb.firestore().collection("workspaces").doc(this.user.uid).delete().then(() => {
              console.log("Workspace deleted");
              this.signout();
            }).catch(err);
          }).catch(err);
        }).catch(err);
      }).catch(err);
    }).catch(err);
  }

  signout() {
    console.log('signing out...')

    const auth2 = gapi.auth2.getAuthInstance()
    if (!auth2.isSignedIn.get()) {
      uiStore.toast('Not signed in!')
      return
    }

    this.isLoading = true;
    auth2.signOut()
    .then(() => { console.log('gapi: sign out complete') })
    .then(action(() => { this.workspace = null; this.user = null;  }))
    .then(() => { return fb.auth().signOut() })
    .then(() => { console.log('firebase: sign out complete') })
    .then(() => { logEvent('logout') })
  }

  signin() {
    const auth2 = gapi.auth2.getAuthInstance()
    if (auth2.isSignedIn.get()) {
      uiStore.toast('already signed in')
      return
    }

    this.isLoading = true;
    auth2.signIn().catch(error => {
      this.isLoading = false;
      if (error.error !== "popup_closed_by_user")
        err(`sign in error: ${error.error}`, error)
    })
  }

  login() {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      if (gapi) {
        gapi.load('client:auth2', () => {
          resolve(null)
        })
      } else {
        reject("No internet connection");
      }
      
    })
    .then(() => { console.log('gapi: client:auth2 loaded') })
    .then(() => {
      return gapi.client.init({
        apiKey: FIREBASE_CONFIG.apiKey,
        clientId: CLIENT_ID,
        scope: AUTH_SCOPES.join(' '),
        discoveryDocs: DISCOVERY_DOCS,
      })
    })
    .then(() => { console.log('gapi: client initialized') })
    //.then(() => { return gapi.client.load('analytics', 'v3') })
    //.then(() => { console.log('gapi: analytics v3 loaded', gapi.client.analytics)})
    .then(() => gapi.auth2.getAuthInstance())
    .then(() => { console.log('gapi: auth2 instance initialized') })
    .then(() => {
      const auth2 = gapi.auth2.getAuthInstance()
      auth2.isSignedIn.listen((isSignedIn) => this.updateSigninStatus(isSignedIn))
      this.updateSigninStatus(auth2.isSignedIn.get())
    })
    .catch((error) => {
      console.log(error);
      uiStore.toast(JSON.stringify(error, null, 2));
    });
  }
}

export const gapiAuth = new GAPIauth();