import { makeAutoObservable } from 'mobx';
import { HabitDefSource } from './HabitDefSource';
import { HabitDef } from './HabitDef';
import { gapiAuth } from '../../data/gapi';
export class TimeAnchor {
  before: HabitDef[] = [];
  after: HabitDef[] = [];

  constructor(public source: HabitDefSource, public time: number) {
    makeAutoObservable(this)
  }

  get usedTime(): number {
    let usedTime = this.after.map((item: HabitDef) => item.duration).reduce((a: number, b: number) => a + b, 0);
    
    let anchorIndex = this.source.anchors.findIndex(anchor => anchor.time === this.time)
    console.assert(anchorIndex >= 0)
    if (anchorIndex + 1 < this.source.anchors.length) {
      usedTime += this.source.anchors[anchorIndex + 1].before.map((item: HabitDef) => item.duration).reduce((a: number, b: number) => a + b, 0)
    }

    return usedTime;
  }

  get totalTime(): number {
    let anchorIndex = this.source.anchors.findIndex(anchor => anchor.time === this.time)
    
    console.assert(anchorIndex >= 0)

    if (anchorIndex + 1 < this.source.anchors.length) {
      return this.source.anchors[anchorIndex + 1].time - this.time
    }

    return 0;
  }

  correctPositions() {
    this.before.forEach((item, index) => item.positionRelativeToAnchor = - this.before.length + index)
    this.after.forEach((item, index) => item.positionRelativeToAnchor = index + 1)
  }
}

export function correctAnchors(store: HabitDefSource) {
  for (let anchor of store.anchors) {
    anchor.correctPositions();
  }

  for (let item of store.timeItems.filter(item => item.timeAnchor == null)) {
    console.log("PLACING", item, store.anchors)
    let inserted = false;


    for (let i = 0; i + 1 < store.anchors.length; i++) {
      if (store.anchors[i].time <= item.timeBasedOnAnchor && item.timeBasedOnAnchor <= store.anchors[i + 1].time) {
        let span = store.anchors[i+1].time - store.anchors[i].time;
        let addToBegining = item.timeBasedOnAnchor - store.anchors[i].time <= span / 2;

        if (addToBegining) {
          item.timeAnchor = store.anchors[i].time;
          item.positionRelativeToAnchor = Number.MAX_SAFE_INTEGER;
          
          let currentStartTime = store.anchors[i].time;
          for (let j = 0; j < store.anchors[i].after.length; j++) {
            if (currentStartTime >= item.timeBasedOnAnchor) {
              item.positionRelativeToAnchor = store.anchors[i].after[j].positionRelativeToAnchor + 0.5
              break;
            }
            
            currentStartTime += store.anchors[i].after[j].duration;
          }
        } else {
          item.timeAnchor = store.anchors[i+1].time;
          item.positionRelativeToAnchor = Number.MIN_SAFE_INTEGER;
          
          let currentStartTime = store.anchors[i+1].time;
          for (let j = store.anchors[i+1].before.length - 1; j >= 0; j--) {
            if (currentStartTime <= item.timeBasedOnAnchor) {
              item.positionRelativeToAnchor = store.anchors[i+1].before[j].positionRelativeToAnchor - 0.5
              break;
            }
            
            currentStartTime -= store.anchors[i+1].before[j].duration;
          }
        }

        inserted = true;
        break;
      }
    }

    if (!inserted) {
      console.log("PLACE NOT FOUND FOR", item, store.anchors)
      item.timeAnchor = store.anchors[0].time;
      item.positionRelativeToAnchor = Number.MAX_SAFE_INTEGER;
    }
  }

  

  for (let anchor of store.anchors) {
    anchor.correctPositions();
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function basicAnchors(source: HabitDefSource, isCurrentTimeSensitive: boolean) {
  let anchors: Map<number, TimeAnchor> = new Map<number, TimeAnchor>();

  function thereIsAnchorBetween(time1: number, time2: number) {
    for (let time of anchors.keys()) {
      if (time1 < time && time < time2) {
        return true;
      }
    }

    return false;
  }

  // identify anchors

  for (let anchor of source.customAnchors) {
    anchors.set(anchor.time, anchor);
  }
  
  for (let item of source.timeItems) {
    if (item.timeAnchor != null && !anchors.has(item.timeAnchor)) {
      anchors.set(item.timeAnchor, new TimeAnchor(source, item.timeAnchor));
    }
  }

  for (let item of source.timeItems) {
    if (item.timeAnchor != null && false && !anchors.has(item.timeAnchor + item.duration) && !thereIsAnchorBetween(item.timeAnchor, item.timeAnchor + item.duration)) {
      anchors.set(item.timeAnchor + item.duration, new TimeAnchor(source, item.timeAnchor + item.duration));
    }
  }

  if (anchors.size < 2) {
    anchors.set(gapiAuth.workspace.wakeUpTime, new TimeAnchor(source, gapiAuth.workspace.wakeUpTime));
  }

  if (anchors.size < 2) {
    anchors.set(gapiAuth.workspace.goodNightTime, new TimeAnchor(source, gapiAuth.workspace.goodNightTime));
  }

  // fill before and after

  for (let item of source.timeItems) {
    if (item.timeAnchor != null) {
      if (item.positionRelativeToAnchor < 0) {
        anchors.get(item.timeAnchor).before.push(item);
      } else {
        anchors.get(item.timeAnchor).after.push(item);
      }
    }
  }

  for (let anchor of anchors.values()) {
    anchor.before = anchor.before.slice().sort((a, b) => a.positionRelativeToAnchor - b.positionRelativeToAnchor);
    anchor.after = anchor.after.slice().sort((a, b) => a.positionRelativeToAnchor - b.positionRelativeToAnchor);
  }

  return Array.from(anchors.values()).sort((a, b) => a.time - b.time)
}

export function timeBasedOnAnchor(item: HabitDef, timeItemAndAnchorSource: HabitDefSource):number {
  let anchor = timeItemAndAnchorSource.anchors.find(anchor => anchor.time === item.timeAnchor)

  if (anchor && item.positionRelativeToAnchor > 0) {
    let currentTime = anchor.time;
    for (let otherItem of anchor.after) {
      if (otherItem.id === item.id) {
        return currentTime;
      }
      currentTime += 1;
    }
  }

  if (anchor && item.positionRelativeToAnchor < 0) {
    let currentTime = anchor.time;
    for (let otherItem of anchor.before.slice().reverse()) {
      currentTime -= 1;
      if (otherItem.id === item.id) {
        return currentTime;
      }
    }
  }

  return 0;
}