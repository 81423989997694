import { makeObservable, observable, computed, action } from "mobx"
import { CopyPasteOp, Item, ItemConstructorParameters } from "../../data/Item";
import { datePlusDays } from "@habline/common/utils";
import { TaskItem } from "../../data/TaskItem";
import { timeToMs } from "@habline/common/time";

export const DEFAULT_EVENT_PRIORITY = 75;

function stripHTML(str: string) {
  const regex = /(<([^>]+)>)/ig;
  return str.replace(regex, '');
}

interface RawEventType {
  id: string,
  summary: string,
  description: string,
  start: {
    dateTime?: string,
    date?: string,
    timeZone?: string
  },
  end: {
    dateTime?: string,
    date?: string,
    timeZone?: string
  }
}

interface EventItemConstructorParameters extends ItemConstructorParameters {
  rawEvent?: RawEventType
}

export class EventItem extends Item {
  rawEvent: RawEventType;

  constructor({rawEvent = null, ...args }: EventItemConstructorParameters) {
    super(args);
    this.rawEvent = rawEvent;

    makeObservable(this, {
      rawEvent: observable,
      autoCategory: computed,
      canOverrideCategory: computed,
      canOverrideDuration: computed,

      eventStartDate: computed,
      eventEndDate: computed,
      shouldOnlyAddAsReminder: computed,

      toFirestore: action,
      instanceForCopy: action,
      fromFirestore: action,
      performItemTypeUpdateFromFirebase: action,
    });
  }

  get type() { return "event"; }
  get autoCategory() {return "🗓️"; }
  get canOverrideCategory() { return true }
  get canOverrideDuration() { return true } //TODO: This should be based on calendar settings
  get autoDescription() { return stripHTML(this.rawEvent && this.rawEvent.description ? this.rawEvent.description : "") }
  get autoName() { return this.rawEvent ? this.rawEvent.summary : "???" }
  get autoIsAnchored() { return !this.shouldOnlyAddAsReminder}
  get autoPriority() { return DEFAULT_EVENT_PRIORITY; }

  get eventStartDate() {
    if (this.rawEvent && this.rawEvent.start.dateTime) {
      let eventStartDate = new Date(this.rawEvent.start.dateTime);
      eventStartDate = eventStartDate.getTime() > this.date.getTime() ? eventStartDate : this.date;
      return eventStartDate;
    }

    if (this.rawEvent && this.rawEvent.start.date) {
      let eventStartDate = new Date(this.rawEvent.start.date);
      eventStartDate = eventStartDate.getTime() > this.date.getTime() ? eventStartDate : this.date;
      return eventStartDate;
    }

    return this.date;
  }

  get eventEndDate() {
    const dayAfter = datePlusDays(this.date, 1);

    if (this.rawEvent && this.rawEvent.end.dateTime) {
      let eventEndDate = new Date(this.rawEvent.end.dateTime);
      eventEndDate = eventEndDate.getTime() < dayAfter.getTime() ? eventEndDate : dayAfter;
      return eventEndDate;
    }

    if (this.rawEvent && this.rawEvent.end.date) {
      let eventEndDate = new Date(this.rawEvent.end.date);
      eventEndDate = eventEndDate.getTime() < dayAfter.getTime() ? eventEndDate : dayAfter;
      return eventEndDate;
    }

    return dayAfter;
  }

  get shouldOnlyAddAsReminder() {
    // if it touches midnight or has "reminder" in it, only add it as a reminder
    return this.eventStartDate.getTime() - this.date.getTime() <= 0 ||
           this.eventEndDate.getTime() - this.date.getTime() >= timeToMs("24:00") ||
           this.autoName.toLowerCase().indexOf("reminder") !== -1
  }

  get autoInsertionTime() {
    let timeInMs = 0;

    if (this.eventStartDate.getTime() > this.date.getTime()) {
      timeInMs = this.eventStartDate.getTime() - this.date.getTime();
    }

    return timeInMs;
  }

  get autoDuration() {
    if (this.shouldOnlyAddAsReminder) {
      return 0;
    }

    return this.eventEndDate.getTime() - this.eventStartDate.getTime();
  }

  toFirestore() {
    return {
      rawEvent: this.rawEvent,
      ...super.toFirestore()
    };
  }

  instanceForCopy(id: string, date: Date) {
    return new TaskItem({id, date});
  }

  performItemTypeSpecificCopyOps(item: Item, type: CopyPasteOp) {
    //rawEventId is intentionally ommited in case of an event, For events that are converted to tasks, we are losing some information here, is that ok?
  }

  fromFirestore(data: any, version: number) {
    super.fromFirestore(data, version);
    this.rawEvent = data.rawEvent;

    if (this.rawEvent == null) {
      this.rawEvent = {
        id: data.rawEventId,
        summary: data.name,
        description: data.description,
        start: {
          dateTime: "2020-09-18T08:00:00+02:00",
          timeZone: "Europe/Paris"
        },
        end: {
          dateTime: "2020-09-18T08:00:00+02:00",
          timeZone: "Europe/Paris"
        }
      }
    }
  }

  performItemTypeUpdateFromFirebase(item: Item): void {
    this.rawEvent = (item as EventItem).rawEvent
  }
}
