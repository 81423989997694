import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

const ButtonDOM = styled.div<{position: "left"|"right"}>`
  position:fixed;
  ${({position}) => position === "right" && css`
      right:20px;
  `}
  ${({position}) => position === "left" && css`
      left:20px;
  `}
  bottom:10px;
  height: 56px;
  width: 56px;
  background-color: ${({theme}) => theme.backgroundLevel1.toString()};
  color: ${({theme}) => theme.backgroundLevel4.toString()};
  border-radius: 56px;
  box-shadow:0px 4px 8px rgba(0, 0, 0, 0.5), -1px -1px 3px rgba(0, 0, 0, 0.1) ;
  border: 0px;
  padding: 0px;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

//outline: none;

const BigButtonDOM = styled.div<{position: "left"|"right"}>`
  position:fixed;
  ${({position}) => position === "right" && css`
      right:20px;
  `}
  ${({position}) => position === "left" && css`
      left:20px;
  `}
  bottom: 10px;
  height: 56px;
  width: calc(100% - 120px);
  background-color: #f6f5ff;
  color: #705BD6;
  border-radius: 56px;
  box-shadow:0px 4px 8px rgba(0, 0, 0, 0.5), -1px -1px 3px rgba(0, 0, 0, 0.1) ;
  border: 0px;
  padding: 0px;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

export const HoverButton: FunctionComponent<{position: "left"|"right", onClick: ()=>void}> = ({ onClick, position = "right", children }) => {
  return <ButtonDOM onClick={onClick} position={position}>
    {children}
  </ButtonDOM>;
}

export const BigHoverButton: FunctionComponent<{position: "left"|"right", onClick: ()=>void}> = ({ onClick, position = "right", children }) => {
  return <BigButtonDOM onClick={onClick} position={position}>
    {children}
  </BigButtonDOM>;
}
