import { datePlusDays, daysBetween, today } from "@habline/common/utils.common";
import { dayTagStore } from "./DayTagStore";
import seedrandom from "@habline/common/seedrandom";

export function isOtherWeek(date: Date) {
  let days = daysBetween(new Date(2000, 0, 1), date) + [6,0,1,2,3,4,5][new Date(2000, 0, 1).getDay()]
  return Math.floor(days / 7) % 2 === 1;
}

export enum SegmentType {
  EVERY = "EVERY",
  EVERY_OTHER = "EVERY_OTHER",
  EXCEPT = "EXCEPT",
  EXCEPT_OTHER = "EXCEPT_OTHER",
}

export interface DaySet {
  isInThisSet(date: Date): boolean;
}

export class AnyDaySet implements DaySet {
  isInThisSet(date: Date) {
    return true;
  }
}

export class WeekDaySet implements DaySet {
  isInThisSet(date: Date) {
    return date.getDay() > 0 && date.getDay() < 6;
  }
}

export class DayOfWeekSet implements DaySet {

  constructor (public dayOfWeek: number) {}

  isInThisSet(date: Date) {
    return date.getDay() === this.dayOfWeek;
  }
}

export class MonthReference implements DaySet {
  constructor(public nth: number, public dayOfWeek: null|number, public monthNum: null|number) {};

  isInThisSet(date: Date) {
    if (this.monthNum != null) {
      if (date.getMonth() + 1 !== this.monthNum) return false;
    }

    if (this.dayOfWeek != null) {
      if (this.dayOfWeek !== date.getDay()) return false;

      if (this.nth > 0) {
        let startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        let skipDays = this.dayOfWeek - startOfMonth.getDay();
        if (skipDays < 0) skipDays += 7;
        let firstInterestingWeekDay = datePlusDays(startOfMonth, skipDays);
        let weekDiff = daysBetween(firstInterestingWeekDay, date) / 7;
        if (weekDiff + 1 !== this.nth) return false;
      } else {
        let endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        let skipDays = endOfMonth.getDay() - this.dayOfWeek;
        if (skipDays < 0) skipDays += 7;
        let lastInterestingWeekDay = datePlusDays(endOfMonth, -skipDays);
        let weekDiff = daysBetween(date, lastInterestingWeekDay) / 7;
        if (weekDiff + 1 !== -this.nth) return false;
      }
    } else {
      let numOfDays = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
      let nth = this.nth > 0 ? this.nth : numOfDays + this.nth;

      if (date.getDate() !== nth) return false;
    }

    return true;
  }
}

export class TagReference implements DaySet {
  constructor(public id: string, public positive: boolean, public sequenceRef: number = 0) {};

  isInThisSetIgnoreSequence(date: Date) {
    if (!dayTagStore.dayTagMapping[this.id]) { console.warn(`#${this.id} not found`); return false; } //TODO: throw new Error(`#${this.id} not found`)}

    if (dayTagStore.dayTagMapping[this.id].isInThisSet(date))
      return this.positive;
    else
      return !this.positive;
  }

  isInThisSet(date: Date) {
    if (this.sequenceRef) {
      let mult = this.sequenceRef > 0 ? -1 : 1;
      let count = Math.abs(this.sequenceRef);

      if (this.isInThisSetIgnoreSequence(datePlusDays(date, mult * count))) {
        return false;
      }

      for (let i = 0; i < count; i++) {
        if (!this.isInThisSetIgnoreSequence(datePlusDays(date, mult * i))) {
          return false;
        }
      }
    }

    return this.isInThisSetIgnoreSequence(date);
  }
}

export class Segment {
  seed: string;
  
  constructor(public ratio: number, public type: SegmentType, public compontents: DaySet[], public intersectionComponents:  null | (DaySet[])) {}

  setSeed(seed) {
    this.seed = seed;
  }

  checkComponents(date: Date) {
    let found = false;
    for (let compontent of this.compontents)  {
      if (compontent.isInThisSet(date)) {
        found = true; break;
      }
    }

    let acceptedByIntersection = true;
    if (found && this.intersectionComponents) {
      for (let compontent of this.intersectionComponents)  {
        if (!compontent.isInThisSet(date)) {
          acceptedByIntersection = false;
        }
      }
    }

    if (found && acceptedByIntersection && this.ratio < 1) {
      let rng = new seedrandom(this.seed);
      let refDate = today();
      refDate.setFullYear(1980, 0, 1)
      let days = daysBetween(refDate, date) % 600; 
      for (let i = 0; i < days; i++) {
        rng();
      }
      let result = rng() < this.ratio;
      //console.log(`RATIO result=${result} ratio=${this.ratio} days=${days} seed=${this.seed} refDate=${refDate} date=${date}`)
      return result;
    } else {
      return found && acceptedByIntersection;
    }
  }
}

export class DateExpression implements DaySet {

  constructor(public segments: Segment[]) {}

  setSeed(seed) {
    this.segments.forEach(s => s.setSeed(seed))
  }

  isSimpleWeeklySchedule() {
    if (this.segments.some(s => s.ratio < 1)) return false;

    if (this.segments.length === 0) return true;
    if (this.segments.length !== 1) return false;

    let segment = this.segments[0];

    if (segment.type !== SegmentType.EVERY) return false;

    if (segment.intersectionComponents != null) return false;

    for (let component of segment.compontents) {
      if (!(component instanceof AnyDaySet || component instanceof WeekDaySet || component instanceof DayOfWeekSet)) {
        return false;
      }
    }

    return true;
  }


  isInThisSet(date: Date): boolean {
    let isInSet = false;

    for (let segment of this.segments) {
      if (segment.type === SegmentType.EVERY) {
        if (segment.checkComponents(date)) {
          isInSet = true;
        }
      } else if (segment.type === SegmentType.EVERY_OTHER) {
        if (isOtherWeek(date)) {
          if (segment.checkComponents(date)) {
            isInSet = true;
          }
        }
      } else if (segment.type === SegmentType.EXCEPT) {
        if (segment.checkComponents(date)) {
          isInSet = false;
        }
      } else if (segment.type === SegmentType.EXCEPT_OTHER) {
        if (isOtherWeek(date)) {
          if (segment.checkComponents(date)) {
            isInSet = false;
          }
        }
      } 
    }

    return isInSet;
  }
}