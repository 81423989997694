import React from "react";
import { Status } from "@habline/common/Status"
import styled, {css, DefaultTheme} from 'styled-components';
import { observer } from 'mobx-react-lite';
import { uiStore } from '../data/UIStore';
import { ItemContextMenu } from "./context/ItemContextMenu";
import { Item, ItemPriority } from "../data/Item";
import { humanReadableDuration, msToTime } from "@habline/common/time";
import { describeDate } from "@habline/common/MainStore";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { openContextMenu } from "./context/ItemActionsContextMenu";
import { itemStore } from "../data/ItemStore";
import { LinearColorInterpolator } from "./styles/LinearColorInterpolator";
import { SegmentStartView } from "./SegmentStartView";
import { ItemEnder } from "../data/ItemEnder";

export function describeStatus(item: Item, isInContextOfADay: boolean) {
  if (isInContextOfADay) {
    let sameCompletionDate = item.completionDate.getTime() === item.date.getTime();
    let time = msToTime(item.completionTime);
    if (item.isInIcebox) {
      if (item.isACopy) {
        return `Copied${sameCompletionDate ? ` at ${time}` : ``}`
      } else {
        return `Postponed${sameCompletionDate ? ` at ${time}` : ``}`
      }
    } else {
      if (item.isUnknown) return `Scheduled for ${item.isAnchored ? "" : "~"}${msToTime(item.time)}`
      if (item.status === Status.SUCCESS) return `Successful${sameCompletionDate ? ` at ${time}` : ``}`
      if (item.status === Status.PARTIAL) return `Partially successful${sameCompletionDate ? ` at ${time}` : ``}`
      if (item.isMissed) return `Skipped${sameCompletionDate ? ` at ${time}` : ``}`
    }
  } else {
    let d = describeDate(item.date);

    if (item.isInIcebox) {
      if (item.isACopy) {
        return `Copied ${d}`
      } else {
        return `Postponed ${d}`
      }
    } else {
      if (item.isUnknown) return `Scheduled for ${d} at ${item.isAnchored ? "" : "~"}${msToTime(item.time)}`
      if (item.status === Status.SUCCESS) return `Successful ${d}`
      if (item.status === Status.PARTIAL) return `Partially successful ${d}`
      if (item.isMissed) return `Skipped ${d}`
    }
  }

  return `?`
}

export const DailyItemView = observer(({item, clickBlock, inItem, isBeingSubDragged, provided, snapshot, subDraggingIdsCount}: {item: Item, clickBlock: boolean, inItem?: Item, isBeingSubDragged?: boolean, provided: DraggableProvided, snapshot: DraggableStateSnapshot, subDraggingIdsCount?: number}) => {
  let onClick = (event) => {
    if (clickBlock) {
      return;
    }

    if (event.shiftKey) {
      if (itemStore.isInSelection(item)) {
        itemStore.removeFromSelection(item)
      } else {
        itemStore.addToSelection(item)
      }
    } else {
      if (!item.isPartOfPlan) {
        item.setIsPartOfPlan(true);
      } else {
        openContextMenu(itemStore.isInSelection(item) ? itemStore.selectedItems : [item], false)
      }
    }
  }

  if (item.startsSegment) {
    return <SegmentStartView
      isVisible={true}
      segment={item.segmentThatThisStarts}
      segmentStartTime={item.time}
      setSegmentStartTime={(time) => {
        item.setInsertionTime(time);
      }}
      provided={provided}
      snapshot={snapshot}
      isBeingSubDragged={isBeingSubDragged}
      onClick={onClick}
    />
  } else {
    return <ItemContainer
      name={item.id}
      isPartOfPlan={item.isPartOfPlan}
      isNew={item.isNew}
      isSelected={itemStore.isInSelection(item)}
      isHighlighted={item.isHighlighted}
      priority={item.priority}
      pointWeight={item.pointWeight}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      isDragging={snapshot.isDragging}
      isDoable={item.isPartOfPlan}
      hasError={item.hasError}
      onClick={onClick}
      isBeingSubDragged={isBeingSubDragged}
    >
      <ItemInfoContainer>
        {item.startsSegment ? <IconContainer important={true}>{msToTime(item.time)}<DescriptionSmallLine>{humanReadableDuration(item.duration)}</DescriptionSmallLine></IconContainer> : <IconContainer>{item.category} <DescriptionSmallLine>&nbsp;</DescriptionSmallLine></IconContainer>}
        <Description>
          <DescriptionBigLine
            important={false}
            lineThrough={item.status === Status.MISSED && !item.isInIcebox}
          >
            {item.name} {subDraggingIdsCount > 0 ? `(+${subDraggingIdsCount})` : ``}
          </DescriptionBigLine>
          <DescriptionSmallLine>{humanReadableDuration(item.duration)}</DescriptionSmallLine>
        </Description>
      </ItemInfoContainer>
    </ItemContainer>
  }  
})

export const HistoricDailyItemView = observer<{item: Item, isMultiDay:boolean}>(({item, isMultiDay}) => {
  let icon = item.category;

  return (
    <ItemContainer
      isSkipped={item.isMissed}
      isPartOfPlan={item.status === Status.PARTIAL || item.status === Status.SUCCESS}
      pointWeight={item.pointWeight}
      priority={item.priority}
      isSelected={itemStore.isInSelection(item)}
      isDoable={true}
      onClick={() => {openContextMenu([item], false)}}
    >
      <ItemInfoContainer>
        <IconContainer>{icon}</IconContainer>
        <Description>
          <DescriptionBigLine lineThrough={item.status === Status.MISSED && !item.isInIcebox}>{ item.name }</DescriptionBigLine>
          <DescriptionSmallLine>{describeStatus(item, !isMultiDay)}</DescriptionSmallLine>
          { item.insightNote && <DescriptionSmallLine>{ item.insightNote }</DescriptionSmallLine> }
        </Description>
      </ItemInfoContainer>
    </ItemContainer>
  )
});

export const IceboxDailyItemView = observer<{item: Item, addingToIcebox: boolean}>(({item, addingToIcebox}) => {
  let icon = item.category;
  
  return (
    <ItemContainer
      isPartOfPlan={true}
      pointWeight={item.pointWeight}
      priority={item.priority}
      isSelected={itemStore.isInSelection(item)}
      isDoable={true}
      onClick={() => {uiStore.showContextMenu(<ItemContextMenu items={[item]} inIcebox={true} addingToIcebox={addingToIcebox} />)}}
    >
      <ItemInfoContainer>
        <IconContainer>{icon}</IconContainer>
        <Description>
          <DescriptionBigLine lineThrough={item.status === Status.MISSED && !item.isInIcebox}>{ item.name }</DescriptionBigLine>
          <DescriptionSmallLine>{ describeStatus(item, false) }</DescriptionSmallLine>
        </Description>
      </ItemInfoContainer>
    </ItemContainer>
  )
});


export const ButtonIcon = styled.i`
  display: inline-block;
  --ggs: 0.80;
  top: 6px;
`

export const IconContainer = styled.div<{important?: boolean}>`
  width: 50px;
  height: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 30px;
  font-weight: ${({important}) => important ? "bold" : "normal"};
`;

export const Description = styled.div`
  width: calc(100% - 50px - 15px);
  padding-right: 15px;
`;

export const DescriptionBigLine = styled.div<{lineThrough: boolean, important?: boolean}>`
  text-overflow: ellipsis;
  text-decoration: ${({lineThrough}) => lineThrough ? "line-through" : "none"};
  overflow: hidden;
  white-space: nowrap;
  line-height: 30px;
  font-weight: ${({important}) => important ? "bold" : "inherit"};
`;

export const DescriptionSmallLine = styled.div<{important?: boolean}>`
  font-size: 80%;
  font-weight: ${({important}) => important ? "bold" : "inherit"};
  padding-bottom: 0em;
  display:block;
  line-height: normal;
  min-height: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export function backgroundForItem(priority: ItemPriority, isDragging: boolean, theme: DefaultTheme) {
  if (isDragging) priority = 125;

  priority -= 25;

  if (priority < 0) priority = 0;

  return LinearColorInterpolator.findColorBetween(theme.backgroundLevel0, theme.backgroundLevel2, priority).toString();
}

export const ItemContainer = styled.div<{name?: string, isPartOfPlan?: boolean, isMarginal?: boolean, pointWeight: number, priority: ItemPriority, isDoable: boolean, isBeingSubDragged?: boolean, isSkipped?: boolean, isDragging?: boolean, isNew?:boolean, isHighlighted?:boolean, isSelected: boolean, hasError?: boolean}>` //name is only here so it can be a target of scroolTo
  display:flex;
  flex-direction:column;
  text-align: left;
  background-color: ${({priority, isDragging, isSelected, theme}) => backgroundForItem(priority, isDragging || isSelected, theme)};
  
  ${({isNew}) => isNew && css`
    animation-duration: 1.0s;
    animation-name: slidein;
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
  `};

  ${({isHighlighted}) => (isHighlighted) && css`
    animation-duration: 2.0s;
    animation-name: highlight;
    animation-iteration-count: infinite;
  `};

  opacity: ${({isBeingSubDragged, isSkipped, isPartOfPlan}) => isBeingSubDragged || (!isPartOfPlan) ? 0.3 : (isSkipped ? 0.75 : 1.0)};

  box-shadow: ${props => {
    if (props.isDragging) return '2px 2px 3px 2px rgba(0,0,0,0.3)'
    return 0;
  }};

  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

	touch-action: manipulation;
  white-space: nowrap;
  overflow: hidden;
  padding-top: ${({isPartOfPlan, isMarginal}) => isPartOfPlan && !isMarginal ? `0.25em` : 0};
  padding-bottom: ${({isPartOfPlan, isMarginal}) => isPartOfPlan && !isMarginal ? `0.75em` : 0};
  color: ${({isDoable, theme, hasError}) => hasError ? theme.red.toString() : (isDoable ? theme.textLevel0 : theme.textLevel2)};

  max-height: ${({isMarginal, isPartOfPlan}) => isMarginal ? 1 : (isPartOfPlan ? 100 : 32)}px;

  transition: background-color .2s, min-height 1s, max-height 1s, opacity 0.5s, padding-top 1s, padding-bottom 1s; 

  ${({priority, theme}) => (priority === 100) && css`
    background-size: 300% 300%;
    background-image: linear-gradient(
          90deg, 
          ${theme.backgroundLevel2.s} 0%,
          ${theme.backgroundLevel3.s} 100%
    );  
    animation: AnimateBG 2s ease infinite;

  `};
`;

export const ItemInfoContainer = styled.div`
  display:flex;
  flex-grow: 1;
`;

export const EnderView = observer(({ender, inItem, isBeingSubDragged, provided, snapshot}: {ender: ItemEnder, inItem: Item, isBeingSubDragged?: boolean, provided: DraggableProvided, snapshot: DraggableStateSnapshot}) => 
  <SegmentStartView
    isVisible={true}
    segment={ender.segmentThatThisStarts}  
    segmentStartTime={ender.time}
    setSegmentStartTime={(time) => {
      let duration = time - inItem.time;
      if (duration >= 0) {
        inItem.setDuration(duration)
      }
    }}
    provided={provided}
    snapshot={snapshot}
    isBeingSubDragged={isBeingSubDragged}
  />
)
