import React from 'react';
import { GSLoginInfo } from './glogininfo';
import { gapiAuth } from "../data/gapi"
import { observer } from 'mobx-react-lite';
import { DateMode, mainStore } from '@habline/common/MainStore';
import { DateHeader } from './DateHeader';
import { uiStore } from '../data/UIStore';
import { loadingStore } from '../data/loading';
import { DayView } from './DayView';
import { WeekView } from './WeekView';
import { MonthView } from './MonthView';
import { HoverButton } from './HoverButton';
import { copyPasteStore } from './CopyPasteStore';
import { SettingsView } from './preferences/SettingsView';
import { logEvent } from '@habline/common/utils';

function handleTouchEnd(event) {
    if (document.scrollingElement.scrollTop < -50 && !uiStore.isContextMenuVisible) {
      gapiAuth.login();
    }
  }
  
export const CoreView = observer((props) => {
  React.useEffect(() => {
    window.addEventListener('touchend', handleTouchEnd);

    return function() {
      window.removeEventListener('touchend', handleTouchEnd);
    }
  }, []);

  let view:any = "";

  if (loadingStore.isLoading) {
    
  } else if (gapiAuth.user) {
    if (mainStore.dateMode === DateMode.DAY) view = <DayView />;
    if (mainStore.dateMode === DateMode.WEEK) view = <WeekView />;
    if (mainStore.dateMode === DateMode.MONTH) view = <MonthView />;
  } else {
    view = <GSLoginInfo />
  }

  return <div>
    <DateHeader />
    {view}
    {!copyPasteStore.isReusing && gapiAuth.user ? <HoverButton onClick={() => {logEvent('open_settings'); uiStore.push(<SettingsView />)}} position="left"><i className="gg-menu"></i></HoverButton> : ""}
    {copyPasteStore.isReusing && gapiAuth.user ? <HoverButton onClick={() => copyPasteStore.clear()} position="left"><i className="gg-close"></i></HoverButton> : ""}
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
  </div>
});