import { mainStore } from '@habline/common/MainStore';
import { makeAutoObservable } from "mobx";
import { logEvent } from "@habline/common/utils";
import { CopyPasteOp, Item } from "../data/Item";

export class CopyPasteStore {
  item: Item = null;
  type: CopyPasteOp = null;

  get description() {
    switch (this.type) {
      case CopyPasteOp.MOVE: return `Move here`;
      case CopyPasteOp.COPY: return `Paste here`;
      case CopyPasteOp.ADD_FROM_ICEBOX: return `Add here`;
      default: throw Error(`Uknown type ${this.type}`);
    }
  }

  get isReusing() {
    return this.item !== null;
  }

  reuse(item, type) {
    logEvent('reuse_start');
    this.item = item;
    this.type = type;
  }

  execute() {
    logEvent('reuse_execute');
    this.item.performCopy(mainStore.currentDate, this.type);
    this.item = null;
    this.type = null;
  }


  clear() {
    logEvent('reuse_cancel'); 
    this.item = null;
    this.type = null;
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export const copyPasteStore = new CopyPasteStore();