import React, { FunctionComponent, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { DayLevelScreen, Divider } from "../../components/styles/components"
import styled from 'styled-components'
import { uiStore } from '../../data/UIStore'
import { err, isEmpty, logEvent } from '@habline/common/utils'
import { stripIconFromName } from '@habline/common/emojis'
import { CheckBox, PropertySection, TextEditButton } from "../../components/preferences/SettingsView"
import { habitStore } from "../data/HabitStore"
import { HabitDef } from "../data/HabitDef"
import { SaveButton } from "../../components/preferences/DayTagEditor"
import { Help } from '../../components/styles/components';
import { msToDuration, msToTime, timeToMs } from "@habline/common/time";
import { TextEditor } from "../../components/preferences/TextEditor"
import { ContextMenuRow, ContextMenuOption } from '../../components/context/ContextMenu'
import { CategoryEditor } from '../../components/preferences/CategoryEditor'
import { DurationEditor } from '../../components/preferences/DurationEditor'
import { TimeSpecLabel, TimeSpecButton, DateExpressionEditor, TimeSpecButtons } from '../../components/preferences/DateExpressionEditor'
import { action } from 'mobx'
import { HabitDefList, HabitsDefListItem } from './HabitDefList'
import { ItemStatsView } from '../../components/ItemStatsView'
import { Center } from '@habline/common/primitives'
import { RangeEditor } from '../../components/context/RangeEditor'

export const HabitsDefView: FunctionComponent<{habitDef:HabitDef}> = observer(
  ({habitDef}) => {
    const [hasItemsOn, setHasItemsOn] = React.useState<Date|null>(null);

    useEffect(() => {
      habitDef.requestCheckForExistance().then(date => {
        console.log("Habit exists on ", date);
        setHasItemsOn(date);
      }).catch(err);
    }, [habitDef]);
    
    return <DayLevelScreen title="Edit a habit" onBack={() => uiStore.pop()} onSaveText="Save">
      <ContextMenuRow style={{"marginTop": "1rem"}}>
        <ContextMenuOption onClick={() => uiStore.push(<CategoryEditor value={habitDef.category} valueSetter={(value) => { habitDef.category = value; habitDef.save()} }/>)}>{habitDef.category}</ContextMenuOption>
        <ContextMenuOption onClick={() => uiStore.push(<TextEditor label="Name" help="Used everywhere, keep it short and discriptive, if it starts with an emoji, it becomes the icon of this habit" placeholder="eg. Read 3 pages of a book in the morning" value={habitDef.name} error={value => value.length === 0 ? `Please enter a name of the habit` : ''} valueSetter={(value) => { habitDef.name = stripIconFromName(value); habitDef.save() }}/>)}>{!isEmpty(habitDef.name) ? habitDef.name : "Name ..."}</ContextMenuOption>
      </ContextMenuRow>

      <TextEditButton title="Description" placeholder="eg. Complete only if done before 10am, partial otherwise." value={habitDef.description} valueSetter={(value) => { habitDef.description = value; habitDef.save() }} area={true} >{!isEmpty(habitDef.description) ? habitDef.description : "Description"}</TextEditButton>
      <Divider />

      <DateExpressionEditor autoExpression={habitDef.autoExpression} onExpressionChanged={action((value: string) => { habitDef.autoExpression = value; habitDef.save() }) } />

      <TimeSpecButtons>
        <TimeSpecLabel>At</TimeSpecLabel>
        ~ {msToTime(habitDef.timeBasedOnAnchor)}
        <TimeSpecLabel>For</TimeSpecLabel>
        <TimeSpecButton enabled={true} onClick={() => uiStore.push(<DurationEditor value={habitDef.duration} valueSetter={(value) => { habitDef.duration = value; habitDef.save() }}/>)
      }>{msToDuration(habitDef.duration)}</TimeSpecButton></TimeSpecButtons>

      <Divider />

      <PropertySection
        label="Priority"
        onClick={() => uiStore.showContextMenu(<RangeEditor
          label="Priority"
          value={habitDef.priority}
          valueSetter={(value) => {habitDef.priority = value; habitDef.save()}}
          canBeReset={false}
        />)}
        hasValue={true}
        help={"Used to prioritize items when there is too litle time to do everything"}
      >
        {habitDef.priority}
      </PropertySection>

      <Divider />
      <CheckBox label="Can be done at a later date?" help="When checked, your default action is to postpone a habit to a later date. Useful for unfrequent habits, don't use this for daily ones." value={habitDef.canBeDoneLater} valueSetter={(value) => { habitDef.canBeDoneLater = value; habitDef.save() }}/>
      <Divider />

      {habitDef.isActive ?
        <div>
          <Center><button onClick={() => uiStore.push(<ItemStatsView habitDef={habitDef} />)}><InsightsIcon className="gg-insights" /> View stats</button></Center>
          {habitDef.id && (hasItemsOn ? <SaveButton onClick={() => {habitDef.isActive = false; habitDef.save(); uiStore.pop()}}>Archive</SaveButton> : <SaveButton onClick={() => {logEvent('day_set_deleted'); habitDef.delete(); uiStore.pop();}}>Delete</SaveButton>)}
          <Help style={{textAlign: "center"}}>{hasItemsOn ? "Can not delete, because habit has historic data." : ""}</Help>
        </div>      
      :
        <div>        
          {habitDef.id ? <SaveButton onClick={() => {habitDef.isActive = true; habitDef.save() }}>Unarchive</SaveButton> : ""}
        </div>
      }
    </DayLevelScreen>
  }
)

export const InsightsIcon = styled.i`
  display: inline-block;
  --ggs: 0.80;
  top: 3px;
`

export const CenteredContentContainer = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  min-height: 3.2em;
  justify-content: center;
  display:flex;
  justify-content: center;
  align-items: center;
`;

export const CenteredContentTextEditButtonDOM = styled.div`
  flex-grow: 1;
  text-align: center;
  display:block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: ${expansive => expansive ? "wrap" : "nowrap"};
`;

export const HabitsDefEditor = observer((props) => {

  function add() {

    uiStore.push(<TextEditor label="New habit" placeholder="eg. Read 3 pages of a book in the morning" value={""} onSaveText="Add" error={value => value.length === 0 ? `Please enter a name of the habit` : ''} valueSetter={(value) => habitStore.timeItemAndAnchorStore.newHabit(value)}/>)
  }
  
  return <DayLevelScreen title="Habits" onBack={() => uiStore.pop()} onPlus={add}>
    {habitStore.habitDefs.length === 0 ? <SaveButton onClick={() => habitStore.timeItemAndAnchorStore.setupInitialDayStructure()}>Create an initial day structure</SaveButton> : ""}
    <HabitDefList itemAndAnchorStore={habitStore.timeItemAndAnchorStore} />
    <SaveButton onClick={() => uiStore.push(<TextEditor label="New Time"  placeholder="eg. 16:00" value={"12:00"} valueSetter={(value) => habitStore.timeItemAndAnchorStore.addAnchor(timeToMs(value))}/>)}> New time anchor </SaveButton>
    <Divider />
    {habitStore.deactivatedDefs.map(habitDef => <HabitsDefListItem key={habitDef.id} active={habitDef.isActive} priority={habitDef.priority} onClick={() => uiStore.push(<HabitsDefView habitDef={habitDef} />)}>
      {habitDef.name}
    </HabitsDefListItem>)}
  </DayLevelScreen>
});

export const WeekDayCheckBox: FunctionComponent<{checked: boolean, onChange: () => void}> = observer(({checked, onChange}) => {
  return <input style={{margin: 0}} type="checkbox" checked={!!checked} onChange={onChange}></input>
});