export const DURATION_SECOND = 1000;
export const DURATION_MINUTE = DURATION_SECOND * 60;
export const DURATION_HOUR = DURATION_MINUTE * 60;
export const DURATION_DAY = DURATION_HOUR * 24;
export const TIME_00_00 = timeToMs("00:00");
export const TIME_24_00 = timeToMs("24:00");

export const DEFAULT_TIME_POINT_DENSITY = (DURATION_MINUTE * 10) / pointWeight(DURATION_MINUTE * 10);

export function isDurationMs(ms: number) {
    return Number.isInteger(ms) && ms >= 0;
  }
  
  export function timeToMs(time: string) {
    const components = time.split(":");
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
  
    if (components.length >= 1) {
      hours = parseFloat(components[0])
      if (isNaN(hours)) hours = 0;
    }
    
    if (components.length >= 2) {
      minutes = parseFloat(components[1])
      if (isNaN(minutes)) minutes = 0;
      minutes = Math.min(59, minutes)
    }
    
    if (components.length >= 3) {
      seconds = parseFloat(components[2])
      if (isNaN(seconds)) seconds = 0;
      seconds = Math.min(59, seconds)
    }
  
    return Math.round(((hours * 60 + minutes) * 60 + seconds) * 1000);
  }
  
  const duration = /(-?(?:\d+\.?\d*|\d*\.?\d+)(?:e[-+]?\d+)?)\s*([a-zµμ]*)/ig
  
  /**
   * conversion ratios
   */
  
  const durationConvertion: {[id: string]: number} = {}
  
  durationConvertion.nanosecond =
  durationConvertion.ns = 1 / 1e6
  
  durationConvertion['µs'] =
  durationConvertion['μs'] =
  durationConvertion.us =
  durationConvertion.microsecond = 1 / 1e3
  
  durationConvertion.millisecond =
  durationConvertion.ms = 1
  
  durationConvertion.second =
  durationConvertion.sec =
  durationConvertion.s = durationConvertion.ms * 1000
  
  durationConvertion.minute =
  durationConvertion.min =
  durationConvertion.m = durationConvertion.s * 60
  
  durationConvertion.hour =
  durationConvertion.hr =
  durationConvertion.h = durationConvertion.m * 60
  
  durationConvertion.day =
  durationConvertion.d = durationConvertion.h * 24
  
  durationConvertion.week =
  durationConvertion.wk =
  durationConvertion.w = durationConvertion.d * 7
  
  durationConvertion.month =
  durationConvertion.b =
  durationConvertion.d * (365.25 / 12)
  
  durationConvertion.year =
  durationConvertion.yr =
  durationConvertion.y = durationConvertion.d * 365.25
  
  export function durationToMs(str=''): number {
    str = str.trim()
  
    if (str === '' || str === '0') {
      return 0;
    }
  
    var result: number = 0
  
    // If we have both dots and commas
    if (str.indexOf(".") !== -1 && str.indexOf(",") !== -1) {
      // Ignore commas
      str = str.replace(/(\d),(\d)/g, '$1$2')
    } else {
      // Otherwise, replace commas with dots
      str = str.replace(/(\d),(\d)/g, '$1.$2')
    }
  
    str.replace(duration, function(_, n, units){
      units = durationConvertion[units] || durationConvertion[units.toLowerCase().replace(/s$/, '')]
      if (units) result = (result || 0) + parseFloat(n) * units
      return _
    })
  
    return result && (result / durationConvertion['ms'])
  }
  
  export function msToTime(ms: number) {
    if (ms === null || ms < 0) {
      return "00:00"
    }
  
    if (ms > 1000 * 60 * 60 * 24) {
      return "24:00"
    }
  
    const allMinutes = Math.round(ms / 1000 / 60)
    const minutes = allMinutes % 60;
    const hours = (allMinutes - minutes) / 60;
    
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`
  }
  
  export function msToDuration(ms: number) {
    let negative = ms < 0;
    ms = Math.abs(ms);

    let seconds = ms / 1000;
    let minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    let hours = Math.floor(minutes / 60);
    minutes -= hours * 60;
  
    return `${negative ? "-" : ""}${hours > 0 ? (hours + "h") : ""}${hours > 0 && minutes > 0 ? " " : ""}${minutes > 0 || hours === 0 ? (minutes + "m") : ""}`
  }
  
  export function humanReadableDuration(duration: number) {
    if (duration <= DURATION_HOUR) {
      return duration > 0 ? `~${msToDuration(duration)}` : ""
    } else {
      let hours = Math.round(duration / DURATION_HOUR * 2) / 2;
      return duration > 0 ? `~${hours}h` : ""
    }
  }
  
  
  export function pointWeight(duration: number) {
    let hours = duration / 1000 / 60 / 60;
  
    if (hours < 0.5) { return 1; }
    if (hours < 1) { return 2; }
    if (hours < 2) { return 3; }
    if (hours < 4) { return 4; }
    return 5;
  }