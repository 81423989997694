import React from 'react'
import styled, { useTheme } from 'styled-components'

import { observer } from "mobx-react-lite";
import { DayLevelScreen, Help } from "./styles/components";
import { Item } from '../data/Item';
import { uiStore } from '../data/UIStore';
import { HabitDef } from '../habits/data/HabitDef';
import { CalendarView } from './CalendarView';
import { HabitItem } from '../habits/data/HabitItem';
import { dayDataStore } from '../data/DayDataStore';
import { describeDate, mainStore } from '@habline/common/MainStore';
import { capitalizeFirstLetter, datesInAMonth } from '@habline/common/utils';
import { extractStats } from '../data/StatsStore';
import { Status } from '@habline/common/Status';
import { InsightsIcon } from '../habits/components/HabitsDefEditor';
import { Center } from '@habline/common/primitives';
import { msToDuration, msToTime } from '@habline/common/time';
import { openContextMenu } from './context/ItemActionsContextMenu';

export function* iterateItems(dates:Date[], itemPredicate: (item: Item) => boolean) {
  for (let date of dates) {
    let dayData = dayDataStore.getDayData(date);
    
    if (dayData) {
      for (let item of dayData.items) {
        if (itemPredicate(item)) {
          yield item;
        }
      }
    }
  }
}

export const ItemStatsView = observer<{item?: Item, habitDef?: HabitDef, category?: string}>(({item, habitDef, category}) => {
  const theme = useTheme();
  const [currentDate, setCurrentDate] = React.useState(mainStore.currentDate);

  habitDef = habitDef ? habitDef : (item instanceof HabitItem ? item.habitDef : null)


  function isItemFilteredIn(i: Item) {
    return (
      (habitDef instanceof HabitDef && i instanceof HabitItem && i.defId === habitDef.id) ||
      (item && i.name === item.name && i.category === item.category) ||
      (category && i.category === category)
    )
  }

  function infoForDate(date: Date) {
    let isInIcebox = false;
    let wasPostponed = false;
    let status = null;

    let dayData = dayDataStore.getDayData(date);

    if (dayData) {
      if ((habitDef != null && habitDef.isInThisSet(date))) {
        status = Status.UNKNOWN;
      }

      for (let dayDataItem of dayData.items) {
        if (dayDataItem.status !== Status.UNKNOWN && isItemFilteredIn(dayDataItem)) {
          isInIcebox = isInIcebox || dayDataItem.isInIcebox;
          wasPostponed = wasPostponed || dayDataItem.isPostponedToSpecificDate;
          if (status === null || status === Status.UNKNOWN || status === dayDataItem.status) {
            status = dayDataItem.status;
          } else {
            status = "COMPLICATED"
          }
        }

        if ((item != null || category != null) && isItemFilteredIn(dayDataItem) && dayDataItem.status === Status.UNKNOWN) {
          status = Status.UNKNOWN
        }
      }
    }

    let backgroundColor = theme.backgroundLevel1.toString();
    let text:any = String(date.getDate())
    if (status === Status.UNKNOWN) backgroundColor = theme.backgroundLevel2.toString();
    if (status === Status.SUCCESS) { backgroundColor = theme.backgroundLevel4.toString(); text = <i className="gg-check"></i>}
    if (status === Status.PARTIAL) { backgroundColor = theme.backgroundLevel4.toString(); text = "•"}
    if (status === Status.MISSED) { backgroundColor = theme.backgroundLevel2.toString(); text = "-"} //<i className="gg-close"></i>

    if (isInIcebox) text = "❄";
    if (wasPostponed) text = <i className="gg-arrow-right"></i>;
    
    if (status === "COMPLICATED") { backgroundColor = theme.backgroundLevel4.toString(); text = "?" }

    return {text, backgroundColor};
  }

  let title: string;

  if (item != null) {
    title = `Stats for ${item.category} ${item.name}`
  } else if (habitDef != null) {
    title = `Stats for ${habitDef.category} ${habitDef.name}`
  } else if (category != null) {
    title = `Stats for ${category}`
  } else {
    title = `Stats for ???`
  }

  let monthDayData = datesInAMonth(currentDate).map(date => dayDataStore.getDayData(date)).filter((dayData) => dayData != null);

  let stats = extractStats(monthDayData, {
    itemTest: isItemFilteredIn,
    isDayApplicableTest: (dayData, dailyStats) => habitDef != null && dayData.date.getTime() <= mainStore.today.getTime() && habitDef.isInThisSet(dayData.date) && dailyStats.successful + dailyStats.ignored > 0,
    referenceDuration: habitDef ? habitDef.duration : 0
  })
  
  let canGoToCategory = habitDef != null ? habitDef.category : (item != null ? item.category : null);

  return <DayLevelScreen title={title} onBack={() => uiStore.pop()} >
    <CalendarView
      infoForDate={infoForDate}
      currentDate={currentDate}
      onDateChanged={(date:Date) => setCurrentDate(date)}
    />
    
    { isFinite(stats.completionRatio) && <div>
      <p>Total adherence this month: {(stats.completionRatio * 100).toFixed(0)}%</p>
    </div> }

    { !isFinite(stats.completionRatio) && stats.successful > 0 && <div style={{textAlign: "center"}}>
      <p>Total score this month: {stats.successful}</p>
    </div> }
    
    { [...iterateItems(datesInAMonth(currentDate), (item) => isItemFilteredIn(item) && item.insightNote !== "")].map(item =>
      <div onClick={() => openContextMenu([item], true)} style={{marginBottom: "1em"}} key={item.id}><Help>{capitalizeFirstLetter(describeDate(item.date))}</Help>{item.insightNote}</div>
    ) }

    { isFinite(stats.medianCompletionTime) &&
      <p>Usual completion time: { msToTime(stats.medianCompletionTime) }</p>
    }

    { isFinite(stats.guessedDurationTime) &&
      <p>Usually takes: { msToDuration(stats.guessedDurationTime) }</p>
    }

    { canGoToCategory && <Center><button onClick={() => uiStore.push(<ItemStatsView category={canGoToCategory} />)}><InsightsIcon className="gg-insights" /> View {canGoToCategory} stats</button></Center>}
  </DayLevelScreen>
});

export const HabitsDefListItem = styled.div<{active?: boolean, isDragging?: boolean}>`
  text-align: left;
  padding-bottom: 0.5em;
  text-decoration: ${props => props.active ? "initial": "line-through"};
  display:block;

  user-select: 'none';
  background: ${({isDragging}) => isDragging ? 'lightgreen' : 'none'};
`;