import { action } from 'mobx';
import { scroller } from 'react-scroll'
import { Item } from '../data/Item';

export const scrollToItem = action((item: Item, isNew = true, isHighlighted = false) => {
  const SCROLL_DURATION = 500;

  setTimeout(() => {
    scroller.scrollTo(item.id, {
      duration: SCROLL_DURATION,
      offset: -Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)/2,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  
    if (isHighlighted) {
      setTimeout(action(() => {
        item.doHighlight();
      }), SCROLL_DURATION);
    }

    item.makeSureThatYouAreNotHidden();
  }, 10)

  if (isNew) {
    item.doNewItemEntry();
  }
})