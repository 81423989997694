import React, {useEffect} from 'react';
import { gapiAuth } from "../data/gapi"
import { observer } from 'mobx-react-lite'
import styled from 'styled-components';
import { SavingIndicator } from '@habline/common/savingindicator';
import { loadingStore } from '../data/loading';
import { uiStore } from '../data/UIStore';
import { CoreView } from './CoreView';
import { useSwipeable } from 'react-swipeable';
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./styles/GlobalStyles";
import { darkTheme, lightTheme } from './styles/themes';
import { globalPrefsStore } from '../data/GlobalPrefsStore';
import { itemStore } from '../data/ItemStore';
import { ItemContextMenu } from './context/ItemContextMenu';

export const App = observer((props) => {
  React.useEffect(() => {
    gapiAuth.login();
    uiStore.push(<CoreView />)
  }, []);

  useEffect(() => {
    if (itemStore.selectedItemIds.length > 0) {
      uiStore.permToast(`Selected ${itemStore.selectedItemIds.length} item${itemStore.selectedItemIds.length === 1 ? `` : `s`}.`, () => uiStore.showContextMenu(<ItemContextMenu items={itemStore.selectedItems} outsideOfListing={true} />), () => itemStore.clearSelection());
    } else {
      uiStore.closePermToast();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemStore.selectedItemIds.length])

  let swipablePermProps = useSwipeable({onSwipedUp: () => uiStore.closePermToast()});
  let swipeableProps = useSwipeable({onSwipedUp: () => uiStore.closeToast()});

  let uis = uiStore.screenStack.slice().reverse()

  return (
    <ThemeProvider theme={globalPrefsStore.darkMode ? darkTheme: lightTheme}>
      <>
      <GlobalStyles/>
      {uis.length === 1 &&  <Toast onClick={() => uiStore.activatePermToast()} {...swipablePermProps} visible={uiStore.permMessageVisible}>{uiStore.permMessage}</Toast>}
      <Toast onClick={() => uiStore.activateToast()} {...swipeableProps} visible={uiStore.messageVisible}>{uiStore.message}</Toast>
      
      {uis.map((screen, i) => <Screen key={i} style={{display: i === uis.length - 1 ? "block": "none"}}>{screen.screen}</Screen>)}
      {uiStore.contextMenu}
      <SavingIndicator isSaving={loadingStore.isSaving} justSaved={loadingStore.justSaved} isDirty={loadingStore.isDirty}/>
      </>
    </ThemeProvider>
  );
});

const Toast = styled.div<{visible: boolean}>`
  position: fixed;
  top: 0;
  
  background-color: ${({theme}) => theme.backgroundLevel1.toString()};

  box-sizing: border-box;
  width: 92%;
  margin-left:4%;
  border-radius: 0 0 1rem 1rem;
  padding: 2rem;
  padding-top: 8rem;
  
  color: ${({theme}) => theme.textLevel0};
  margin-top: ${(({visible}) => visible ? 0 : -30)}rem;
  opacity: ${(({visible}) => visible ? 1 : 0)};
  pointer-events: ${(({visible}) => visible ? "auto" : "none")};
  z-index: 1500;

  transition: opacity 0.3s, margin-top 0.3s;
  box-shadow: 1px 1px 4px 1px rgba(0,0,0,0.35);
  min-height: 10rem;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  touch-action: none;
`;

export const Screen = styled.div`
  width: 100%;
`;

export default App;