import React, { FunctionComponent } from 'react'
import { observer } from 'mobx-react-lite'
import { DayLevelScreen } from "../styles/components"
import styled from 'styled-components'
import { uiStore } from '../../data/UIStore'
import { Help } from '../styles/components';

export interface TextInputProps {
  placeholder?: string,
  area?: boolean,
  label?: string,
  help?: string|JSX.Element,
  value: string,
  valueSetter: (arg0: string) => void
}

export interface TextEditorProps extends TextInputProps {
  error?: (arg0: string) => string|undefined,
  onSaveText?: string
}

export const TextInput: FunctionComponent<TextInputProps> = (
  ({placeholder, area, label, help, value, valueSetter}) => {
    return <label>
      {label ? label : ""}
      {help ? <Help>{help}</Help> : ""}
      {area ? 
        <TextAreaDOM className="u-full-width" placeholder={placeholder} value={value} onChange={(event) => { valueSetter(event.target.value) }} ref={input => input && input.focus()}/>
        :
        <TextInputDOM className="u-full-width" type="text" placeholder={placeholder} value={value} ref={input => input && input.focus()} onChange={(event) => { valueSetter(event.target.value) }} />
      }
    </label>
  }
)

export const TextEditor: FunctionComponent<TextEditorProps> = observer(
  ({label, help, placeholder, value, error, valueSetter, area, onSaveText = "save"}) => {
    const [text, setText] = React.useState(value);

    const err = error && error(text);

    function submit() {
      if (!err) {
        valueSetter(text);
        uiStore.pop()
      }
    }

    return <DayLevelScreen title={label} onBack={() => uiStore.pop()} onSave={!err && submit} onSaveText={onSaveText}>
      <Form action="my_redirect_url" method="post" onSubmit={e => {submit(); e.preventDefault()}}>
        <TextInput placeholder={placeholder} value={text} valueSetter={setText} area={area}/>
      </Form>
      {err ? <Help>{err}</Help> : ""}
      {help ? <Help>{help}</Help> : ""}
    </DayLevelScreen>
  }
)

export const TextInputDOM = styled.input`
  text-align: left;
  margin: 0;
`;

export const TextAreaDOM = styled.textarea`
  text-align: left;
  margin: 0;
  border: 0;
  height: 75vh;

  &:focus {
    outline: none !important;
    border:0;
  }
`;

export const Form = styled.form`
  margin: 0;
`;
