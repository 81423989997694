import { observable, reaction, action, makeObservable, computed, IObservableArray } from "mobx"
import firebase from 'firebase/app';
import { uiStore } from './UIStore'
import { newId } from "@habline/common/firebase";

//WARNING: this class requires for the converter from firestore to read checkSum (does not have to write)

export interface UpdatableInPlace<T> {
  readonly id: string;
  updateFromFirebase(data: T|undefined): void;
}

export function carefullUpdateArray<T extends UpdatableInPlace<T>>(oldArray: T[], newArray: T[]) {
  let objectsMap = new Map<string, T>();

  for (let obj of oldArray) {
    objectsMap.set(obj.id, obj);
  }

  for (let newObj of newArray) {
    if (objectsMap.has(newObj.id)) {
      objectsMap.get(newObj.id).updateFromFirebase(newObj);
    } else {
      objectsMap.set(newObj.id, newObj);
    }
  }

  oldArray.splice(0, oldArray.length, ...newArray.map(newObj => objectsMap.get(newObj.id)))
}

export abstract class WithProtectedSaveDelay<T extends WithProtectedSaveDelay<T>> implements UpdatableInPlace<T> {
  readonly id: string
  isSaving: boolean = false
  runOnReady: IObservableArray<() => void> = observable.array()
  checkSum: string|undefined = undefined

  isLoadedForTheFirstTime = false

  _areUpdatesBlocked = false
  _pendingUpdate = null

  dirtyDelayId: NodeJS.Timeout|undefined = undefined
  justSavedTimeoutId: NodeJS.Timeout|undefined = undefined
  toDisposeOnUnsubscribe: Array<() => void> = []

  constructor(id: string, checkSum: string) {
    this.id = id;
    this.checkSum = checkSum;

    makeObservable(this, {
      isSaving: observable,
      runOnReady: observable,
      checkSum: observable,
      
      isLoadedForTheFirstTime: observable,

      dirtyDelayId: observable,
      justSavedTimeoutId: observable,

      isDirty: computed,
      justSaved: computed,

      subscribeToChanges: action,
      unsubscribeFromChanges: action,

      save: action,
      _updateDoc: action,
    })
  }

  get isDirty() { return this.dirtyDelayId != null; }
  get justSaved() { return this.justSavedTimeoutId != null; }

  abstract get dbReference(): firebase.firestore.DocumentReference<T>;
  abstract updateFromFirebase(data: T|undefined): void;
  abstract get isReadyToRunRunners(): boolean;
  abstract preSubScribe(): void;

  subscribeToChanges() {
    this.unsubscribeFromChanges();

    console.log(`Subscring to changes to ${this.id}`);

    this.preSubScribe();

    {
      const checkRunOnReady = () => {
        if (this.isReadyToRunRunners && this.runOnReady.length > 0) {
          console.log(`${this.id} running ready runners!`);
          for (const runOnReady of this.runOnReady) {
            runOnReady();
          }
          this.runOnReady.clear();
        }
      }
  
      checkRunOnReady();
      this.toDisposeOnUnsubscribe.push(reaction(
        () => [this.isReadyToRunRunners, ...this.runOnReady],
        () => { checkRunOnReady() }
      ))
    }
    
    this.toDisposeOnUnsubscribe.push(this.dbReference.onSnapshot({includeMetadataChanges: true}, (doc) => this._updateDoc(doc)));
  }

  unsubscribeFromChanges() {
    if (this.toDisposeOnUnsubscribe.length > 0) {
      console.log(`Unsubscribed from changes to ${this.id}`);

      for (const disposer of this.toDisposeOnUnsubscribe) {
        disposer();
      }

      this.toDisposeOnUnsubscribe = [];
    }
  }

  abstract tmpLog(): void

  save() {
    console.log(`${this.id} marked as dirty`);
  
    clearTimeout(this.dirtyDelayId);
    clearTimeout(this.justSavedTimeoutId);

    action(() => this.justSavedTimeoutId = undefined)();
    this.dirtyDelayId = setTimeout(action(() => {
      this.dirtyDelayId = undefined;
      this.isSaving = true;

      var db = firebase.firestore();
      db.runTransaction((transaction) => {
        return transaction.get(this.dbReference).then((doc) => {
          let currentObject = doc.data();
          //console.log("SAVING", currentObject, currentObject.checkSum, this.checkSum)
          if ((!currentObject && this.checkSum == null) || (currentObject && currentObject.checkSum === this.checkSum)) {
            let newChecksum = newId(); //New random number, it works as a checksum for this use case
            //@ts-ignore
            this.tmpLog();
            transaction.set(this.dbReference, this as any as T);
            transaction.update(this.dbReference, {checkSum: newChecksum}); //TODO: Streamlining this into one operation can reduce the number of total writes
            return newChecksum
          } else {
            return Promise.reject("Rejected concurrent modification"); 
          }
        });
      }).then(action((newChecksum: string) => {
        this.checkSum = newChecksum;
        console.log(`DayData for ${this.id} written`);
        this.isSaving = false;
        this.justSavedTimeoutId = setTimeout(action(() => {
          this.justSavedTimeoutId = undefined;
        }), 5000)
      })).catch((err) => {
        this.isSaving = false;

        console.error(err);
        uiStore.toast(err.toString());

        //Recovery from this state, need to ask again for data 
        this.dbReference.get().then(((doc) => this._updateDoc(doc)));
      })
    }), 2000);
  }

  blockUpdates() {
    this._areUpdatesBlocked = true;
  }

  unblockUpdates() {
    this._areUpdatesBlocked = false;
    if (this._pendingUpdate != null) {
      this._updateDoc(this._pendingUpdate)
    }
  }

  _updateDoc(doc: firebase.firestore.DocumentSnapshot<any>) {
    if (this.isDirty || this.isSaving) {
      console.log("Ignoring snapshot because we have local changes", this.isDirty, this.isSaving);
      return;
    }

    if (this._areUpdatesBlocked) {
      this._pendingUpdate = doc;
    } else {
      this._actuallyPerformDocUpdate(doc)
      this._pendingUpdate = null;
    }
  }

  _actuallyPerformDocUpdate(doc: firebase.firestore.DocumentSnapshot<any>) {
    let data = doc.data();
    this.checkSum = data ? data.checkSum : undefined;
    this.updateFromFirebase(data ? data : undefined);
    if (!doc.metadata.fromCache) this.isLoadedForTheFirstTime = true;
  }
}