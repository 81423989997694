import { makeAutoObservable, reaction } from "mobx"
import { datePlusDays, fromSimpleDate, logEvent } from "@habline/common/utils"
import { gapiAuth } from "./gapi"
import { dayDataStore } from "./DayDataStore"
import { Item } from "./Item"
import { durationToMs } from '@habline/common/time';
import { TaskItem } from "./TaskItem"
import { mainStore } from "@habline/common/MainStore"
import { newId } from "@habline/common/firebase"

export function hackToSolveAccessError() {}

class ItemStore {
  selectedItemIds: [string, Date][] = [];
  notifyAboutItemStatus: (item: Item) => void;

  constructor() {
    makeAutoObservable(this);
  }

  get postponedItems() {
    let postponed = new Array<Item>();
    for (let dateStr of dayDataStore.daysData.keys()) {
      let date = fromSimpleDate(dateStr);
      if (dayDataStore.hasReadyDayData(date)) {
        let dayData = dayDataStore.getDayData(date);

        for (let item of dayData.items) {
          if (item.isInIcebox) {
            postponed.push(item);
          }
        }
      }
    }

    postponed.sort(function (a, b) {
      if (a.priority > b.priority) return -1;
      if (a.priority < b.priority) return 1;
      if (a.lastUpdate > b.lastUpdate) return -1;
      if (a.lastUpdate < b.lastUpdate) return 1;
      return 0;
    });

    return postponed;
  }

  clearSelection() {
    this.selectedItemIds = [];
  }

  addToSelection(item: Item) {
    this.selectedItemIds.push([item.id, new Date(item.date)]);
  }

  isInSelection(item: Item) {
    return this.selectedItemIds.some(([id, date]) => id === item.id && date.getTime() === item.date.getTime());
  }

  removeFromSelection(item: Item) {
    this.selectedItemIds = this.selectedItemIds.filter(([id, date]) => id !== item.id || date.getTime() !== item.date.getTime());
  }

  get selectedItems() {
    return this.selectedItemIds.map(([id, date]) => dayDataStore.getDayData(date).items.find((item) => item.id === id));
  }

  init() {
    //
    // Load postponed
    //
    reaction(
      () => gapiAuth.workspace,
      isLoggedIn => {
        if (isLoggedIn) {
          //request 30 days for postponed items, temporary solution before posponed will be stored in db
          let currentDate = datePlusDays(mainStore.today, 5); //A bit of a hack, we go into the future and get items from there if any
          let MAX_BACK = 30;

          for (let i = 0; i < MAX_BACK; i++) {
            dayDataStore.requestDayData(currentDate);
            currentDate = datePlusDays(currentDate, -1);
          }
        }
      }
    )

    //
    // Fix items on load
    //
    reaction(
      () => gapiAuth.isWorkspaceFresh,
      (isWorkspaceFresh) => {
        if (isWorkspaceFresh) {
          console.log("Initial tasks added");
          let nowMs = mainStore.msElapsedToday;
          console.log("NOW MS", nowMs);
          let sequence = [
            //new TaskItem({id: newId(), manualName: "Read the help section", manualTime: nowMs, manualCategory: "📖", manualDescription: "You can access 'Help' section from the settings page.", manualDuration: durationToMs("10m"), date: mainStore.today}),
            new TaskItem({id: newId(), manualName: "This is a sample task", manualDescription: "Feel free to mark it as a success.", manualDuration: durationToMs("5m"), date: mainStore.today}),
            //new TaskItem({id: newId(), manualName: "Click this and mark as successful", manualDescription: "This is a task, mark it of as successful by pressing the SUCCESS button below.\nOn a given day, tasks, habits and calendar items can be marked both as a SUCCESS or a MISS.", date: mainStore.today}),
            //new TaskItem({id: newId(), name: "Acknowledge that you have until midnight to mark all of the tutorial tasks", description: "All tasks and items in habline are added to a specific day, there is no backlog, there is no project management.", date: mainStore.today}),
            //new TaskItem({id: newId(), manualName: "Mark this as a miss", manualDescription: "It's ok not to do things, as long as you mark them as so.\nMark this task as missed.", date: mainStore.today}),
            //new TaskItem({id: newId(), manualName: "Add the 'Mark this as a miss' task to Tomorrow's list", manualDescription: "Any task marked as missed, becomes a suggestion when adding a new task.\n1. Go to tomorrow\n2. Add the previously marked task to that day by pressing the + button, selecting that task and pressing 'Add'.", date: mainStore.today}),
            //new TaskItem({id: newId(), manualName: "Setup your calendars", manualDescription: "Go to settings -> calendars and pick the calendars that you use, mark this succesfull if you see calendar events in this app.", date: mainStore.today}),
            //new TaskItem({id: newId(), manualName: "Add an initial structure for your habits", manualDescription: "Go to settings -> habits and press 'Create an initial day structure', check this off once you seen some habit items in your daily list.", date: mainStore.today}),
            //new TaskItem({id: newId(), name: "Make a commitment to use this up to drive you Today and Tomorrow", description: "There are no reminders or notifications! When you finish a task, mark it of, check what's next and proceed with that. Rinse and repeat, good luck!", date: mainStore.today}),
            //new TaskItem({id: newId(), name: "Acknowledge each day your mission is to mark of all the items on your daily list", description: "Remember that you can fail at tasks, it's ok. But it's important to mark all of them. As an additional benefit of marking all the tasks off you will be able to see the stats screen each day.", date: today()}),
            //new TaskItem({id: newId(), name: "Adjust the habits", description: "Adjust the habits so they reflect your way of life and what you want to focus on. Go to settings -> habits and edit the list. Don't focus that much on the actual times, they are just used to sort your daily list", date: mainStore.today}),
            //new TaskItem({id: newId(), name: "Add your tasks for Today", description: "Add your tasks for Today. In order to add a task, press the + button, located near the bottom of the screen.", date: mainStore.today}),
            
          ];

          sequence.reverse();
        
          (async () => {
            const func = x => dayDataStore.requestDayData(x.date).addItem(x);
            for (let job of sequence.map(x => () => func(x)))
              await job()
          })()
        
          logEvent('added_tutorial_tasks');
          gapiAuth.isWorkspaceFresh = false;
        }
      }
    )
  }
}

export const itemStore = new ItemStore();