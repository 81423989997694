import React, { FunctionComponent } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { uiStore } from '../../data/UIStore'
import { datePlusDays } from '@habline/common/utils'
import { TextEditor } from "./TextEditor"
import { finalParser } from '../../data/every'

export const DateExpressionEditor: FunctionComponent<{autoExpression: string, onExpressionChanged: (value: string) => void}> = observer(
  ({autoExpression, onExpressionChanged}) => {
    let simpleWhen = null;

    if (autoExpression === "" || autoExpression == null) {
      simpleWhen = {}
    } else {
      let compiledAutoExpression = finalParser.parse(autoExpression.toLowerCase());
      
      if (compiledAutoExpression.status) {
        let expression = compiledAutoExpression.value;
        
        if (expression.isSimpleWeeklySchedule()) {
          let exampleSundayZeroDay = new Date(2021, 0, 17);

          simpleWhen = {};
          for (let i = 0; i < 7; i++) {
            simpleWhen[i] = expression.isInThisSet(datePlusDays(exampleSundayZeroDay, i));
          }
        }
      }
    }

    function whenUpdated(simpleWhen: {[idx: number]: boolean}) {
      let daysOfWeekNames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
      let activeDays = [0,1,2,3,4,5,6].filter(idx => simpleWhen[idx]).map(idx => daysOfWeekNames[idx]);
      let str = "";

      if (activeDays.length > 0) {
        if (activeDays.length === 7) {
          str = `every day`
        } else {
          if (activeDays.length === 1) {
            str = `every ${activeDays[0]}`
          } else if (activeDays.length === 5 && activeDays.indexOf("saturday") === -1 && activeDays.indexOf("sunday") === -1) {
            str = `every weekday`
          } else {
            str = `every ${activeDays.slice(0, -1).join(', ')} and ${activeDays.slice(-1)}`
          }
        }
      }
      
      onExpressionChanged(str);
    }

    function openWhenEditor() {
      uiStore.push(
        <TextEditor label={"When?"}
          help={<span>
            Examples:<br/>
            every Tue, Monday and Friday<br/>
            every Mon and every other Tue and Wednesday<br/>
            every #tag<br/>
            every day except #tag<br/>
            every Tue except every other Tuesday<br/>
            every Tue with #julek except every other tuesday<br/>
            every first of #tag and every last of #tag<br/>
            every 3rd and every 4th of july<br/>
            every day except sunday<br/>
            every 1st, 10th and last wednesday<br/>
            every 3rd sunday<br/>
            every monday and thursday with #tag<br/>
            50% of every day<br/>
          </span>}
          placeholder="eg. *, monday, monday|friday, monday&even-week ..."
          value={autoExpression}
          error={(value) => {
            let res = finalParser.parse(value.toLowerCase());
            if (res.status === false) {
              return "Error in expression"
            }
          }}
          valueSetter={(value) => {onExpressionChanged(value)}}
        />
      )
    }

    return <TimeSpecButtons>{simpleWhen && [["MON", 1], ["TUE", 2], ["WED", 3], ["THU", 4], ["FRI", 5], ["SAT", 6], ["SUN", 0]].map(([name, dayIdx]) =>
        <TimeSpecButton key={name} enabled={simpleWhen[dayIdx]} onClick={() => { whenUpdated({...simpleWhen, [dayIdx]: !simpleWhen[dayIdx]}); }}>
          {name}
        </TimeSpecButton>
      )}

        {simpleWhen && <TimeSpecButton enabled={true} onClick={() => openWhenEditor()}>…</TimeSpecButton>}
        {!simpleWhen && <TimeSpecButton enabled={true} onClick={() => openWhenEditor()}>{autoExpression}</TimeSpecButton>}
    </TimeSpecButtons>
  }
);

export const TimeSpecLabel = styled.label`
  width: 3rem;
  text-align: center;

  margin: 0;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  &:first-child {
    margin-left: 0rem;
  }
  &::last-child {
    margin-right: 0rem;
  }
`;

export const TimeSpecButton = styled.button<{enabled?: boolean}>`
  opacity: ${({enabled}) => enabled ? 1.0 : 0.25};
  flex-grow: 1;
  padding: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  
  margin: 0;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  &:first-child {
    margin-left: 0rem;
  }
  &::last-child {
    margin-right: 0rem;
  }
  
  white-space: ${expansive => expansive ? "wrap" : "nowrap"};
`;

export const TimeSpecButtons = styled.div`
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  justify-content: center;
  display:flex;
  align-items: center;
`;
