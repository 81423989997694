import React from 'react';
import { observer } from 'mobx-react-lite';
import { dayDataStore } from '../data/DayDataStore';
import { capitalizeFirstLetter, datePlusDays, datesInRange, getMonday } from '@habline/common/utils';
import { describeDate, DateMode } from '@habline/common/MainStore';
import { CategoryStats, Content, DayBars } from './DailyStats';
import { Notes } from './Notes';
import { medianInfo } from '../data/StatsStore';

export const WeekView = observer((props) => {
  let dayData = dayDataStore.dayData;
  const currentDate = dayData.date;
  
  const monday = getMonday(currentDate);
  const thisPeriodDaysData = datesInRange(monday, datePlusDays(monday, 6)).map(date => dayDataStore.requestDayData(date));
  const prevPeriodDaysData = datesInRange(datePlusDays(monday, -6-1), datePlusDays(monday, -1)).map(date => dayDataStore.requestDayData(date));
  
  const stats = medianInfo(thisPeriodDaysData)
  //TODO: currentDate={currentDate}?
  return <>
    <Content>
      <p>Average score: {stats.medianSuccessful}</p>
      
      <DayBars daysData={thisPeriodDaysData} /> 
      <CategoryStats thisPeriodDaysData={thisPeriodDaysData} prevPeriodDaysData={prevPeriodDaysData}/>
    </Content>
    
    <Notes daysData={thisPeriodDaysData} dateToHeader={date => capitalizeFirstLetter(describeDate(date, {mode: DateMode.DAY}))} />
  </>
});