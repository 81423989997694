import emojiOneJSON from "./emojione.json"
import emojiLibJSON from "./emojilib.json"
import emojiRegex from 'emoji-regex';

// emoji-regex doesn't match some emojis
// https://github.com/mathiasbynens/emoji-regex/issues/59
const rsSurrPair = '[\\ud800-\\udbff][\\udc00-\\udfff]';
const rsEmoji = `(?:${rsSurrPair})`;

// Prepare a searchable database
let emojiDatabase: {[id:string]: Set<string>} = {};
let keyWordIndex: {[keyword:string]: Set<string>} = {};

(function processDatabase() {
  function emojiOneConvertEmoji(unicode: string) {
    var s: any;
    var hi: number;
    var lo: number;
  
    if (unicode.indexOf("-") > -1) {
      var parts = [];
      s = unicode.split('-');
      for (var i = 0; i < s.length; i++) {
        var part: any = parseInt(s[i], 16);
        if (part >= 0x10000 && part <= 0x10FFFF) {
          hi = Math.floor((part - 0x10000) / 0x400) + 0xD800;
          lo = ((part - 0x10000) % 0x400) + 0xDC00;
          part = (String.fromCharCode(hi) + String.fromCharCode(lo));
        }
        else {
          part = String.fromCharCode(part);
        }
        parts.push(part);
      }
      return parts.join('');
    } else {
      s = parseInt(unicode, 16);
      if (s >= 0x10000 && s <= 0x10FFFF) {
        hi = Math.floor((s - 0x10000) / 0x400) + 0xD800;
        lo = ((s - 0x10000) % 0x400) + 0xDC00;
        return (String.fromCharCode(hi) + String.fromCharCode(lo));
      }
      else {
        return String.fromCharCode(s);
      }
    }
  };

  function addKeyword(char:string, keyword: string) {
    let keywords = emojiDatabase[char];

    if (!keywords) {
      keywords = new Set<string>();
      emojiDatabase[char] = keywords;
    }

    keyword = keyword.toLowerCase().replaceAll("_", " ").replaceAll(":", " ").replaceAll("-", " ").trim();
    if (keyword.length >= 2) {
      keywords.add(keyword);
    }

    if (keyword.indexOf(" ") !== -1)
      keyword.split(" ").forEach(subKw => {
        if (subKw.length >= 2) {
          keywords.add(subKw);
        }
      });
  }

  Object.keys(emojiOneJSON).forEach((emojiId: string) => {
    var emoji: any = (emojiOneJSON as {[id:string]: {keywords: string[]}})[emojiId];

    let process = (char: string, emoji: {keywords: string[], name: string, shortname: string, shortname_alternates: string[]}) => {
      emoji.keywords.forEach((keyword: string) => addKeyword(char, keyword));
      addKeyword(char, emoji.name);
      [emoji.shortname, ...emoji.shortname_alternates].forEach((keyword: string) => addKeyword(char, keyword));
    }

    if (emoji.display === 1) { //Keep only displayable emojis
      if (!emoji.diversity) {
        let char = emojiOneConvertEmoji(emoji.code_points.base);
        process(char, emoji);
      }
    }

    //We don't have a proper entry for this emoji so add it as an exception
    if (emojiId === "2695") {
      let char = "⚕️";
      process(char, emoji);
    }
  });

  Object.keys(emojiLibJSON).forEach((emojiId: string) => {
    let emoji = (emojiLibJSON as {[id: string]: {
      keywords: string[],
      char: string,
      fitzpatrick_scale: boolean,
      category: string
    }})[emojiId];

    emoji.keywords.forEach((keyword: string) => addKeyword(emoji.char, keyword));
    addKeyword(emoji.char, emoji.category);
    addKeyword(emoji.char, emojiId);
  });

  Object.keys(emojiDatabase).forEach((char: string) => {
    let keywords = emojiDatabase[char];

    keywords.forEach(keyword => {
      if (!keyWordIndex[keyword]) {
        keyWordIndex[keyword] = new Set<string>();
      }

      keyWordIndex[keyword].add(char);
    })
  });

  //console.log("EMOJI DATABASE", keyWordIndex, emojiDatabase);
  //console.log("EMOJI DATABASE", JSON.stringify(keyWordIndex).length, JSON.stringify(emojiDatabase).length);
  //EMOJI DATABASE 64341 15331
  //EMOJI DATABASE 431383 15331
})();

export function searchEmoji(query: string) {
  query = query.toLowerCase().replaceAll("_", " ").replaceAll(":", " ").replaceAll("-", " ").trim();

  let candidates: string[] = [];

  let icon = extractIcon(query)[0];
  if (icon && candidates.indexOf(icon) === -1) {
    candidates.push(icon);
  }

  if (emojiDatabase[query] && candidates.indexOf(query) === -1) {
    candidates.push(query);
  }

  //Search by keywords
  const maxResults = 30;
  for (let keyword of Object.keys(keyWordIndex)) {
    if (keyword.startsWith(query)) {
      for (let emoji of keyWordIndex[keyword]) {
        if (candidates.indexOf(emoji) === -1) {
          candidates.push(emoji);
        }

        if (candidates.length == maxResults) {
          return candidates;
        }
      }
    }
  }
  
  return candidates;
}

export const emojiSplitRegexp = new RegExp(
  `(${emojiRegex().source}|${rsEmoji}?)`,
  'i'
);

export function extractIcon(text: string):[string|null, string] {
  text = text.trim();

  const regex = emojiSplitRegexp;
  const match = regex.exec(text);
  
  if (match && match.index === 0) {
    return [match[0], text.substring(match[0].length).trim()];
  }
  
  return [null, text.trim()];
}

export function extractIconFromName(name: string, defaultIcon: any) {
  let icon = extractIcon(name)[0];

  if (icon) {
    return icon;
  } else {
    return defaultIcon;
  }
}

export function stripIconFromName(name: string) {
  let newName = extractIcon(name)[1];

  return newName;
}