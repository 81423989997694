import { makeAutoObservable } from "mobx"
import { habitStore } from "../habits/data/HabitStore"
import { dayTagStore } from "./DayTagStore"
import { gapiAuth } from "./gapi"
import { dayDataStore } from "./DayDataStore"
import { mainStore } from "@habline/common/MainStore"

class LoadingStore {
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    //console.log("LAODING", mainStore.timeOutId === null, !gapiAuth.isLoading, gapiAuth.workspace === null, habitStore.isLoadedForTheFirstTime, dayTagStore.isLoadedForTheFirstTime, !dayDataStore.isLoadingAny)
    return !(mainStore.timeOutId === null && !gapiAuth.isLoading && (gapiAuth.workspace === null || (habitStore.isLoadedForTheFirstTime && dayTagStore.isLoadedForTheFirstTime && dayDataStore.requestDayData(mainStore.currentDate).isReadyToRunRunners)))
  } 

  get isSaving() {
    return dayDataStore.isSavingAny || habitStore._habitDefs.isSaving;
  }

  get justSaved() {
    return dayDataStore.justSavedAny || habitStore._habitDefs.justSaved;
  }

  get isDirty() {
    return dayDataStore.isDirtyAny || habitStore._habitDefs.isDirty;
  }
}

export const loadingStore = new LoadingStore();
