import { action, computed, makeObservable, observable } from "mobx";
import { HabitDef } from "./HabitDef";
import { basicAnchors, correctAnchors, TimeAnchor } from "./HabitDefList";
import { timeToMs } from "@habline/common/time";
import { carefullUpdateArray, WithProtectedSaveDelay } from "../../data/WithProtectedSaveDelay";
import { habitStore } from "./HabitStore";
import { gapiAuth } from "../../data/gapi";
import { fb, newId } from "@habline/common/firebase";

export class HabitDefSource extends WithProtectedSaveDelay<HabitDefSource> {
  static VERSION = 1;
  static DB_ID = "habits";

  habitDefs: HabitDef[];
  customAnchors: TimeAnchor[] = []; //this is ephermal

  get deactivatedDefs() { return this.habitDefs.filter(def => !def.isActive); }
  get activeDefs() { return this.habitDefs.filter(def => def.isActive); }

  get anchors() {
    return basicAnchors(this, false);
  }

  get timeItems() {
    return this.habitDefs.filter(habitDef => habitDef.isActive);
  }

  get dbReference() {
    return habitStore.dbReference;
  }

  get isReadyToRunRunners(): boolean {
    return true;
  }

  tmpLog() { console.log("saving", HabitDefSource.toFirestore(this)) }

  constructor(habitDefs: HabitDef[] = [], checkSum: string = undefined) {
    super(HabitDefSource.DB_ID, checkSum);

    this.habitDefs = habitDefs;

    makeObservable(this, {
      habitDefs: observable,
      customAnchors: observable,

      deactivatedDefs: computed,
      activeDefs: computed,
      anchors: computed,
      timeItems: computed,
      dbReference: computed,
      isReadyToRunRunners: computed,
      
      addAnchor: action,
      preSubScribe: action,
      updateFromFirebase: action,
    })
  }

  addAnchor(time: number): void {
    this.customAnchors.push(new TimeAnchor(this, time));
  }

  preSubScribe(): void {
    this.runOnReady.push(() => {
      //this.loadOldHabits();
    })
  }

  updateFromFirebase(habitDefs: HabitDefSource | undefined): void {
    if (habitDefs) {
      carefullUpdateArray(this.habitDefs, habitDefs.habitDefs);
      correctAnchors(this);
    }
  }

  newHabit(name: string) {
    let habitDef = new HabitDef({id: newId(), name: name})
    this.habitDefs.push(habitDef);
    correctAnchors(this)
    this.save();
    return habitDef;
  }

  delete(habitDef: HabitDef) {
    this.habitDefs.splice(this.habitDefs.indexOf(habitDef), 1)
    this.save();
  }

  loadOldHabits() {
    
    let collection = fb.firestore().collection(`/workspaces/${gapiAuth.user.uid}/habitDefs`).withConverter({
      fromFirestore: (snapshot, options) => {
        let habitDef = new HabitDef();
        let data = snapshot.data();
        if (data.id === "habits")
          return null;
        data.id = snapshot.ref.id;
        habitDef.fromFirestore(data, 0);
        return habitDef;
      },
      toFirestore: (data:HabitDef) => {
        throw Error("We are not saving this way")
      },
    });
    collection.orderBy("time").get().then(action(docs => {
      let habitDefs = docs.docs.map((item) => item.data());
      this.habitDefs = habitDefs;
      this.save();
      console.log("LOADING OLD HABITS", this.habitDefs)
    }))
  }

  setupInitialDayStructure() {
    this.habitDefs = [...this.habitDefs, 
      (new HabitDef({id: newId(), category: "🌙", name: "Woke up around 6:00", timeAnchor: timeToMs("06:00"), autoExpression: "every day"})),
      (new HabitDef({id: newId(), category: "☑️", name: "Checked off items in HabLine in the morning", timeAnchor: timeToMs("06:35"), autoExpression: "every day"})),
      (new HabitDef({id: newId(), category: "🎯", name: "Planned the next day", description: "Planning your next is a crutial habbit for maintaining a well organised life, idenfify 3-5 key things for tomorrow and mark them as such. Don't try to cram us much stuff in there, as you will most likely feel overwhelmed.", timeAnchor: timeToMs("22:00"), autoExpression: "every day"})),
      (new HabitDef({id: newId(), category: "☑️", name: "Checked off items in HabLine in the evening", timeAnchor: timeToMs("22:20"), autoExpression: "every day"})),
      (new HabitDef({id: newId(), category: "🌙", name: "Went to bed before 23:00", timeAnchor: timeToMs("22:35"), autoExpression: "every day"}))
    ];

    this.save();
  }

  static toFirestore(habitDefs: HabitDefSource) {
    let data = {
      version: HabitDefSource.VERSION,
      habitDefs: habitDefs.habitDefs.map(habitDef => habitDef.toFirestore()),
      lastUpdate: new Date(),
    };

    return data;
  }

  static fromFirestore(snapshot, options) {
    const data = snapshot.data(options);

    if (!data.version)
      data.version = 0;

    if (data.version !== this.VERSION) {
      console.log("OLD VERSION", data.version, this.VERSION);
    }

    return new HabitDefSource(
      data.habitDefs.map((habitDefData: any) => {
        let obj = new HabitDef();
        obj.fromFirestore(habitDefData, data.version);
        return obj;
      }),
      data.checkSum
    );
  }
}

