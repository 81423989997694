import { makeAutoObservable } from "mobx";
import { DayData } from "./DayData";
import { dayDataStore } from "./DayDataStore";
import { ItemSegment } from "./ItemSegment";
import { UpdatableInPlace } from "./WithProtectedSaveDelay";

export class ItemEnder implements UpdatableInPlace<ItemEnder> {
  readonly id: string;
  readonly itemId: string;
  readonly duration: number = 0;
  readonly isAnchored: boolean = false;
  readonly date: Date;

  position: number;

  constructor({ itemId, position, date }: { itemId: string; position: number; date: Date; }) {
    this.id = itemId + ":END";
    this.itemId = itemId;
    this.date = date;

    this.position = position;
    
    makeAutoObservable(this);
  }

  updateFromFirebase(ender: ItemEnder): void {
    this.position = ender.position;
  }

  toFirestore() {
    return {
      itemId: this.itemId,
      position: this.position,
    };
  }

  get insertionTime() {
    return this.inItem != null ? this.inItem.insertionTime + this.inItem.duration : 0;
  }

  get time() {
    let time = this.dayData.itemOrEnderTimes[this.id];
    return time != null ? time : this.insertionTime;
  }

  get inItem() {
    return this.dayData.lookupItemOrEnder(this.itemId);
  }

  get dayData(): DayData {
    return dayDataStore.getDayData(this.date);
  }

  get segmentThatThisContinues(): ItemSegment {
    return this.dayData.lookupSegment(this.id, true);
  }

  get segmentThatThisStarts(): ItemSegment {
    return this.dayData.lookupSegment(this.id, false);
  }

  get hasError() {
    if (this.segmentThatThisStarts != null && this.segmentThatThisStarts.duration < 0) {
      return true;
    }

    return false;
  }

  static fromFirestore(data: any, date: Date) {
    return new ItemEnder({
      itemId: data.itemId,
      position: data.position != null ? data.position : 0,
      date: date,
    });
  }
}
