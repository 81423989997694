import React from 'react';
import { Help} from './styles/components';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { gapiAuth } from '../data/gapi';
import { Center } from '@habline/common/primitives';
import packageJson from '../../package.json';

export const GSLoginInfo = observer((props) => {
  return (
    <Container>
      <h3>Welcome to DayOS</h3>
      <p>Sign in using your google account to continue.</p>
      <button className="button-primary" style={{display: (gapiAuth.user ? 'none' : 'inline-block')}} id="authorize_button" onClick={()=>gapiAuth.signin()}>Sign in</button>
      <Help><Center>Build: {packageJson.version}</Center></Help>
    </Container>
  );
})

const Container = styled.div`
  padding: 1em;
  font-size: 1.0em;
  color: ${({theme}) => theme.backgroundLevel5.toString()};
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;