import React from 'react';
import { gapiAuth } from "../../data/gapi"
import { observer } from 'mobx-react-lite';
import { uiStore } from '../../data/UIStore';
import { ContextMenu, ContextMenuOption, ContextMenuIcon, ContextMenuRow, ContextMenuDivider, ContextMenuInlineButton } from './ContextMenu';
import { dayDataStore } from '../../data/DayDataStore';
import { DateMode, describeDate, mainStore } from '@habline/common/MainStore';
import { dayTagStore } from '../../data/DayTagStore';
import { DayTagsEditor } from '../preferences/DayTagsEditor';
import { action } from 'mobx';

const CURRENT_PERIOD_NAME = {}
CURRENT_PERIOD_NAME[DateMode.DAY] = "today"
CURRENT_PERIOD_NAME[DateMode.WEEK] = "current week"
CURRENT_PERIOD_NAME[DateMode.MONTH] = "current month"

export const DayContextMenu = observer<{date: Date}>(({date}) => {
  function sort() {
    if (window.confirm(`Are you sure you want to reset the order of tasks?`)) {
      dayDataStore.dayData.sortItems();
    }
  }

  let tags = dayTagStore.tagsForDate(mainStore.currentDate);

  return <ContextMenu visible={uiStore.isContextMenuVisible} onClose={() => uiStore.hideContextMenu()}>
    { dayDataStore.dayData && (dayTagStore.customDayTags.length !== 0 || tags.length !== 0) &&
      <ContextMenuOption style={{cursor: "pointer"}} onClick={() => uiStore.push(<DayTagsEditor />)}><ContextMenuIcon><i className="gg-pentagon-right"></i></ContextMenuIcon> {tags.length === 0 ? "No tags" : tags.map(tag => <span key={tag.id}> #{tag.name.toUpperCase()}&nbsp;</span>)}</ContextMenuOption>
    }
    <ContextMenuOption style={{cursor: "pointer"}} onClick={() => {mainStore.showingCompletedItems = !mainStore.showingCompletedItems}}><ContextMenuIcon><i className="gg-play-list-check"></i></ContextMenuIcon>{mainStore.showingCompletedItems ? "Hide" : "Show"} completed items</ContextMenuOption>
    
    { dayDataStore.dayData && mainStore.dateMode === DateMode.DAY &&
      <ContextMenuOption style={{cursor: "pointer"}} onClick={() => sort()}><ContextMenuIcon><i className="gg-arrows-exchange-v"></i></ContextMenuIcon>Reset order</ContextMenuOption>
    }
    <ContextMenuOption style={{cursor: "pointer"}} onClick={() => gapiAuth.login()}><ContextMenuIcon><i className="gg-sync"></i></ContextMenuIcon> Refresh</ContextMenuOption>
    <ContextMenuDivider/>
    <ContextMenuRow>
      <ContextMenuOption style={{flexGrow: 2}}>Mode:</ContextMenuOption>
      <ContextMenuInlineButton isToggled={mainStore.dateMode === DateMode.DAY} disabled={mainStore.dateMode === DateMode.DAY} onClick={action(() => {mainStore.dateMode = DateMode.DAY})}>Day</ContextMenuInlineButton>
      <ContextMenuInlineButton isToggled={mainStore.dateMode === DateMode.WEEK} disabled={mainStore.dateMode === DateMode.WEEK} onClick={action(() => {mainStore.dateMode = DateMode.WEEK})}>Week</ContextMenuInlineButton>
      <ContextMenuInlineButton isToggled={mainStore.dateMode === DateMode.MONTH} disabled={mainStore.dateMode === DateMode.MONTH} onClick={action(() => {mainStore.dateMode = DateMode.MONTH})}>Month</ContextMenuInlineButton>
    </ContextMenuRow>
    { describeDate(date, {mode: mainStore.dateMode}) !== describeDate(mainStore.today, {mode: mainStore.dateMode}) ? <ContextMenuOption onClick={() => mainStore.setCurrentDate(mainStore.today)}><ContextMenuIcon><i className="gg-arrow-right"></i></ContextMenuIcon>Go to {CURRENT_PERIOD_NAME[mainStore.dateMode]}</ContextMenuOption> : "" }
    
  </ContextMenu>
});
