import React from 'react'
import { observer } from 'mobx-react-lite'
import { DayLevelScreen } from '../styles/components';
import { uiStore } from '../../data/UIStore'
import { CheckBox, EditorProperty } from './SettingsView';
import { globalPrefsStore } from '../../data/GlobalPrefsStore';
import { gapiAuth } from '../../data/gapi';
import { msToTime, timeToMs } from '@habline/common/time';
import { TextEditor } from './TextEditor';

export const GeneralSettingsEditor = observer((props) => {
  return <DayLevelScreen title="General" onClose={() => uiStore.pop()}>
    <CheckBox
      label="Dark mode"
      help=""
      value={globalPrefsStore.darkMode}
      valueSetter={(value) => {globalPrefsStore.setDarkMode(!globalPrefsStore.darkMode)}}
    />

    <EditorProperty
      label="Wake up time"
      help="Usual wake up time."
      onClick={() => uiStore.push(
        <TextEditor
          label="Wake up time"
          help="Usual wake up time."
          placeholder="6:30"
          value={msToTime(gapiAuth.workspace.wakeUpTime)}
          valueSetter={(value) => {gapiAuth.workspace.wakeUpTime = timeToMs(value); gapiAuth.saveWorkspace()}}
        />
      )}
    >
      {msToTime(gapiAuth.workspace.wakeUpTime)}
    </EditorProperty>
    <EditorProperty
      label="Sleep time"
      help="Usual time you go to sleep"
      onClick={() => uiStore.push(
        <TextEditor
          label="Sleep time"
          help="Usual time you go to sleep"
          placeholder="22:30"
          value={msToTime(gapiAuth.workspace.goodNightTime)}
          valueSetter={(value) => {gapiAuth.workspace.goodNightTime = timeToMs(value); gapiAuth.saveWorkspace()}}
        />
      )}
    >
      {msToTime(gapiAuth.workspace.goodNightTime)}
    </EditorProperty>
  </DayLevelScreen>
});