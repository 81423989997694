import React from "react";
import { uiStore } from '../../data/UIStore'
import { observer } from 'mobx-react-lite';
import { TextEditor } from "../preferences/TextEditor"
import { ContextMenu, ContextMenuOption, ContextMenuDivider, ContextMenuRow, ContextMenuInlineButton, ContextMenuTextOption, ContextMenuActionButton } from "./ContextMenu";
import { Item, CopyPasteOp } from "../../data/Item";
import { msToDuration, msToTime, timeToMs } from "@habline/common/time";
import { CategoryEditor } from "../preferences/CategoryEditor";
import { mainStore } from "@habline/common/MainStore";
import { ButtonIcon, describeStatus }  from "../DailyItemView"
import { Status } from "@habline/common/Status";
import { DurationEditor } from "../preferences/DurationEditor";
import { newLinesToBR } from "@habline/common/utils";
import { Help } from "../styles/components";
import { Center } from "@habline/common/primitives";
import { scrollToItem } from "../scrollToItem";
import { ItemActionsContextMenu, ItemActions, openDescriptionEditor, openHabit, openInsightNoteEditor, resetItems, returnToMainScreen, setPartialForItems, setSuccessForItems, skipLaterItems, duplicateItems, editPriority } from "./ItemActionsContextMenu";
import { itemStore } from "../../data/ItemStore";
import { action } from "mobx";

export const ItemContextMenu = observer<{items:Item[], outsideOfListing?: boolean, inIcebox?: boolean, addingToIcebox?: boolean}>(({items, outsideOfListing, inIcebox, addingToIcebox}) => {
  let item = items.length === 1 ? items[0] : null;

  let durationButton = item != null ?
    <ContextMenuInlineButton
      style={{margin:0}}
      onClick={() => item.canOverrideDuration ? uiStore.push(<DurationEditor value={item.duration} valueSetter={(value) => item.setDuration(value)} item={item} />) : uiStore.toast("Can not override duration")}
    >
      {msToDuration(item.duration)}
    </ContextMenuInlineButton>
  : null;

  let priorityButton = item != null ?
    <ContextMenuInlineButton
      isToggled={item.isPriorityOverriden}
      onClick={() => {editPriority(item)}
    }>
      {item.priority}
    </ContextMenuInlineButton>
  : null;

  let category = item != null ? item.category : (items.every(item => item.category === items[0].category) ? items[0].category : "❔");


  let thereIsSelectionButImNotIt = itemStore.selectedItems.length > 0 && items.some(item => !itemStore.isInSelection(item));

  let addButtonAvailable = item != null && item.isInIcebox && !addingToIcebox && item.date.getTime() !== mainStore.currentDate.getTime();
  let moveButtonAvailable = items.every(item => item.status === Status.UNKNOWN && !item.isInIcebox) && ((inIcebox && addingToIcebox) || items.some(item => item.date.getTime() !== mainStore.currentDate.getTime()));
  let duplicateButtonAvailable = items.some(item => !item.isInIcebox && !addingToIcebox && (outsideOfListing || inIcebox || item.date.getTime() !== mainStore.currentDate.getTime()));
  let goToButtonAvailable = items.some(item => item.status === Status.UNKNOWN && (item.date.getTime() !== mainStore.currentDate.getTime() || inIcebox || outsideOfListing))
  let resetButtonAvailable = items.some(item => item.status !== Status.UNKNOWN);
  let ignoreButtonAvailable = items.some(item => item.isInIcebox);

  let secondaryActions = (addButtonAvailable || moveButtonAvailable || duplicateButtonAvailable || goToButtonAvailable || resetButtonAvailable || ignoreButtonAvailable) && <ContextMenuRow>
   { addButtonAvailable &&
     <ContextMenuActionButton
       className="button-primary"
       onClick={action(() => {
         returnToMainScreen();
         item.performCopy(mainStore.currentDate, CopyPasteOp.ADD_FROM_ICEBOX);
         itemStore.clearSelection();
       })}>
       <ButtonIcon className="gg-arrow-right-o"/> Add
     </ContextMenuActionButton>
   }

   { moveButtonAvailable &&
     <ContextMenuActionButton
       className="button-primary"
       onClick={action(() => {
         if (addingToIcebox) {
           items.forEach(item => item.moveToIcebox())
         } else {
           returnToMainScreen();
           items.forEach(item => item.performCopy(mainStore.currentDate, CopyPasteOp.MOVE));
         }
         itemStore.clearSelection();
       })}
     >
       <ButtonIcon className={"gg-move-right"} style={{marginRight: "3px", top: "1.25px" }}></ButtonIcon> &nbsp;Move
     </ContextMenuActionButton>
   }

   { duplicateButtonAvailable &&
     <ContextMenuActionButton
        className={items.every(item => item.status === Status.UNKNOWN) ? "" : "button-primary"}
        onClick={() => {
          duplicateItems(items, addingToIcebox);
        }}
      >
       <ButtonIcon className={"gg-duplicate"} style={{marginRight: "3px", top: "3px" }}></ButtonIcon> &nbsp;Duplicate
     </ContextMenuActionButton>
   }

   { goToButtonAvailable &&
     <ContextMenuActionButton
       onClick={action(() => {
         let unknownItems = items.filter(item => item.status === Status.UNKNOWN)
         let item = unknownItems[Math.floor(Math.random() * unknownItems.length)];
         mainStore.setCurrentDateImmediatelly(item.date);
         returnToMainScreen();

         item.makeSureThatYouAreNotHidden();
         
         scrollToItem(item, false, false);
         unknownItems.forEach(item => item.doHighlight());
         itemStore.clearSelection();
       })}
     >
       <ButtonIcon className="gg-arrow-top-right" style={{top: "-1px", marginRight: "1px" }}/> Go to
     </ContextMenuActionButton>
   }

   { resetButtonAvailable && <ContextMenuActionButton
     className={inIcebox ? "" : "button-primary"}
     onClick={() => resetItems(items, )}>
       Reset
   </ContextMenuActionButton>}

   { ignoreButtonAvailable && <ContextMenuActionButton
     className={inIcebox ? "" : "button-primary"}
     onClick={action(() => items.forEach(item => item.isInIcebox && item.ignore()))}>
       <ButtonIcon className={"gg-close"} style={{top: "7px", marginRight: "-1px" }}/> Abandon
   </ContextMenuActionButton>}
  </ContextMenuRow>

  return <ContextMenu visible={uiStore.isContextMenuVisible} onClose={() => uiStore.hideContextMenu()}>
    <ContextMenuRow>
      <ContextMenuOption style={{cursor:'pointer', marginRight: 0}} onClick={() => items.every(item => item.canOverrideCategory) ? uiStore.push(<CategoryEditor value={category !== "❔" ? category : null} valueSetter={(value) => items.forEach(item => item.setCategory(value))}/>) : openHabit(item)}>{category}</ContextMenuOption>
      { item && <ContextMenuOption style={{cursor:'pointer', }} onClick={() => item.canOverrideName ? uiStore.push(<TextEditor label={"Name"} help="Used everywhere, keep it short and discriptive, if it starts with an emoji, it becomes the icon of this habit" placeholder="eg. Buy a pen" value={item.name} valueSetter={(value) => item.setName(value)}/>) : openHabit(item)}>{item.name}</ContextMenuOption> }
      { item == null && <ContextMenuOption>Selection of {items.length} items</ContextMenuOption> }
      { !thereIsSelectionButImNotIt && item && <ContextMenuRow style={{justifyContent: "flex-end", flexGrow: 1}}>
          <ContextMenuInlineButton style={{width: "3rem", height: "3rem", display: "flex", alignItems: "center", justifyContent: "center"}} onClick={() => {
          uiStore.showContextMenu(<ItemActionsContextMenu items={items} addingToIcebox={addingToIcebox} />);
        }}><i className={"gg-more-vertical-alt"} style={{top: "0px", left: "0px"}} ></i></ContextMenuInlineButton>
      </ContextMenuRow> }
    </ContextMenuRow>

    {item == null && <ContextMenuDivider /> }

    {item == null && <ItemActions items={items} addingToIcebox={addingToIcebox} />}

    { !thereIsSelectionButImNotIt && item && item.description.length > 0 &&
      <ContextMenuRow>
        <ContextMenuTextOption
          style={{cursor:'pointer', }}
          onClick={() => item.canOverrideDescription ? openDescriptionEditor(item) : openHabit(item)}
        >
          <span>{newLinesToBR(item.description)}</span>
        </ContextMenuTextOption>
      </ContextMenuRow>
    }

    { !thereIsSelectionButImNotIt && item && item.status === Status.UNKNOWN && <ContextMenuRow>
      At
      <ContextMenuInlineButton isToggled={item.isAnchored} onClick={() => {
        if (item.canOverrideIsAnchored) {
          if (item.isAnchored) {
            item.setInsertionTime(null);
          } else {
            uiStore.push(<TextEditor label={"Time"} help="Used to sort items on a given day" placeholder="10:00" value={msToTime(item.time)} valueSetter={(value) => item.setInsertionTime(timeToMs(value))} />)
          }
        } else {
          uiStore.toast("Can not override anchor")
        }
      }}>
        {item.isAnchored ? "" : "~"} {msToTime(item.time)}
      </ContextMenuInlineButton>
      for
      {durationButton}
      with
      {priorityButton}
      priority

    </ContextMenuRow>}

    { item && item.status !== Status.UNKNOWN && <>
      <ContextMenuRow>
        {describeStatus(item, true)}, and lasted for
        <ContextMenuOption style={{alignItems: "baseline"}}>{durationButton}</ContextMenuOption>
      </ContextMenuRow>
      <ContextMenuRow>
         at {priorityButton} priority.
      </ContextMenuRow>
    </>}

    <ContextMenuDivider />

    { item != null && item.numPostpones > 0 &&
      <Help><Center>Postponed {item.numPostpones} time{item.numPostpones > 1 ? "s" : ""}</Center></Help>
    }

    { item != null && item.isACopy &&
      <Help><Center>Item was copied</Center></Help>
    }

    { !thereIsSelectionButImNotIt && items.every(item => item.status === Status.UNKNOWN && item.date.getTime() === mainStore.currentDate.getTime()) && !inIcebox &&
      <ContextMenuRow>
        <ContextMenuActionButton
          className="button-primary"
          onClick={() => {setPartialForItems(items)}}
        >
          <ButtonIcon className={"gg-dice-1"}></ButtonIcon> Partial
        </ContextMenuActionButton>
      
        <ContextMenuActionButton
          className="button-primary"
          onClick={() => {setSuccessForItems(items)}}
        >
          <ButtonIcon className={"gg-check-r"}></ButtonIcon> Success
        </ContextMenuActionButton>

        <ContextMenuActionButton
            className="button-primary"
            onClick={() => {skipLaterItems(items)}}
          >
            {items.every(item => !item.canBeDoneLater) && <><ButtonIcon className={"gg-close"} style={{top: "7px", marginRight: "-1px" }}/> Skip</>}
            {items.every(item => item.canBeDoneLater) && <><ButtonIcon className="gg-arrow-right-o"/> Later</>}
            {!items.every(item => !item.canBeDoneLater) && !items.every(item => item.canBeDoneLater) && <><ButtonIcon className="gg-arrow-right-o"/> / <ButtonIcon className={"gg-close-r"} /></>}            
        </ContextMenuActionButton>
      </ContextMenuRow>
    }

    {!thereIsSelectionButImNotIt && secondaryActions}

    {(thereIsSelectionButImNotIt || items.every(item => itemStore.isInSelection(item))) &&
      <ContextMenuRow>
        {thereIsSelectionButImNotIt && <ContextMenuActionButton
          className="button-primary"
          onClick={action(() => {items.forEach(item => itemStore.addToSelection(item))})}
        >
          <ButtonIcon className={"gg-play-list-add"} style={{marginRight: "5px", top: "-1.5px" }}></ButtonIcon> Select
        </ContextMenuActionButton> }
      
        <ContextMenuActionButton
          onClick={action(() => {itemStore.clearSelection()})}
        >
          <ButtonIcon className={"gg-play-list-remove"} style={{marginRight: "5px", top: "-1.5px" }}></ButtonIcon> Clear selection
        </ContextMenuActionButton>
      </ContextMenuRow>
    }

    { !thereIsSelectionButImNotIt && item != null && item.insightNote.length > 0 &&
      <div onClick={() => openInsightNoteEditor(item)}>
        <ContextMenuTextOption style={{cursor:'pointer'}} >
            <span>{newLinesToBR(item.insightNote)}</span>
        </ContextMenuTextOption>
      </div>
    }
  </ContextMenu>
});
