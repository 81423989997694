import { action, makeAutoObservable } from "mobx";
import { flow } from "mobx";
import { newLinesToBR } from '@habline/common/utils';

const TOAST_SHOW_MS = 8000;
const TOAST_FADE_OUT_MS = 300;

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export class UIStore {
  screenStack = [];

  message: JSX.Element | string | null = null;
  messageVisible = false;
  messageOnClick = null;
  
  permMessage: JSX.Element | string | null = null;
  permMessageVisible = false;
  permMessageOnClick = null;
  permMessageOnClose = null;
  
  isContextMenuVisible = false;
  contextMenu = null;

  showContextMenu(menu) {
    if (!this.isContextMenuVisible) {
      this.contextMenu = menu;
      this.isContextMenuVisible = true;
    } else {
      this.hideContextMenu();
      setTimeout(() => {
        this.contextMenu = menu;
        this.isContextMenuVisible = true;
      }, 100);
    }
  }

  hideContextMenu() {
    this.isContextMenuVisible = false;
  }

  permToast = flow(function* (this: UIStore, message: JSX.Element | string | null, onClick: () => void = null, onClose: () => void = null) {
    console.log("Toast", message);
    
    if (this.permMessage) {
      this.permMessage = message;
      this.permMessageOnClick = onClick;
      this.permMessageOnClose = onClose;
      return;
    }

    if (typeof message === "string") {
      message = newLinesToBR(message);
    }

    this.permMessage = message;
    this.permMessageOnClick = onClick;
    this.permMessageOnClose = onClose;

    yield sleep(10); //So animation can register
    this.permMessageVisible = true;

    let step = 100;
    while (this.permMessageVisible) {
      yield sleep(step);
    }

    yield sleep(TOAST_FADE_OUT_MS);

    this.permMessage = null;
    this.permMessageOnClick = null;
    this.permMessageOnClose = null;
  });

  toast = flow(function* (this: UIStore, message: JSX.Element | string | null, onClick: () => void = null) {
    console.log("Toast", message);
    while (this.message) {
      yield sleep(100);
      this.messageVisible = false;
    }

    if (typeof message === "string") {
      message = newLinesToBR(message);
    }

    this.message = message;
    this.messageOnClick = onClick;
    yield sleep(10); //So animation can register
    this.messageVisible = true;

    let accumulated = 0;
    let step = 100;
    while (accumulated < TOAST_SHOW_MS) {
      yield sleep(step);
      accumulated += step;
      if (!this.messageVisible) { //was closed by user
        break;
      }
    }

    this.messageVisible = false;
    yield sleep(TOAST_FADE_OUT_MS);

    this.message = null;
    this.messageOnClick = null;
  });

  closePermToast() {
    if (this.permMessageOnClose) this.permMessageOnClose();
    this.permMessageVisible = false;
  }

  closeToast() {
    this.messageVisible = false;
  }

  activatePermToast() {
    if (this.permMessageOnClick) this.permMessageOnClick();
  }

  activateToast() {
    if (this.messageOnClick) this.messageOnClick();
    this.messageVisible = false;
  }

  push(view) {
    this.hideContextMenu();

    let scrollPosition = 0;

    if (document.body.style.top) { //used by modal screens to freeze position of scroll
      scrollPosition = -parseInt(document.body.style.top, 10);
    } else {
      scrollPosition = document.scrollingElement ? document.scrollingElement.scrollTop : 0;
    }

    this.screenStack = [{ screen: view, scroll: scrollPosition }, ...this.screenStack];
    //console.log("SCROLL SAVED", scrollPosition);
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }

    //When keyboard pops up on ios, we need to have another go
    setTimeout(() => {
      if (document.scrollingElement) {
        document.scrollingElement.scrollTop = 0;
      }
    }, 100);
  }

  pop() {
    let [{ screen, scroll }, ...newScreenStack] = this.screenStack;
    this.screenStack = newScreenStack;
    setTimeout(() => {
      if (document.scrollingElement) {
        document.scrollingElement.scrollTop = scroll;
      }
    }, 0); //onsole.log("SCROLLING", scroll); 
    return screen;
  }

  returnToCoreView() {
    while (this.screenStack.length > 1) {
      this.pop();
    }
  }

  constructor() {
    makeAutoObservable(this, {
      toast: action,
    });
  }
}

export const uiStore = new UIStore();