import { makeAutoObservable } from "mobx"
import { DayData } from "./DayData";
import { Item } from "./Item"
import { ItemEnder } from "./ItemEnder"

export const DAY_START = "DAY_START";
export const DAY_END = "DAY_END";

export class DayStartEnd {
  readonly id: string;
  readonly insertionTime: number;
  readonly duration = 0;
  readonly hasError = false;

  get time() { return this.insertionTime }

  constructor(id: string, insertionTime: number) {
    this.id = id;
    this.insertionTime = insertionTime;
  }
}

export class ItemSegment {
  readonly dayData: DayData;

  readonly start: Item|ItemEnder|DayStartEnd
  readonly items: Item[]
  readonly end: Item|ItemEnder|DayStartEnd

  showingMissedItems: boolean = false;
  
  constructor(dayData: DayData, start: Item|ItemEnder|DayStartEnd, items: Item[], end: Item|ItemEnder|DayStartEnd) {
    this.dayData = dayData;
    this.start = start;
    this.items = items;
    this.end = end;

    makeAutoObservable(this);
  }
  
  get usedTime() {
    return this.items.filter(item => item.isPartOfPlan).reduce((a, itemOrSeparator) => a + itemOrSeparator.duration, 0);
  }

  get duration() {
    return this.end.time - this.start.time;
  }

  get availableTime() {
    return this.duration - this.usedTime;
  }

  get notInPlanTime() {
    return this.items.filter(item => !item.isPartOfPlan).reduce((a, itemOrSeparator) => a + itemOrSeparator.duration, 0);
  }
}