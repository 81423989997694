import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite'
import styled from 'styled-components';
import "./savingindicator.css"

const LoadedIcon = styled.i`
  color: ${({theme}) => theme.green};
`;

const LoadingIcon = styled.i`
  color: ${({theme}) => theme.textLevel2};
`;

const IconContainer = styled.div`
  position: fixed;

  bottom: 34px;
  left: 50%;
  margin-left: -13px;

  background: ${({theme}) => theme.backgroundLevel0};
  border-radius: 26px;
  box-shadow: 0px 0px 8px 8px ${({theme}) => theme.backgroundLevel0};

  display: block;

  width: 26px;
  height: 26px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
`;

export const SavingIndicator: FunctionComponent<{isSaving: boolean, isDirty: boolean, justSaved: boolean}> = observer<{isSaving: boolean, isDirty: boolean, justSaved: boolean}>(({isSaving, isDirty, justSaved}) => {
  let indicator = null;

  if (justSaved) indicator = <IconContainer className="saving-just-saved"><LoadedIcon className="gg-check-o"></LoadedIcon></IconContainer>;
  if (isSaving) indicator = <IconContainer><LoadingIcon className="gg-spinner-two"></LoadingIcon></IconContainer>;
  if (isDirty) indicator = <IconContainer className="saving-is-dirty"><LoadingIcon className="gg-spinner-two-custom"></LoadingIcon></IconContainer>;
  
  return indicator;
});